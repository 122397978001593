import React from "react";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { actionCreators as Login } from '../store/Login';
import { ApplicationState } from "../../store";
import { actionCreators as UserActionCreator } from "../../store/User";
import $ from "jquery";
import "../../css/DashBoard.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { ReactPainter } from 'react-painter';
import { handleCount } from '../../helpers/validation';
import { makeId } from "../../helpers/func";
import SweetAlert from "react-bootstrap-sweetalert";
import DifficultLevel from "./smallComponents/DifficultLevel";
//import { div } from "@tensorflow/tfjs-core";
// import { isNullOrUndefined } from '../../helpers/func';
const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  GetUser: () => dispatch(UserActionCreator.GET_USER()),
  SetMenu: (menu: any) => dispatch(UserActionCreator.SET_USER_MENU(menu)),
  Logout: () => dispatch(UserActionCreator.CLEAR_USER_LOGOUT()),
});

let obj = {
  id: makeId(10),
  type:"ImageHighlight",
  showObjectives: false,
  objectives: "",
  feedback: "",
  mode: "editingMode",
  totalMark: "",
  score:"",
  authorNote:"",
  header:'<p></p>',
  qst_image:'',
  resetCanvas:0,
  qstImageAfterDrawing:"",
  msg: '',
  headertxt:'',
  required:true,
  difficult:"Low",
  cognitive:"Understanding",
  alert:null
};
// editingMode
// examMode
// gradingModeTeacher
// gradingModeStudent
class ImageHighlight extends Component<any, any> {
  

  constructor(props :any){
    super(props)
    this.state = obj
       //console.log("this is prop ", props)
       if (this.props.quest != undefined) {
         this.state = this.props.quest;
       } else {
         this.state = obj;
       }
       this.onConfirm = this.onConfirm.bind(this)
      }
      onConfirm (){
           this.setState({alert : null})
      }
      // state = obj;
       onSaveQuestion  (updatedState:any){
      
     
       let require = handleCount(this.state.header);
      
          if(require ) {
           this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >   Question header is required </SweetAlert> )})   
              }else if(this.state.qst_image == ""){
                this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >   You have to upload image </SweetAlert> )})   
              }else if(this.state.totalMark.length==0){
                this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >    Total mark is required </SweetAlert> )})   
              }else{
                this.props.onSaveQuestion(updatedState)
              }
       }
    
 
  componentDidMount() {
    $("#preloader").hide();
  }
  // setHeader = (contents: any) => {
  //   this.header = contents;
  // };
  resetcanvasfunc(){
    this.setState({
      resetCanvas:Math.random()
    })
  }
  onSaveCanvas = async (imageDownloadUrl: any) => {
    let blob = await fetch(imageDownloadUrl,{method:'GET',mode:'no-cors'}).then((r) => r.blob()).then((blobFile) => {
        let file = new File([blobFile], "fileNameGoesHere", {
          type: "image/jpeg",
        });
        var reader: any = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          var base64data = reader.result;
          //console.log(base64data);
          let qstImageAfterDrawing = base64data ;
          let updatedState = {...this.state , qstImageAfterDrawing}
          this.onSaveQuestion(updatedState)
          this.setState({
            qstImageAfterDrawing: base64data,
          });                
        };
      });
  };
  loadFromUrl= async(imageDownloadUrl: any)=>{
    try {
      const data = await fetch(imageDownloadUrl);
      const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result; 
        this.setState({
          qst_image: base64data,
        });    
        resolve(base64data);
      }
    });
    } catch (error) {
      //console.log(error.message)
      //Failed to fetch
      if(error.message === 'Failed to fetch'){
        this.setState({msg: "You can't use this image from this url! please choose another image from another url or upload the image directely from PC"})
      }
    }
    
  };
  openAddFileInputHandler = () => {
    const fileInput = document.getElementById("addFile") as HTMLInputElement;
    fileInput.click();
  };
  addFileHandler = (e: any) => {
    const file: any = e.target.files[0];
    // console.log(file);

    if (file && file.size > 0) {
      var reader: any = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (reader.result.split(":")[1].split("/")[0] === "image") {
          this.setState({
            qst_image: reader.result,
          }); //base64encoded string
          // this.setState({ image: reader.result, selectedFile: file });
        }
      };
    }
   const ele = document.querySelector('#imageurl')! as HTMLInputElement;
   ele.value = '';
  };
 
  render() {
    return (
      <div className="mx-5">
        {this.state.alert}
        <div className="row mx-auto rounded my-5 p-4 shadow ">
        {this.state.mode =="examMode" && (<div> Q {this.props.index} <br></br> </div> )}  
          <div className="col-12 row mx-auto align-items-center bg-question rounded p-3 text-white">
            <div className="col-12 row justify-content-between">
              <h3 className="text-shadow">Question Setup</h3>
              {/*<button
                className="btn bg-info text-white"
                onClick={this.stateToJsonGradingMode}
              >
                show
              </button>*/}
              <div className="d-flex flex-column">
                <div className="form-group d-flex align-items-center my-3">
                  <span className="col-auto text-shadow ">
                    Total mark :
                  </span>
                  {this.state.mode === "editingMode"? (
                    <input
                      type="number"
                      min={0}
                      className="col form-control"
                      name=""
                      id=""
                      value={this.state.totalMark}
                      onChange={(e) =>
                        this.setState({ totalMark: e.target.value })
                      }
                    />
                  ) : this.state.mode === "examMode" ||
                    this.state.mode === "gradingModeTeacher" ||
                    this.state.mode === "gradingModeStudent" ? (
                    <span className="text-shadow">{this.state.totalMark}</span>
                  ) : null}
                  {this.state.mode === "gradingModeTeacher"? (
                    <>
                    <span className="col-auto border-left ml-2">
                        Score : &nbsp;
                    </span>
                    <input
                      type="number"
                      min={0}
                      className="col form-control"
                      name=""
                      id=""
                      value={this.state.score}
                      onChange={(e) =>
                        {
                          let score = e.target.value
                          let updatedState = {...this.state , score }
                          this.onSaveQuestion(updatedState)
                          this.setState({ score: e.target.value })
                        }
                      }
                    />
                    </>
                  ) :this.state.mode === "gradingModeStudent" ? (
                    <>
                    <span className="border-left ml-2 pl-2 text-shadow">
                      Score : &nbsp;{this.state.score}
                    </span>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-12 mb-5 row justify-content-center align-items-center">
              {this.state.mode === "editingMode" ? (
                <div className="col-12 w-100 row justify-content-center align-items-center">
                  <RichBox
                    onChange={(v: any) => {this.setState({ header: v });}}
                    onChangeTxt={(a: any) => {this.setState({ headertxt: a });}}
                    defaultValue={this.state.header}
                    height={"100%"}
                  ></RichBox>
                  <div className="mt-2 col-8 row justify-content-center">
                  <label className="align-self-start">Author Note</label>
                    <input
                      className="form-control align-self-stretch "
                      name=""
                      id=""
                      value={this.state.authorNote}
                      onChange={(e) =>
                        this.setState({ authorNote: e.target.value })
                      }
                    ></input>
                  </div>
                  <div className="mt-2 col-8 row">
                  <button className="btn bg-info text-white"onClick={()=>{this.openAddFileInputHandler();this.setState({msg: '', qst_image: ''});}}> upload img</button>
                  <input accept="image/*" id="addFile" multiple={false} hidden={true} type="file" onChange={this.addFileHandler}/>
                  <label className="align-self-center ml-5 mr-4 mt-1" htmlFor="imageurl">OR Image Url: &nbsp;</label>
                    <input
                      id="imageurl"
                      name="imageurl"
                      type="text"
                      className="col-8 form-control normal"
                      onChange={(e) =>{
                        this.loadFromUrl(e.target.value);
                        this.setState({ qst_image: e.target.value })
                        }
                      }
                    />
                    {this.state.qst_image && this.state.msg === ''?<img src={this.state.qst_image} max-width="100%" max-height="80%" className="col-12 mt-3 rounded  mx-auto d-block" alt="..."></img>:null}
                    {this.state.msg !== ''? <div>
                      {this.state.msg} 
                    </div>:null}
                  </div>
                </div>
              ) : this.state.mode === "examMode" ||
                this.state.mode === "gradingModeTeacher" ||
                this.state.mode === "gradingModeStudent" ? (
                <div
                  className="bg-white w-100 text-dark rounded row align-items-center p-3"
                  dangerouslySetInnerHTML={{ __html: this.state.header }}
                >
                </div>
              ) : (
                <div></div>
              )}
            </div>
         
            {this.state.mode === "editingMode"  ? (
              <DifficultLevel 
               difficult = {this.state.difficult} 
                cognitive= {this.state.cognitive} 
                onChangeDifficult = {(difficult :any) => { this.setState({difficult :difficult}) }}
                onChangeCognitive = {(cognitive :any) => { this.setState({cognitive :cognitive}) }}
              />

            ) : null}   
            <div className="col-12 row justify-content-center align-items-center">
                {this.state.mode === "examMode" ? (
                  <>
                  <div className="col-12 w-100 mt-3 row justify-content-center align-items-center">
                  <ReactPainter
                    key={this.state.resetCanvas}
                    width={1000}
                    height={1000}
                    //onSave={blob => console.log(blob)}
                    image={this.state.qst_image}
                    render={({ triggerSave, canvas , setColor , setLineWidth , imageDownloadUrl }) => (
                      <div>
                        <div className="m-2 row justify-content-center">
                          <div className="col-4">
                          <button className="pt-3 pb-4 mt-2 btn bg-info text-white btn-block" onClick={()=>this.resetcanvasfunc()}>Reset</button>
                          </div>
                          <div className="col-4">
                            <label htmlFor="line-color">Color</label>
                            <input type="color" className="form-control" id="line-color" onChange={e => setColor(e.target.value)} />
                          </div>
                          <div className="col-4">
                            <label htmlFor="line-width">Line Width</label>
                            <input
                              type="number"
                              id="line-width"
                              defaultValue={5}
                              className="form-control"
                              onChange={e => setLineWidth(Number(e.target.value))}
                            />
                          </div>
                        </div>
                        <div onMouseDown={triggerSave} onMouseLeave={triggerSave} onMouseMove={triggerSave}>{canvas}</div>
                        <button className="p-3 btn bg-info text-white btn-block" onClick={() => { triggerSave(); this.onSaveCanvas(imageDownloadUrl);}}
                        disabled={
                          imageDownloadUrl? false : true
                        }>Save & submit</button>
                      </div>
                      
                    )}
                  />
                  </div>
                  </>
                ) : 
                  this.state.mode === "gradingModeTeacher" ||
                  this.state.mode === "gradingModeStudent" ? (
                  <div
                    className="bg-white w-100 text-dark rounded row align-items-center p-3"
                  >
                    <img src={this.state.qstImageAfterDrawing} className="rounded mx-auto d-block" alt="..."></img>
                  </div>
                ) : (
                  <div></div>
                )}
              {this.state.mode === "gradingModeTeacher" ||
              this.state.mode === "gradingModeStudent" ? (
                <>
                <div className="col-12 row align-items-start align-content-start mx-auto">
                  <label className="align-self-start">Feedback</label>
                  <textarea
                    className="form-control align-self-stretch "
                    name=""
                    id=""
                    value={this.state.feedback}
                    rows={5}
                    disabled={
                      this.state.mode === "gradingModeStudent" ? true : false
                    }
                    onChange={(e) =>
                    {
                      let feedback = e.target.value ; 
                      let updatedState = {...this.state , feedback}
                      this.onSaveQuestion(updatedState)
                      this.setState({ feedback: e.target.value })
                    }
                    }
                  ></textarea>
                </div>

                {this.state.authorNote != "" &&  this.state.mode === "gradingModeTeacher" ? (
                    <div className="col-12 row align-items-start align-content-start mx-auto">
                    <label className="align-self-start">Author Note</label>
                    <textarea
                      className="form-control align-self-stretch "
                      name=""
                      id=""
                      value={this.state.authorNote}
                      rows={5}
                      disabled={
                        this.state.mode === "gradingModeStudent" || this.state.mode === "gradingModeTeacher"? true : false
                      }
                      onChange={(e) =>
                        this.setState({ authorNote: e.target.value })
                      }
                    ></textarea>
                    </div>
                ) : null }
               
                </>
              ) : null}
            </div>
            {this.state.showObjectives && this.state.mode === "editingMode" && (
              <div className="col-12 row my-3">
                <label>Learning objective</label>
                <textarea
                  className="col-12 form-control"
                  rows={10}
                  value={this.state.objectives}
                  onChange={(e) =>
                    this.setState({ objectives: e.target.value })
                  }
                  disabled={
                    this.state.mode === "gradingModeTeacher" ? true : false
                  }
                ></textarea>
              </div>
            )}
            {this.state.mode === "editingMode" ? (
              <div className="col-12 my-3  d-flex justify-content-between">
                <button
                  className="btn btn-success"
                  onClick={() =>
                    this.setState({
                      showObjectives: !this.state.showObjectives,
                    })
                  }
                >
                  Learning objective
                </button>
              </div>
            ) : null}
           
           {  this.state.mode == "editingMode" && <div className="text-center col-12">
              <button
                className="btn btn-warning btn-lg rounded col-3"
                onClick={()=>this.onSaveQuestion(this.state)}
             
              >
                {" "}
                Save
              </button>
            </div>}
          </div>
        </div>
      </div>
    );
    // }
  }
}
function mapStateToProps(state: ApplicationState) {
  const Store = { store: state };
  return Store;
}

export default withRouter<any,any>(
  connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    mapDispatchToProps // Selects which action creators are merged into the component's props
  )(ImageHighlight as any)
);


