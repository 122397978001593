import React, { Component } from "react";

import RichBox from "./RichBoxComponent";
import { handleCount } from '../../../helpers/validation';


export interface OptionProps {
  id: string;
  option: string;

  index: number;
  mode: string;

  style: any;
  onOptionUpdate?: any;
  onDeleteOption?: any;
  onClickHandler?: any;
  onChooseCorrect?: any;
  correctAnswer: boolean;

  
  required:boolean;

}

// const selectedStyle = {
//   cursor: "pointer",
//   backgroundColor: "#9df3b1 !important",
//   border: "4px solid #098434 !important",
//   borderRadius: "10px !important",
// };

class OrderOption extends Component<OptionProps, any> {

 

  onChangeHandler = (e: any) => {
    // this.setState({ authorNote: e.target.value });
    this.props.onOptionUpdate({ ...this.props, authorNote: e.target.value });
  };
  onChangeHandler2 = (e: string) => {
    //this.setState({ option: e });
    this.props.onOptionUpdate({ ...this.props, option: e });
  };

  onClickHandler = () => {

    this.props.onClickHandler(this.props.id);
  };

  onChooseCorrect = () => {
    this.props.onChooseCorrect(this.props.id);
  };

  render() {

    // if(this)

    return (
      <div  className="col-12 m-2 row align-items-baseline">

        {this.props.mode === "editingMode" ? (
          <React.Fragment>
            <span className="col-auto mr-1" > {this.props.index} -</span>

            <div className="col p-0 rounded" style={{ overflow: "hidden" }}>
              <RichBox
                onChange={this.onChangeHandler2}
             
                defaultValue={this.props.option}
                height={"10px"}
              ></RichBox>
            </div>
            <span
              className="col-auto ml-1"
              style={{ cursor: "pointer" }}
              onClick={() => this.props.onDeleteOption(this.props.id)}
            >
              <i className="fas fa-trash-alt"></i>
            </span>
          </React.Fragment>
        ) : this.props.mode === "examMode" ? (
          <React.Fragment>
            <div className="col-12 " draggable={true} style={{ cursor: "pointer" }}>
              <div className="mr-1 align-items-center d-lg-flex" draggable={true} style={{ cursor: "pointer" }}> {this.props.index} -</div>
              <div
                className="bg-white  text-dark rounded  align-items-center p-3"
                dangerouslySetInnerHTML={{ __html: this.props.option }}
              ></div>

            </div>
          </React.Fragment>
        ) : this.props.mode === "answeringMode" ? (
          <React.Fragment>

            <div className="col-12 row" draggable={true}  style={{ cursor: "pointer" }}>
              <div className="mr-1 align-items-center d-lg-flex" draggable={true}> {this.props.index} -</div>
              <div
                className="bg-white w-75 text-dark rounded row align-items-center p-3"
                dangerouslySetInnerHTML={{ __html: this.props.option }}
              ></div>

            </div>
          </React.Fragment>
        ) : this.props.mode === "gradingModeTeacher" ||
          this.props.mode === "gradingModeStudent" ? (
          <React.Fragment>
            <div className="col-12">

           


            <div
              className={`  text-dark rounded  col-12 align-items-center p-3 `}
              style={this.props.style}
              dangerouslySetInnerHTML={{ __html: this.props.option }}
              ></div>
              </div>
          </React.Fragment>
        ) : (
          <div>

          </div>
        )}

      </div>
    );
  }
}

export default OrderOption;
