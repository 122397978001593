import React from "react";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { actionCreators as Login } from '../store/Login';
import { ApplicationState } from "../../store";
import $ from "jquery";
import { actionCreators as AssessmentActionCreator } from "../../store/Assessment";

import "../../css/DashBoard.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { makeId } from "../../helpers/func";
// import { isNullOrUndefined } from '../../helpers/func';
// import Option, { OptionProps } from "./smallComponents/Option";
import { handleCount } from '../../helpers/validation';
import SweetAlert from "react-bootstrap-sweetalert";
const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  AddAssessment: (data: any) =>
    dispatch(AssessmentActionCreator.AddAssessment(data)),
  GetAssessment: (id: any) =>
    dispatch(AssessmentActionCreator.GetAssessment(id)),
});

class Blank {
  index = 1;
  value = "";
  constructor(i: number, v: string = "") {
    this.index = i;
    this.value = v;
  }
  onChangeHandler = (e: any) => {
    this.value = e.target.value;
  };
  render = () => {
    return `<span className="bg-secondary p-3">
        ${this.index}:
        <input type="text" onChange={this.onChangeHandler} value={this.value} />
      </span>`;
  };
}

const blank = `<input type="text" disabled="">`;
//let response = 1;
class FillTheBlank extends Component<any, any> {
  child = React.createRef();
  state = {
    id: makeId(10),
    type:"FillTheBlank",
    showObjectives: false,
    shuffleOptions: false,
    objectives: "",
    feedback: "",
    mode: "editingMode",
    totalMark: "",
    header: "",
    vHeader: "",
    headertxt:'',
    required:true,
    options: [
      {
        id: makeId(10),
        option: "true",
        authorNote: "",
        selected: false,
        isTrue: false,
      },
    ],
    content: "",
    blanks: [],
    difficult:"Low",
    cognitive:"Understanding",
    alert :null
  };
  constructor(props :any){
    super(props)
    if (this.props.quest != undefined) {
      this.state = this.props.quest;
    } 
    this.onConfirm = this.onConfirm.bind(this)
  }
  onConfirm (){
       this.setState({alert : null})
  }
  // state = obj;
   onSaveQuestion  (){
    let require=handleCount(this.state.header);
      if(require) {
       this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >  Question Header is required </SweetAlert> )})   
          }else if(this.state.totalMark.length==0){
            this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >    Total mark is required </SweetAlert> )})   
          }else{
            this.props.onSaveQuestion(this.state)
          }
   }



  componentDidMount() {
    $("#preloader").hide();
  }
  // setHeader = (contents: any) => {
  //   this.header = contents;
  // };
  onHandleCount=()=>{
    let require=handleCount(this.state.header);
    this.setState({required:require});
  }
  onChangeHandler = (value: any, id: any) => {
    let newOp = [
      ...this.state.options.map((op) => {
        if (op.id === id) {
          op.authorNote = value;
        }
        return op;
      }),
    ];
    this.setState({ options: newOp });
  };

  onClickHandler = (id: any) => {
    let newOp = [
      ...this.state.options.map((op) => {
        if (op.id === id) {
          op.selected = true;
        } else {
          op.selected = false;
        }
        return op;
      }),
    ];
    this.setState({ options: newOp });
  };

  toggleHandler = () => {
    if (this.state.mode === "editingMode") {
      this.setState({ mode: "answeringMode" });
    } else {
      this.setState({ mode: "editingMode" });
    }
  };

  onChooseCorrect = (id: any) => {
    // console.log(id);
    let newOp = [
      ...this.state.options.map((op) => {
        if (op.id === id) {
          op.isTrue = true;
        } else {
          op.isTrue = false;
        }
        return op;
      }),
    ];
    this.setState({ options: newOp });
  };
  insert = (data: any) => {
    this.child.current.insertHtml(data);
  };

  onRichChange = (value: any) => {
    let blanks = [];
    let v = value;
    //console.log(blank, v);
    for (let i = 1; ; i++) {
      if (!(v.indexOf(blank) >= 0)) {
        break;
      }
      //console.log("heelo");
      let obj = new Blank(i);

      v = v.replace(blank, `${obj.render()}`);
      blanks.push(obj);
    }

    this.setState({ vHeader: $(v).html(), header: value, blanks: blanks });
    //console.log("changed", v);
    // this.child.current.setContent(v);
  };
  render() {
    // console.log(this.options, ops);
    // if (this.mode == "insertion") {

    //console.log(this.state);
    return (
      <div>
        {this.state.alert}
        <div className="row rounded my-5 p-4 shadow ">
          <div className="col-12 row align-items-center bg-question rounded p-3 text-white">
            <div className="col-12 row justify-content-between">
              <h3>Question Setup</h3>
              <div className="d-flex flex-column">
                {this.state.mode === "editingMode" ||
                this.state.mode === "answeringMode" ? (
                  <button
                    className="btn btn-warning text-white "
                    onClick={this.toggleHandler}
                  >
                    Answer mode
                  </button>
                ) : null}
                <div className="form-group d-flex align-items-center my-3">
                  <span className="col-auto">Total mark : &nbsp;</span>
                  {this.state.mode === "editingMode" ||
                  this.state.mode === "answeringMode" ? (
                    <input
                      type="number"
                      min={0}
                      className="col form-control"
                      name=""
                      id=""
                      onChange={(e) =>
                        this.setState({ totalMark: e.target.value })
                      }
                    />
                  ) : this.state.mode === "examMode" ||
                    this.state.mode === "gradingModeTeacher" ||
                    this.state.mode === "gradingModeStudent" ? (
                    <span>{this.state.totalMark}</span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-12 mb-5 row justify-content-center align-items-center">
              <button
                className="btn btn-success m-2"
                onClick={() =>
                  // this.insert(
                  //   `<span><u><span style="box-sizing: border-box; -webkit-user-drag: none; overflow: visible; font-family: inherit; font-size: inherit; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px; background-color: rgb(255, 228, 0);">${response++}) response</span></u></span> <input type="text" /> `
                  // )
                  this.insert(blank)
                }
              >
                fill in the blank
              </button>
              {this.state.mode === "editingMode" ? (
                <div className="col-12 w-100 row justify-content-center align-items-center">
                  <RichBox
                    onChange={(v: any) => {this.setState({ header: v });this.onHandleCount();}}
                    onChangeTxt={(a: any) => {this.setState({ headertxt: a });this.onHandleCount();}}
                    defaultValue={this.state.header}
                    ref={this.child}
                    onBlur={() => {}}
                    height={"400px"}
                  ></RichBox>
                </div>
              ) : this.state.mode === "examMode" ||
                this.state.mode === "answeringMode" ||
                this.state.mode === "gradingModeTeacher" ||
                this.state.mode === "gradingModeStudent" ? (
                <div
                  className="bg-white w-100 text-dark rounded row align-items-center p-3"
                  dangerouslySetInnerHTML={{ __html: this.state.vHeader }}
                ></div>
              ) : (
                <div></div>
              )}
            </div>
            {this.state.mode==="editingMode"||this.state.mode === "gradingModeTeacher"?(
                <div className="col-12 justify-content-center align-items-center align-items-center d-flex p-3">
                <label className="col-auto text-white ml-1">Difficult level: </label>
                <select
                      className="form-control col-4"
                      name="difficult"
                      id="difficult"
                      defaultValue={"low"}
                      onChange={(e: any) =>
                        this.setState({
                          difficult: e.target.value,
                        })
                      }
                      disabled={
                        this.state.mode === "gradingModeTeacher" ? true : false
                      }
                    >
                      <option value="low">low</option>
                      <option value="medium">medium</option>
                      <option value="high">high</option>
                </select>
                <label className="col-auto text-white">Cognitive level: </label>
                <select
                      className="form-control col-4"
                      name="cognitive"
                      id="cognitive"
                      defaultValue={"understanding"}
                      onChange={(e: any) =>
                        this.setState({
                          cognitive: e.target.value,
                        })
                      }
                      disabled={
                        this.state.mode === "gradingModeTeacher" ? true : false
                      }
                    >
                      <option value="understanding">understanding</option>
                      <option value="applying">applying</option>
                      <option value="problem solving/critical thinking">problem solving / critical thinking</option>
                </select>
              </div>
              
              ):null}
            <div className="col-12 row">
              <div
                className={`text-dark col-12 row align-items-center justify-content-center`}
              >
                {/* {ops} */}
                <div
                  className="col-12 normal p-4"
                  style={{ height: "400px" }}
                  dangerouslySetInnerHTML={{ __html: this.state.vHeader }}
                ></div>
              </div>

              {this.state.mode === "gradingModeTeacher" ||
              this.state.mode === "gradingModeStudent" ? (
                <div className="col-12 row align-items-start align-content-start p-2">
                  <label className="align-self-start">Feedback</label>
                  <textarea
                    className="form-control align-self-stretch "
                    name=""
                    id=""
                    value={this.state.feedback}
                    rows={5}
                    disabled={
                      this.state.mode === "gradingModeStudent" ? true : false
                    }
                    onChange={(e) =>
                      this.setState({ feedback: e.target.value })
                    }
                  ></textarea>
                </div>
              ) : null}
            </div>

            {this.state.showObjectives && (
              <div className="col-12 row my-3">
                <label>Learning objective</label>
                <textarea
                  className="col-12 form-control"
                  rows={10}
                  value={this.state.objectives}
                  onChange={(e) =>
                    this.setState({ objectives: e.target.value })
                  }
                ></textarea>
              </div>
            )}
            {this.state.mode === "editingMode" ? (
              <div className="col-12 my-3  d-flex justify-content-between">
                <button
                  className="btn btn-success"
                  onClick={() =>
                    this.setState({
                      showObjectives: !this.state.showObjectives,
                    })
                  }
                >
                  Learning objective
                </button>
              </div>
            ) : null}
            <div className="text-center col-12">
              <button
                className="btn btn-warning btn-lg rounded col-3"
                onClick={()=>this.onSaveQuestion()}
              
              >
                {" "}
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    // }
  }
}
function mapStateToProps(state: ApplicationState) {
  const Store = { store: state };
  return Store;
}

export default withRouter<any,any>(
  connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    mapDispatchToProps // Selects which action creators are merged into the component's props
  )(FillTheBlank as any)
);
