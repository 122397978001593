import React from 'react';
import { Component, MouseEvent } from 'react';
import {Switch,Route,withRouter, BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
//import { actionCreators as Login } from '../store/Login';
import { ApplicationState } from '../../store';

import $ from "jquery";
<script type="text/javascript" src="https://cdn.datatables.net/v/dt/dt-1.10.25/datatables.min.js"></script>

import '../../css/DashBoard.css';

import DashBoardNav from './DashBoardNavComponent';
import SideNav from './SideNavComponent';

//import { ConnString } from '../../store/initialStatus';
import {Translate} from '../../helpers/Translate';

import Assessments from './pages/Assessments';
import Subjects from './pages/Subjects';
import StudentsExams from './pages/StudentsExams';
import StudentAssessment from '../AssessmentView/StudentAssessment';





class DashBoard extends Component<any,any>{
  state: any;
  t =new Translate();
  constructor(props:any) {
    super(props);
    
    // 1- check if it Logged in first
    //  
  
  
  }
  toggleSideBar(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    $("body").toggleClass("sb-sidenav-toggled");
  }
  userDropdown(event: MouseEvent<HTMLAnchorElement>) {
      event.preventDefault();
      $("#privateuserMenu").toggleClass("show");
  }
  componentDidMount(){
    $("#preloader").hide();
    try {
      /* let signalR = require('@microsoft/signalr')
      let connString = ConnString();
      if (connString.ApiKey !== null || connString.ApiKey !== "") {
            const connection = new signalR.HubConnectionBuilder()
          .withUrl("http://localhost:60823/confirmhub?fingerprint="+sessionStorage.getItem('fp')+"&APIKey="+connString.ApiKey)
          .withAutomaticReconnect()
            
          //.withHubProtocol(new JsonHubProtocol())
          //.configureLogging(LogLevel.Information)
            .build();
          connection.on("send", (data: any) => {
                
          });
          connection.on("ReceiveMessage", function (user: string, message: string) {
            var msg = message.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
            var encodedMsg = user + " says " + msg;
            var li = document.createElement("li");
            li.textContent = encodedMsg;
              
        });
          connection.start()
          .then(() => connection.invoke("send", "kim", "kard"));
          
        setTimeout(() => {
          connection.invoke("Send", "user", "message").catch(function (err:any) {
            return console.error(err.toString());
        });
        }, 5000);
      } */

    } catch (err) {
      
    }
  
  
  }
  
  render() {
   
        //  
        interface pageInterface{
          PageId:number;
          Name:string;
          MenuItemId:number;
          PageTypeId?:number;
          Actions:Array<any>;
          Properties:Array<any>;
        }
    
        //  
            return (
        <React.Fragment>
          <DashBoardNav Logout={this.props.Logout} toggleSideBar={this.toggleSideBar} userDropdown={this.userDropdown} />       
          
          <div id="layoutSidenav">
          <SideNav  />
          
            <div id="layoutSidenav_content">
            
              <main>
                <div className="container-fluid">
            <h1 className="mt-4">{this.t.translate("Dashboard")}</h1>
                  <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.t.translate("Dashboard")}</li>
                  </ol>
                  
  
             
                
                <Switch>
                    <Route exact path="/dashboard/assessments" component={()=> <Assessments />}></Route>
                    <Route exact path="/dashboard/Subjects" component={()=> <Subjects />}></Route>
                    <Route exact path="/dashboard/StudentsExams" component={()=> <StudentsExams />}></Route>
                    <Route exact path="/assessment" component={()=> <StudentAssessment />}></Route>
                </Switch>
            
           
                
                </div>
              </main>
              
              <footer className="py-4 bg-light mt-auto">
                <div className="container-fluid">
                  <div className="d-flex align-items-center justify-content-between small">
                    <div className="text-muted">Copyright &copy; IVY 2020</div>
                    <div>
                      <a id="Privacy" href="#Privacy">Privacy Policy</a>
                      &middot;
                      <a id="Terms" href="#Terms">Terms &amp; Conditions</a>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </React.Fragment>
      );


  }
}
function mapStateToProps(state:ApplicationState) {
  const Store = {store: state }
  return Store;
}

export default withRouter( connect()(DashBoard as any));