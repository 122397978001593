import { LoadFromStore } from "../helpers/func";

export const url = "/assessments_platform/backend/";
export const LoginState: any = {
  user: { UserName: "", password: "", rememberme: false },
  isLoading: false,
  loggedin: false,
};
export const UserState: any = {
  token: "",
  UserId: 0,
  UserName: "",
  PasswordHash: "",
  Name: "",
  Email: "",
  EmailConfirmed: false,
  PhoneNumber: "",
  RoleID: 0,
};
export const AuthState: any = {};
export const InitialFeedback = {
  firstname: "",
  lastname: "",
  telnum: "",
  email: "",
  agree: false,
  contactType: "Tel.",
  message: "",
};
export const User: any = {
  UserId: "",
  Name: "",
  UserName: "",
  AccessFailedCount: "",
  Email: "",
  EmailConfirmed: false,
  LockoutEnabled: false,
  LockoutEnd: null,
  PhoneNumber: "",
  PhoneNumberConfirmed: "",
  SecurityStamp: "",
  TwoFactorEnabled: false,
  Role: {},
  token: "",
};
export const UsersPage: any = {
  Users: [],
};
export const Assessment: any = {
  questions: [],
  assessments :[],
  studentsExams :[],
  studentExamCount : 0,
  grades :[],
  subjects :[],
  groups :[],
  teachers :[],
  assessment : null,
  loading : false,
  subjectsloading : false,
  addAssessmentloading : false,
  submitAssessmentloading : false,
  addSubjectloading : false,
  studentAssessmentLoading :false ,
  assessmentMsg : "",
  submitAssessmentMsg : "",
  subjectMsg : "",
  gotdata : false,
  viewdata : false,
};

export const FP = "";

export var ConnString = () => {
  return {
    token: sessionStorage.getItem("token") || "",
  };
};
export const buttonList = [
  // default
  ["undo", "redo"],
  ["font", "fontSize", "formatBlock"],
  ["paragraphStyle", "blockquote"],
  ["bold", "underline", "italic", "strike", "subscript", "superscript"],
  ["fontColor", "hiliteColor", "textStyle"],
  ["removeFormat"],
  ["outdent", "indent"],
  ["align", "horizontalRule", "list", "lineHeight"],
  ["table", "link", "image", "video", "audio", "math"],
  ["imageGallery"],
  ["fullScreen", "showBlocks", "codeView"],
  ["preview", "print"],
  ["save", "template"],
  // (min-width: 1546)
  [
    "%1546",
    [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle", "blockquote"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor", "textStyle"],
      ["removeFormat"],
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      ["table", "link", "image", "video", "audio", "math"],
      ["imageGallery"],
      ["fullScreen", "showBlocks", "codeView"],
      [
        "-right",
        ":i-More Misc-default.more_vertical",
        "preview",
        "print",
        "save",
        "template",
      ],
    ],
  ],
  // (min-width: 1455)
  [
    "%1455",
    [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle", "blockquote"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor", "textStyle"],
      ["removeFormat"],
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      ["table", "link", "image", "video", "audio", "math"],
      ["imageGallery"],
      [
        "-right",
        ":i-More Misc-default.more_vertical",
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        "print",
        "save",
        "template",
      ],
    ],
  ],
  // (min-width: 1326)
  [
    "%1326",
    [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle", "blockquote"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor", "textStyle"],
      ["removeFormat"],
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      [
        "-right",
        ":i-More Misc-default.more_vertical",
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        "print",
        "save",
        "template",
      ],
      [
        "-right",
        ":r-More Rich-default.more_plus",
        "table",
        "link",
        "image",
        "video",
        "audio",
        "math",
        "imageGallery",
      ],
    ],
  ],
  // (min-width: 1123)
  [
    "%1123",
    [
      ["undo", "redo"],
      [
        ":p-More Paragraph-default.more_paragraph",
        "font",
        "fontSize",
        "formatBlock",
        "paragraphStyle",
        "blockquote",
      ],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor", "textStyle"],
      ["removeFormat"],
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      [
        "-right",
        ":i-More Misc-default.more_vertical",
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        "print",
        "save",
        "template",
      ],
      [
        "-right",
        ":r-More Rich-default.more_plus",
        "table",
        "link",
        "image",
        "video",
        "audio",
        "math",
        "imageGallery",
      ],
    ],
  ],
  // (min-width: 817)
  [
    "%817",
    [
      ["undo", "redo"],
      [
        ":p-More Paragraph-default.more_paragraph",
        "font",
        "fontSize",
        "formatBlock",
        "paragraphStyle",
        "blockquote",
      ],
      ["bold", "underline", "italic", "strike"],
      [
        ":t-More Text-default.more_text",
        "subscript",
        "superscript",
        "fontColor",
        "hiliteColor",
        "textStyle",
      ],
      ["removeFormat"],
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      [
        "-right",
        ":i-More Misc-default.more_vertical",
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        "print",
        "save",
        "template",
      ],
      [
        "-right",
        ":r-More Rich-default.more_plus",
        "table",
        "link",
        "image",
        "video",
        "audio",
        "math",
        "imageGallery",
      ],
    ],
  ],
  // (min-width: 673)
  [
    "%673",
    [
      ["undo", "redo"],
      [
        ":p-More Paragraph-default.more_paragraph",
        "font",
        "fontSize",
        "formatBlock",
        "paragraphStyle",
        "blockquote",
      ],
      [
        ":t-More Text-default.more_text",
        "bold",
        "underline",
        "italic",
        "strike",
        "subscript",
        "superscript",
        "fontColor",
        "hiliteColor",
        "textStyle",
      ],
      ["removeFormat"],
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      [
        ":r-More Rich-default.more_plus",
        "table",
        "link",
        "image",
        "video",
        "audio",
        "math",
        "imageGallery",
      ],
      [
        "-right",
        ":i-More Misc-default.more_vertical",
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        "print",
        "save",
        "template",
      ],
    ],
  ],
  // (min-width: 525)
  [
    "%525",
    [
      ["undo", "redo"],
      [
        ":p-More Paragraph-default.more_paragraph",
        "font",
        "fontSize",
        "formatBlock",
        "paragraphStyle",
        "blockquote",
      ],
      [
        ":t-More Text-default.more_text",
        "bold",
        "underline",
        "italic",
        "strike",
        "subscript",
        "superscript",
        "fontColor",
        "hiliteColor",
        "textStyle",
      ],
      ["removeFormat"],
      ["outdent", "indent"],
      [
        ":e-More Line-default.more_horizontal",
        "align",
        "horizontalRule",
        "list",
        "lineHeight",
      ],
      [
        ":r-More Rich-default.more_plus",
        "table",
        "link",
        "image",
        "video",
        "audio",
        "math",
        "imageGallery",
      ],
      [
        "-right",
        ":i-More Misc-default.more_vertical",
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        "print",
        "save",
        "template",
      ],
    ],
  ],
  // (min-width: 420)
  [
    "%420",
    [
      ["undo", "redo"],
      [
        ":p-More Paragraph-default.more_paragraph",
        "font",
        "fontSize",
        "formatBlock",
        "paragraphStyle",
        "blockquote",
      ],
      [
        ":t-More Text-default.more_text",
        "bold",
        "underline",
        "italic",
        "strike",
        "subscript",
        "superscript",
        "fontColor",
        "hiliteColor",
        "textStyle",
        "removeFormat",
      ],
      [
        ":e-More Line-default.more_horizontal",
        "outdent",
        "indent",
        "align",
        "horizontalRule",
        "list",
        "lineHeight",
      ],
      [
        ":r-More Rich-default.more_plus",
        "table",
        "link",
        "image",
        "video",
        "audio",
        "math",
        "imageGallery",
      ],
      [
        "-right",
        ":i-More Misc-default.more_vertical",
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        "print",
        "save",
        "template",
      ],
    ],
  ],
];



///
export const buttonList2 = [
  // default
  ["undo", "redo"],
 
  ["blockquote"],
  ["bold", "underline", "italic", "strike", "subscript", "superscript"],
  ["fontColor", "hiliteColor", "textStyle"],

  ["outdent", "indent"],
  ["align", "horizontalRule", "list", "lineHeight"],
  ["table", "link", "image", "video", "audio", "math"],
  
  ["fullScreen", "showBlocks"],
 
  // (min-width: 1546)
  [
    "%1546",
    [
      ["undo", "redo"],
     
      [ "blockquote"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor", "textStyle"],
     
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      ["table", "link", "image", "video", "audio", "math"],
      
      ["fullScreen", "showBlocks"],
    
    ],
  ],
  // (min-width: 1455)
  [
    "%1455",
    [
      ["undo", "redo"],

      ["blockquote"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor", "textStyle"],
   
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      ["table", "link", "image", "video", "audio", "math"],
  
    
    ],
  ],

];