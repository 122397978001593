//import * as WeatherForecasts from './WeatherForecasts';
import * as User from "./User";
import * as Login from "./Login";
import * as Users from "./UsersPage";
import * as Assessment from "./Assessment";
import * as CCreator from "./ComponentCreator";
//import { InitialFeedback } from './forms';
import * as Initial from "./initialStatus";
import { createForms } from "react-redux-form";

// The top-level state object
export interface ApplicationState {
  login: Login.LoginState | undefined;
  user: User.UserState | undefined;
  usersPage: Users.UsersPage | undefined;
  Assessment: any;
  componentCreator: any | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
//...createForms({
//    feedback: Initial.InitialFeedback
//})
// acts on the corresponding ApplicationState property type.
export const reducers = {
  user: User.reducer,
  login: Login.reducer,
  usersPage: Users.reducer,
  Assessment: Assessment.reducer,
  componentCreator: CCreator.reducer,
  ...createForms({
    feedback: Initial.InitialFeedback,
  }),
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
