import React from "react";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { actionCreators as Login } from '../store/Login';
import { ApplicationState } from "../../store";
import $ from "jquery";
import "../../css/DashBoard.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { makeId } from "../../helpers/func";
// import { isNullOrUndefined } from '../../helpers/func';
import { handleCount ,shuffle } from '../../helpers/validation';
import OrderOption, { OptionProps } from "./smallComponents/OrderOption";
import SweetAlert from "react-bootstrap-sweetalert";
import DifficultLevel from "./smallComponents/DifficultLevel";


let obj = {
  id: makeId(10),
  showObjectives: false,
  shuffleOptions: false,
  objectives: "",
  feedback: "",
  mode: "editingMode",
  type: "OrderComponent",
  totalMark: "",
  score: "0",
  scoreMode: "partialMatch",
  header:'<p></p>',
  headertxt:'',
  required:true,
  options: [
    {
      id: "Gww36bw4fp",
      option: "<p></p>",
     
    },

    {
      id: "3wBfSgxArP",
      option: "<p></p>",
     
    },
  
  ],
  correctOptions: [
    {
      id: "Gww36bw4fp",
      option: "<p></p>",
    },

    {
      id: "3wBfSgxArP",
      option: "<p></p>",
    },
  
  ],
  difficult:"Low",
  cognitive:"Understanding",
  alert :null,
  oneLineOptions:false
};
// editingMode
// examMode
// gradingModeTeacher
// gradingModeStudent

class Order extends Component<any, any> {
  
  constructor(props :any){
    super(props)
    this.state = obj;
     
       if (this.props.quest != undefined) {
    

         this.state = this.props.quest;
       } else {
         this.state = obj;
       }
    this.onConfirm = this.onConfirm.bind(this)

     }

     onConfirm (e:any){
      this.setState({alert : null})
  }
    onSaveQuestion  (updatedState:any){
   // check required on options and header
   let optionsrequired = false;
 
   let require = handleCount(this.state.header);
  updatedState.options.forEach((o: any) => {
     if (optionsrequired === false) {
       if (handleCount(o.option) === true) {
         optionsrequired = true;
       }
     }
   
   });
   // count of options must be => 2 
   let count =updatedState.options.length;
      if(require || optionsrequired) {
       this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >  Question header and quetions options  are required </SweetAlert> )})   
          }else if(this.state.totalMark.length==0){
            this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >    Total mark is required </SweetAlert> )})   
          }else if(count < 2){
            this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >   Options mustn't be less than two options  </SweetAlert> )})   
          }        
          else{
            this.props.onSaveQuestion(updatedState)
          }
   }
  
  
  
  componentDidMount() {

    $("#preloader").hide();
   this.calculateScore();
    if (this.state.mode === "examMode" && this.state.shuffleOptions) {
      const shuffled = shuffle([...this.state.options]);
       this.setState({ options: shuffled, shuffleOptions: false });
    }
  }



  addOptionHandler = () => {
    let id = makeId(10);
    let newOp = [
      ...this.state.options.concat({
        id: id,
        option: "<p></p>",
        required :true 
      }),
    ];
    let newAnsweredOp = [
      ...this.state.correctOptions.concat({
        id: id,
        option: "<p></p>",
      }),
    ];
    this.setState({ options: newOp, correctOptions: newAnsweredOp });
  };

  onOptionUpdate = (val: OptionProps) => {
    let option = this.state.options.find((x:any) => x.id === val.id);
    let options = this.state.options;
    let correctOptions = this.state.correctOptions;
    if (option) {
      let index = this.state.options.indexOf(option);
      options[index] = {
        id: val.id,
        option: val.option,
      };
    }
    let correctOption = this.state.correctOptions.find((x:any) => x.id === val.id);

    if (correctOption) {
      let index = this.state.correctOptions.indexOf(correctOption);
      correctOptions[index] = {
        id: val.id,
        option: val.option,
      };
    }
    this.setState({ options: options, correctOptions: correctOptions });
  };

  onDeleteOption = (id: any) => {
    let newOp = [...this.state.options.filter((op:any) => op.id !== id)];
    let newAnsweredOp = [
      ...this.state.correctOptions.filter((op:any) => op.id !== id),
    ];
    this.setState({ options: newOp, correctOptions: newAnsweredOp });
  };

  toggleHandler = () => {
    if (this.state.mode === "editingMode") {
      this.setState({ mode: "answeringMode" });
    } else {
      this.setState({ mode: "editingMode" });
    }
  };

  onDrag = (e: any, index: any) => {
    e.dataTransfer.setData("id", index);
  };
  onDrop = (e: any, index: any) => {
    if (this.state.mode == "editingMode" || this.state.mode == "examMode") {
      var comingele = e.dataTransfer.getData("id");

      let data = [...this.state.options];

      let temp = data[comingele];
      data[comingele] = data[index];
      data[index] = temp;
      this.setState({ options: data });
      if(this.state.mode == "examMode"){
        let options = data
    let updatedState = {...this.state , options}
     
        this.onSaveQuestion(updatedState)
      }
    } else if (this.state.mode == "answeringMode") {
      var comingele = e.dataTransfer.getData("id");

      let data = [...this.state.correctOptions];

      let temp = data[comingele];
      data[comingele] = data[index];
      data[index] = temp;
      this.setState({ correctOptions: data });
    }
  };
  onDragOver = (e: any) => {
    e.preventDefault();
  };

  calculateScore() {
    let numberOfQuestions = this.state.options.length;
    let optionScore = +this.state.totalMark / numberOfQuestions;
    let studentScore = 0;
    if (this.state.scoreMode == "partialMatch") {
      for (let index = 0; index < this.state.options.length; index++) {
        if (
          this.state.options[index].id == this.state.correctOptions[index].id
        ) {
          studentScore += optionScore;
        }
      }
    } else if (this.state.scoreMode == "exactMatch") {
      for (let index = 0; index < this.state.options.length; index++) {
        if (
          this.state.options[index].id == this.state.correctOptions[index].id
        ) {
          studentScore += optionScore;
        }
      }
      if (studentScore < +this.state.totalMark) studentScore = 0;
    }
    this.setState({score : +studentScore.toFixed(2)})

  }
  render() {
    
  

    let ops = this.state.options.map((op:any, index:any) => {
      return (
        <div
          key={op.id}
          className={` ${this.state.oneLineOptions === true && this.state.mode == "examMode"? "col-3" : "col-12"}   m-2 row align-items-baseline`}
          onDragOver={this.onDragOver}
          onDragStart={(e) => {
            this.onDrag(e, index);
          }}
          onDrop={(e) => {
            this.onDrop(e, index);
          }}
      
        >
          <OrderOption
            correctAnswer={false}
            style={{ backgroundColor: "green" }}
            key={op.id}
            option={op.option}
            id={op.id}
            index={index + 1}
            mode={this.state.mode}
            onOptionUpdate={this.onOptionUpdate}
            onDeleteOption={this.onDeleteOption}
            required={true}
          />
        </div>
      );
    });
    let correctOptions = this.state.correctOptions.map((op:any, index:any) => {
      return (
        <div
          key={op.id}
          className="col-12 m-2 row align-items-baseline"
          onDragOver={this.onDragOver}
          onDragStart={(e) => {
            this.onDrag(e, index);
          }}
          onDrop={(e) => {
            this.onDrop(e, index);
          }}
        >
          <OrderOption
            correctAnswer={false}
            style={{ backgroundColor: "" }}
            key={op.id}
            option={op.option}
            id={op.id}
            index={index + 1}
            mode={this.state.mode}
            onOptionUpdate={this.onOptionUpdate}
            onDeleteOption={this.onDeleteOption}
            required={true}
          />
        </div>
      );
    });
    let gradingOptions = this.state.options.map((op:any, index:any) => {
      return (
        <div
          key={op.id}
          className="col-12 row align-items-baseline"
          onDragOver={this.onDragOver}
          onDragStart={(e) => {
            this.onDrag(e, index);
          }}
          onDrop={(e) => {
            this.onDrop(e, index);
          }}
        >
          {op.id == this.state.correctOptions[index].id ? (
            <div className=" col-12 row align-items-center">
              <div className="col-12">
                <OrderOption
                  correctAnswer={false}
                  style={{ backgroundColor  : "#9df3b1 ",
                  border: "4px solid #098434 " ,  textColor: "white !important"
                 }}
                  key={op.id}
                  option={op.option}
                  id={op.id}
                  index={index + 1}
                  mode={this.state.mode}
                  onOptionUpdate={this.onOptionUpdate}
                  onDeleteOption={this.onDeleteOption}
                  required={true}
                />
              </div>
            </div>
          ) : (
            <React.Fragment>
              <div className=" col-12 row align-items-center">
                <div className="col">
                  <OrderOption
                    correctAnswer={false}
                    style={{ backgroundColor: "#ffe1e2", textColor: "white" }}
                    key={op.id}
                    option={op.option}
                    id={op.id}
                    index={index + 1}
                    mode={this.state.mode}
                    onOptionUpdate={this.onOptionUpdate}
                    onDeleteOption={this.onDeleteOption}
                    required={true}
                  />
                </div>
                <div className="col-auto ">
                  Correct answer : <i className="fas fa-arrow-right"></i>
                </div>
                <div className="col">
                  <OrderOption
                    correctAnswer={true}
                    style={{ backgroundColor  : "#9df3b1 ",
                    border: "4px solid #098434 " ,  textColor: "white !important"
                   }}
                    key={this.state.correctOptions[index].id}
                    option={this.state.correctOptions[index].option}
                    id={this.state.correctOptions[index].id}
                    index={index + 1}
                    mode={this.state.mode}
                    onOptionUpdate={this.onOptionUpdate}
                    onDeleteOption={this.onDeleteOption}
                    required={true}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      );
    });

    return (
      <div className="mx-5">
              {this.state.alert}

        <div className="row rounded my-5 p-4 shadow  ">
          <div className="col-12 row align-items-center mx-auto  bg-question rounded p-3 text-white">
            <div className="col-12 row justify-content-between">
              {this.state.mode == "editingMode" ? (
                <h3>Question Setup</h3>
              ) : null}
              {this.state.mode == "answeringMode" ? (
                <h3>Correct answer setup</h3>
              ) : null}

              <div className="d-flex flex-column">
                {this.state.mode === "editingMode" ||
                this.state.mode === "answeringMode" ? (
                  <button
                    className="btn btn-warning text-white "
                    onClick={this.toggleHandler}
                  >
                    {this.state.mode == "editingMode"
                      ? "Go to Answer mode"
                      : "Go to Question setup mode"}
                  </button>
                ) : null}
              {this.state.mode =="examMode" && (<div> Q {this.props.index} <br></br> </div> )}  
              <div className="form-group d-flex align-items-center my-3">
                  <span className="col-auto">Total mark : </span>
                  {this.state.mode === "editingMode" ||
                    this.state.mode === "answeringMode" ? (
                    <input
                      type="number"
                      min={0}
                      className="col form-control"
                      name=""
                      id=""
                      value={this.state.totalMark}
                      onChange={(e) =>
                        this.setState({ totalMark: e.target.value })
                      }
                    />
                  ) : this.state.mode === "examMode" ||
                    this.state.mode === "gradingModeTeacher" ||
                    this.state.mode === "gradingModeStudent" ? (
                    <span>{this.state.totalMark}</span>
                  ) : null}
                  {this.state.mode === "gradingModeTeacher" ||
                    this.state.mode === "gradingModeStudent" ? (
                    <>
                      <span className="border-left ml-2 pl-2 col-auto">Score : </span>
                      <span>{this.state.score}</span>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-12 mb-5 row justify-content-center align-items-center">
              {this.state.mode === "editingMode" ? (
                <div className="col-12 w-100 row justify-content-center align-items-center">
                  <RichBox
                    onChange={(v: any) => {this.setState({ header: v });}}
                    onChangeTxt={(a: any) => {this.setState({ headertxt: a });}}
                    defaultValue={this.state.header}
                    height={"10px"}
                  ></RichBox>
                  {/* <p className="mt-1 bg-danger text-white" style={{fontSize:"1.5rem"}}>{(this.state.required ? "Warning! Please enter the question before submit":"")}</p> */}
                </div>
              ) : this.state.mode === "examMode" ||
                this.state.mode === "answeringMode" ||
                this.state.mode === "gradingModeTeacher" ||
                this.state.mode === "gradingModeStudent" ? (
                <div
                  className="bg-white w-100 text-dark rounded row align-items-center p-3"
                  dangerouslySetInnerHTML={{ __html: this.state.header }}
                ></div>
              ) : (
                <div></div>
              )}
            </div>
            <h5 className="col-12">Options : {this.state.mode != "editingMode" ? "You should drag option and drop it in another option to swap between them" : "" }  </h5>
            <div className="col-12 row">
              <div className={` col-12 row  align-items-center`}>
                {this.state.mode === "answeringMode" ? correctOptions : null}
                {this.state.mode === "gradingModeTeacher" ||
                this.state.mode === "gradingModeStudent"
                  ? gradingOptions
                  : null}
                {this.state.mode === "editingMode" ? ops : null}
                {this.state.mode === "examMode" ? ops : null}
                {this.state.mode === "editingMode" ? (
                  <div className="col-12 my-3 ml-5">
                    <button
                      className="btn btn-success "
                      onClick={this.addOptionHandler}
                    >
                      + Options
                    </button>
                  </div>
                ) : null}
              </div>
              <hr className="col-10"  style ={{color:"white" ,backgroundColor :"white"}}/>

              {this.state.mode === "gradingModeTeacher" ||
                this.state.mode === "gradingModeStudent" ? (
                <div className="col-12 ml-4 row align-items-start align-content-start p-2">
                  <label className="align-self-start">Feedback</label>
                  <textarea
                    className="form-control align-self-stretch "
                    name=""
                    id=""
                    value={this.state.feedback}
                    rows={5}
                    disabled={
                      this.state.mode === "gradingModeStudent" ? true : false
                    }
                    onChange={(e) =>
                     { let feedback = e.target.value ; 
                      let updatedState = {...this.state , feedback}
                    this.onSaveQuestion(updatedState)
                    this.setState({ feedback: e.target.value })}
                    }
                  ></textarea>
                </div>
              ) : null}
            </div>
            
            {this.state.mode === "editingMode" ? (
              <React.Fragment>
                <div className="col-12 row my-3 ml-5 ">
                  <input
                    type="checkbox"
                    name=""
                    className="mr-2"
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    id="oneLineOptions"
                    checked={this.state.oneLineOptions}
                    onChange={(e) =>
                      this.setState({ oneLineOptions: e.target.checked })
                    }
                  />
                  <label htmlFor="oneLineOptions" style={{ cursor: "pointer" }}>
                    Options in one line
                  </label>
                </div>
                <div className="col-12 row my-3 ml-5 ">
                  <input
                    type="checkbox"
                    name=""
                    className="mr-2"
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    id="ShuffleOptions"
                    checked={this.state.shuffleOptions}
                    onChange={(e) =>
                      this.setState({ shuffleOptions: e.target.checked })
                    }
                  />
                  <label htmlFor="ShuffleOptions" style={{ cursor: "pointer" }}>
                    Shuffle Options
                  </label>
                </div>
                <div className="col-12"> Scoring Mode :</div>
                <div className="col-12 row my-3 ml-5">
                  <input
                    type="radio"
                    name="match"
                    className="mr-2"
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    id="exactMatch"
                    value="exactMatch"
                    checked={
                      this.state.scoreMode == "exactMatch" ? true : false
                    }
                    onChange={(e) =>
                      this.setState({ scoreMode: e.target.value })
                    }
                  />
                  <label
                    htmlFor="exactMatch"
                    style={{ cursor: "pointer" }}
                    className="mr-5"
                  >
                    Exact match
                  </label>
                  <input
                    type="radio"
                    name="match"
                    value="partialMatch"
                    checked={
                      this.state.scoreMode == "partialMatch" ? true : false
                    }
                    className="mr-2"
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    id="partialMatch"
                    onChange={(e) =>
                      this.setState({ scoreMode: e.target.value })
                    }
                  />
                  <label htmlFor="partialMatch" style={{ cursor: "pointer" }}>
                    Partial match
                  </label>
                </div>
              </React.Fragment>
            ) : null}
            {this.state.mode === "editingMode"  ? (
              <DifficultLevel 
               difficult = {this.state.difficult} 
                cognitive= {this.state.cognitive} 
                onChangeDifficult = {(difficult :any) => { this.setState({difficult :difficult}) }}
                onChangeCognitive = {(cognitive :any) => { this.setState({cognitive :cognitive}) }}
              />

            ) : null}   
            {this.state.mode === "editingMode" ? (
              <div className="col-12 my-3  d-flex justify-content-between">
                <button
                  className="btn btn-success"
                  onClick={() =>
                    this.setState({
                      showObjectives: !this.state.showObjectives,
                    })
                  }
                >
                  Learning objective
                </button>
              </div>
            ) : null}

            {this.state.showObjectives && this.state.mode === "editingMode" && (
              <div className="col-12 row my-3">
                <label>Learning objective</label>
                <textarea
                  className="col-12 form-control"
                  rows={10}
                  value={this.state.objectives}
                  onChange={(e) =>
                    this.setState({ objectives: e.target.value })
                  }
                ></textarea>
              </div>
            )}


            

            
{ this.state.mode == "editingMode" && <div className="text-center col-12">
              <button
                className="btn btn-warning btn-lg rounded col-3"
                onClick={()=>this.onSaveQuestion(this.state)}
             
              >
                {" "}
                Save
              </button>
            </div>}
          </div>
        </div>
      </div>
    );
    // }
  }
}
function mapStateToProps(state: ApplicationState) {
  const Store = { store: state };
  return Store;
}

export default withRouter<any,any>(
  connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
   null // Selects which action creators are merged into the component's props
  )(Order as any)
);
