import React, { useEffect, useRef } from "react";

const AudioPreview = ({ stream }: { stream: MediaStream | null }) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (audioRef.current && stream) {
      audioRef.current.srcObject = stream;
    }
  }, [stream]);
  if (!stream) {
    return null;
  }
  return <audio ref={audioRef} autoPlay />;
};

export default AudioPreview;
