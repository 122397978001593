import React, { Component } from "react";

import RichBox from "./RichBoxComponent";
import { handleCount } from '../../../helpers/validation';


export interface OptionProps {
  id: string;
  option: string;
  style:any;
 
  index: number;
  mode: string;
  correctAnswer : boolean ;
  onOptionUpdate?: any;
  onDeleteOption?: any;

  delete :boolean ;
  required:boolean;
 
}

// const selectedStyle = {
//   cursor: "pointer",
//   backgroundColor: "#9df3b1 !important",
//   border: "4px solid #098434 !important",
//   borderRadius: "10px !important",
// };

class MatchOption extends Component<OptionProps, any> {
  /*   state = {
    id: this.props.id,
    option: this.props.option ?? "",
    authorNote: this.props.authorNote ?? "",
  }; */
  // static getDrivedStateFromProps() {

  // }

  onChangeHandler = (e: any) => {
    // this.setState({ authorNote: e.target.value });
    this.props.onOptionUpdate({ ...this.props, authorNote: e.target.value });
  };
  onChangeHandler2 = (e: string) => {
    //this.setState({ option: e });
    this.props.onOptionUpdate({ ...this.props, option: e });
  };

  

  render() {


    return (
      <div className="col-12 m-2 row align-items-baseline"   >
        {this.props.mode === "editingMode" ? (
          <React.Fragment>
            <span  draggable={true} className="col-auto mr-1"  style={{ cursor: "pointer" }}> {this.props.index} - </span>

            <div className="col p-0 rounded" style ={{ height:"80px !important"}}>
              <RichBox
                onChange={this.onChangeHandler2}
               
                defaultValue={this.props.option}
                height={"10px"}
              ></RichBox>
            </div>
            {this.props.delete ? ( <span
              className="col-auto ml-1"
              style={{ cursor: "pointer" }}
              onClick={() => this.props.onDeleteOption(this.props.id)}
            >
              <i className="fas fa-trash-alt"></i>
            </span>) :null}
           
          </React.Fragment>
        ) : this.props.mode === "examMode" ? (
          <React.Fragment>

                    
          <div className="col-12 row"  style={{ cursor: "pointer"  }} >
          <div className="mr-1 align-items-center d-lg-flex"    > {this.props.index} -</div>
           <div
         
           className={` col-10 text-dark   p-3 bg-white text-dark rounded  height  align-items-center p-3`} 
             dangerouslySetInnerHTML={{ __html: this.props.option }}
             >

         </div>

           </div>
         </React.Fragment>
        ) : this.props.mode === "answeringMode" ? (
          <React.Fragment>

                    
          <div className="col-12 row" draggable={true} style={{ cursor: "pointer"  }} >
          <div className="mr-1 align-items-center d-lg-flex"   draggable={true} > {this.props.index} -</div>
           <div
         
           className={` col-10 text-dark   p-3 bg-white text-dark rounded  height  align-items-center p-3`} 
             dangerouslySetInnerHTML={{ __html: this.props.option }}
             >

         </div>

           </div>
         </React.Fragment>
        ) : this.props.mode === "gradingModeTeacher" ||
          this.props.mode === "gradingModeStudent" ? (
            <React.Fragment>

            <div className="col-12">

           

           
            <div
              className={`  text-dark rounded  col-12 align-items-center p-3 `}
              style={this.props.style}
              dangerouslySetInnerHTML={{ __html: this.props.option }}
              ></div>
              </div>
        
           </React.Fragment>
        ) : (
          <div></div>
        )}
       
      </div>
    );
  }
}

export default MatchOption;
