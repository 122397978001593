import React from "react";
import { buttonList, buttonList2 } from "../../../store/initialStatus";
import { Component } from "react";
import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css";
import { isNullOrUndefined, makeId } from "../../../helpers/func";
import katex from "katex";
import "katex/dist/katex.min.css";

interface RichBoxProps {
  onBlur?: any;
  onChange?: any;
  onFocus?: any;
  fillTheBlack?: any;
  defaultValue?: any;
  height?: any;
  onChangeTxt?: any;
  maxCharCount?: any;
  charCounter?: any;
  placeholder?: any;
  ref?: any;
}

export default class RichBox extends Component<any, RichBoxProps> {
  richbox: any;
  id: string;
  constructor(props: any) {
    super(props);
    this.id = makeId(10);
  }

  componentDidMount() {

    this.richbox = suneditor.create(this.id, {
      // height: this.props.height ?? "50px",
      //minHeight: "50px",
     //DefaultStyle:
     defaultStyle:"font-family: arial; font-size: 20px;",
      resizingBar: true,
      katex: katex,
      plugins: plugins,
      buttonList: buttonList2,
      maxCharCount: this.props.maxCharCount ?? 100000,
      charCounter: this.props.charCounter ?? true,
      placeholder: this.props.placeholder ?? "",  
     
      
    });
    if (this.props.onBlur == undefined) {
      this.richbox.onBlur = this.onBlur;
    } else {
      this.richbox.onBlur = this.props.onBlur;
    }
    if (this.props.onFocus == undefined) {
      this.richbox.onFocus = this.onFocus;
    } else {
      this.richbox.onFocus = this.props.onFocus;
    }
    this.onBlur();

    this.richbox.setContents(this.props.defaultValue ?? "");
    this.richbox.onChange = this.onChange;
    //console.log(this.richbox);
  }

  // componentDidUpdate() {
  //   console.log("Did mount", this.props.defaultValue);
  //   this.richbox.setContents(this.props.defaultValue ?? "");
  // }
    
  onBlur = () => {
    this.richbox.setToolbarButtons([]);
  };
  onFocus = () => {
    this.richbox.setToolbarButtons(buttonList2);
  };
  onChange = (contents: any, core: any) => {
    if (!isNullOrUndefined(this.props.onChange)) this.props.onChange(contents);
    this.onChangeTxt(contents);
  };
  onChangeTxt = (contents: any) => {
    //this.richbox.setContents(this.richbox.getContents(true).replaceAll(">","> "))
    if (!isNullOrUndefined(this.props.onChangeTxt))
      this.props.onChangeTxt(this.richbox.getText());
  };

  insertHtml = (data: any) => {
    this.richbox.insertHTML(data, true, true);
    this.props.onChange(this.richbox.getContents(true));
  };

  setContent = (content: string) => {
    this.richbox.setContents(content);
  };
  render() {
    // console.log(this.props);
    return (
      <textarea
        className="m-5"
        style={{ width: "90%" }}
        id={this.id}
      ></textarea>
    );
  }
}
