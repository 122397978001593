import React from "react";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { actionCreators as Login } from '../store/Login';
import { ApplicationState } from "../../store";
import { actionCreators as UserActionCreator } from "../../store/User";
import $ from "jquery";
import "../../css/DashBoard.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { handleCount } from '../../helpers/validation';

import { makeId } from "../../helpers/func";
import SweetAlert from "react-bootstrap-sweetalert";
import DifficultLevel from "./smallComponents/DifficultLevel";

// import { isNullOrUndefined } from '../../helpers/func';
// import Option, { OptionProps } from "./smallComponents/Option";
const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  GetUser: () => dispatch(UserActionCreator.GET_USER()),
  SetMenu: (menu: any) => dispatch(UserActionCreator.SET_USER_MENU(menu)),
  Logout: () => dispatch(UserActionCreator.CLEAR_USER_LOGOUT()),
});

// let obj = {
//   feedback: "please be consistent",
//   mode: "editingMode",
//   totalMark: "20",
//   header:
//     '<p><span style="font-size: 14px;"><strong>Please C…g>2 * 5 + 4 = ?</strong></span></span></span></p>',
//   options: [
//     {
//       authorNote: "Correct",
//       id: "Gww36bw4fp",
//       option: "<p>14</p>",
//       selected: true,
//       isTrue: false,
//     },

//     {
//       authorNote: "You should do the multiplication first.",
//       id: "3wBfSgxArP",
//       option: "<p>18</p>",
//       selected: false,
//       isTrue: false,
//     },
//   ],
// };
// editingMode
// examMode
// gradingModeTeacher
// gradingModeStudent

function toBase64(file: any) {
  //   console.log(file);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve({ file: reader.result, type: file.type, name: file.name });
    reader.onerror = (error) => reject(error);
  });
}

async function tobase64Handler(files: any) {
  const filePathsPromises: any = [];
  for (let i = 0; i < files.length; i++) {
    filePathsPromises.push(toBase64(files[i]));
  }

  const filePaths = await Promise.all(filePathsPromises);
  return filePaths;
}
class FileUploadEnhanced extends Component<any, any> {
  state = {
    id: makeId(10),
    type: "FileUploadEnhanced",
    showObjectives: false,
    objectives: "",
    feedback: "",
    mode: "editingMode",
    totalMark: "",
    score: "0",
    required: true ,
    header:
      '<p><span style="font-size: 14px;"><strong></strong></span></span></span></p>',
    files: [],
    maxCount: 1,
    max: 1,
    msg: "",
    difficult:"Low",
    cognitive:"Understanding",
    alert : null
  };

  constructor(props: any) {
    super(props)
   

    if (this.props.quest != undefined) {
      this.state = this.props.quest;
    } 
    this.onConfirm = this.onConfirm.bind(this)
  }
  onConfirm (){
       this.setState({alert : null})
  }
  // state = obj;
   onSaveQuestion  (updatedState:any){
    let require=handleCount(this.state.header);
      if(require) {
       this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >  Question Header is required </SweetAlert> )})   
          }else if(this.state.totalMark.length==0){
            this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >    Total mark is required </SweetAlert> )})   
          }else{
            this.props.onSaveQuestion(updatedState)
          }
   }

  componentDidMount() {
    $("#preloader").hide();
  }
  // setHeader = (contents: any) => {
  //   this.header = contents;
  // };

  deleteFileHandler = (id: any) => {
    // console.log(id);
    let files = [...this.state.files.filter((file: any) => file.id !== id)];
    let updatedState = {...this.state ,files }
    this.setState({ files: files ,maxCount :this.state.maxCount+1 });
    this.onSaveQuestion(updatedState)
  };

  openAddFileInputHandler = () => {
    const fileInput = document.getElementById("addFile") as HTMLInputElement;
    fileInput.click();
  };
  addFileHandler = async (e: any) => {
    console.log(e.target.files.length , this.state.maxCount)
    
    if (e.target.files.length > this.state.maxCount) {
      console.log(e.target.files.length , this.state.maxCount)
      const msg = `Your limit is ${this.state.max} files only`;
      e.target.value = null; // discard selected file
      //   console.log(msg);
      this.setState({ msg: msg });
      return false;
    }
    const filess = await tobase64Handler(e.target.files);
    // console.log(files);
    let newFiles: any = filess.map((file: any) => {
      return {
        data: file.file,
        type: file.type.split("/")[0],
        name: file.name,
        id: makeId(10),
      };
    });
    let files =this.state.files.concat(newFiles);
    this.setState({
      files: this.state.files.concat(newFiles),
      maxCount: this.state.maxCount - filess.length,
      msg: "",
    });

    let updatedState = {...this.state , files}
    if(this.state.mode == "examMode")
    this.onSaveQuestion(updatedState)
  };
  onHandleCount=()=>{
  
    let require=handleCount(this.state.header);   
    if(require===false){
    this.setState({required:false});
    }else{
    
    this.setState({required:true});

    }
  }


  render() {
    let files = this.state.files.map((file: any) => {
      return (
        <div className="col-auto rounded fileContainer" key={file.id}>
          {file.type === "image" ? (
            <img
              src={file.data}
              className="col-12 rounded mx-auto d-block File_Img"
              alt="..."
            ></img>
          ) : (
            <i className=" fas fa-file-alt File_PDF"></i>
          )}
          <i
            className="fas fa-minus-circle deleteFile"
            onClick={() => this.deleteFileHandler(file.id)}
          ></i>
        </div>
      );
    });
   
    return (
      <div className="mx-5">
        {this.state.alert}
        <div className="row mx-auto rounded my-5 p-4 shadow ">
        {this.state.mode =="examMode" && (<div> Q {this.props.index} <br></br> </div> )}  
          <div className="col-12 mx-auto bg-question row align-items-center rounded p-3 text-white">
            <div className="col-12 row justify-content-between">
              <h3>
                {this.state.mode === "editingMode"
                  ? "Question Setup"
                  : "Question"}
              </h3>

              <div className="d-flex flex-column">
                <div className="form-group d-flex align-items-center my-3">
                  <span className="col-auto text-shadow ">
                    Total mark : &nbsp;
                  </span>
                  {this.state.mode === "editingMode" ||
                  this.state.mode === "answeringMode" ? (
                    <input
                      type="number"
                      min={0}
                      className="col form-control"
                      name=""
                      id=""
                      value={this.state.totalMark}
                      onChange={(e) =>
                        this.setState({ totalMark: e.target.value })
                      }
                    />
                  ) : this.state.mode === "examMode" ||
                    this.state.mode === "gradingModeTeacher" ||
                    this.state.mode === "gradingModeStudent" ? (
                    <span className="text-shadow">{this.state.totalMark}</span>
                  ) : null}
                  {this.state.mode === "gradingModeTeacher" ? (
                    <>
                      <span className="col-auto border-left ml-2">
                        Score : &nbsp;
                      </span>
                      <input
                        type="number"
                        min={0}
                        className="col form-control"
                        name=""
                        id=""
                        value={this.state.score}
                        onChange={(e) =>
                         {
                          let score = e.target.value
                          let updatedState = {...this.state , score }
                          this.onSaveQuestion(updatedState)
                          this.setState({ score: e.target.value })
                         }
                        }
                      />
                    </>
                  ) : this.state.mode === "gradingModeStudent" ? (
                    <span className="border-left ml-2 pl-2 text-shadow">
                      Score : &nbsp;{this.state.score}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-12 mb-5 row justify-content-center align-items-center">
              {this.state.mode === "editingMode" ? (
                <div className="col-12 w-100 row justify-content-center align-items-center">
                  <RichBox
                    onChange={(v: any) => {this.setState({ header: v });this.onHandleCount();}}
                    onChangeTxt={(a: any) => {this.setState({ headertxt: a });this.onHandleCount();}}
                    defaultValue={this.state.header}
                    height={"10px"}
                  ></RichBox>
                </div>
              ) : this.state.mode === "examMode" ||
                this.state.mode === "answeringMode" ||
                this.state.mode === "gradingModeTeacher" ||
                this.state.mode === "gradingModeStudent" ? (
                <div
                  className="bg-white w-100 text-dark rounded row align-items-center p-3"
                  dangerouslySetInnerHTML={{ __html: this.state.header }}
                ></div>
              ) : (
                <div></div>
              )}
            </div>

            {this.state.mode === "editingMode" ? (
              <div className="col-12 row align-items-center my-3 mx-auto">
                <label htmlFor="max" className="col-auto">
                  Max Files
                </label>
                <select
                  className="col-sm-1 form-control"
                  name=""
                  id=""
                  value={this.state.max}
                  onChange={(e: any) =>
                    this.setState({
                      maxCount: +e.target.value - this.state.files.length,
                      max: +e.target.value,
                    })
                  }
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div>
            ) : (
              <div className="col-12 row align-items-center my-3">
                <label htmlFor="max" className="col-auto">
                  Max Files: {this.state.max}
                </label>
              </div>
            )}
             <hr className="col-10"  style ={{color:"white" ,backgroundColor :"white"}}/>

             {this.state.mode === "editingMode"  ? (
              <DifficultLevel 
               difficult = {this.state.difficult} 
                cognitive= {this.state.cognitive} 
                onChangeDifficult = {(difficult :any) => { this.setState({difficult :difficult}) }}
                onChangeCognitive = {(cognitive :any) => { this.setState({cognitive :cognitive}) }}
              />

            ) : null}   
            {this.state.mode === "examMode" ? (
              <div className="col-12">
                <button
                  className="btn bg-info text-white"
                  onClick={this.openAddFileInputHandler}
                >
                  upload
                </button>
                <input
                  accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
                  id="addFile"
                  multiple={true}
                  hidden={true}
                  type="file"
                  onChange={this.addFileHandler}
                />
              </div>
            ) : null}

            {this.state.mode === "examMode" ||
            this.state.mode === "gradingModeTeacher" ||
            this.state.mode === "gradingModeStudent" ? (
              <div className="col-12 row p-3 align-content-start align-items-center mx-auto justify-content-start">
                {this.state.msg && (
                  <div className="col-12">
                    <h3>{this.state.msg}</h3>
                  </div>
                )}
                {files}
              </div>
            ) : null}

            <div className="col-12 row">
              {this.state.mode === "gradingModeTeacher" ||
              this.state.mode === "gradingModeStudent" ? (
                <div className="col-12 row align-items-start align-content-start p-2 mx-auto">
                  <label className="align-self-start">Feedback</label>
                  <textarea
                    className="form-control align-self-stretch "
                    name=""
                    id=""
                    value={this.state.feedback}
                    rows={5}
                    disabled={
                      this.state.mode === "gradingModeStudent" ? true : false
                    }
                    onChange={(e) =>
                     {
                      let feedback = e.target.value ; 
                      let updatedState = {...this.state , feedback}
                      this.onSaveQuestion(updatedState)
                      this.setState({ feedback: e.target.value })
                     }
                    }
                  ></textarea>
                </div>
              ) : null}
            </div>

         

            {this.state.mode === "editingMode"  ? (
              <div className="col-12 my-3  d-flex justify-content-between">
                <button
                  className="btn btn-success"
                  onClick={() =>
                    this.setState({
                      showObjectives: !this.state.showObjectives,
                    })
                  }
                >
                  Learning objective
                </button>
              </div>
            ) : null}


{this.state.showObjectives &&  this.state.mode === "editingMode" && (
              <div className="col-12 row my-3">
                <label>Learning objective</label>
                <textarea
                  className="col-12 form-control"
                  rows={10}
                  value={this.state.objectives}
                  onChange={(e) =>
                    this.setState({ objectives: e.target.value })
                  }
                ></textarea>
              </div>
            )}
          
          { this.state.mode == "editingMode" && <div className="text-center col-12">
              <button
                className="btn btn-warning btn-lg rounded col-3"
                onClick={()=>this.onSaveQuestion(this.state)}
             
              >
                {" "}
                Save
              </button>
            </div>}
          </div>
        </div>
      </div>
    );
    // }
  }
}
function mapStateToProps(state: ApplicationState) {
  const Store = { store: state };
  return Store;
}

export default withRouter<any,any>(
  connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    mapDispatchToProps // Selects which action creators are merged into the component's props
  )(FileUploadEnhanced as any)
);
