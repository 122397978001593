import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { Request} from '../helpers/func';
import { CC_DO_ACTION,CLEAR_USER_LOGOUT } from './ActionTypes';
import * as Initial from './initialStatus';
import md5 from 'js-md5';

/** 
 * status 
 *  204 => ok same data in server
 *  401 => Error unauthorized
 *  403 => Error Forbidden not loggedin
 *  500 => Error server error
 *  -1 => Error 
 */

export interface Page {
    msg: string;
    Table:[]| null|undefined
}

 export interface ComponentPage {
    state:any| undefined
}



interface GetData {
    type: string;
    pageName:string;
    data:any|null |undefined
};



type KnownAction = GetData;

export const actionCreators = {
    GetTable: (path:string , method:string,pageName:string,data:any): AppThunkAction<KnownAction | any> => async (dispatch, getState) => {
        return new Promise<any>(async (resolve, rejects) => {
            const appState = getState();
              
            if (appState && appState.componentCreator && appState.user) {
                let table: Array<any>=[];
                let d: any = md5(" "); 
                if (appState.componentCreator[pageName] !== undefined) {
                    //debugger;
                    table = appState.componentCreator[pageName].Table || null;
                    if (table !== null) {
                        if (Object.keys(table).length === 0) table = [];
                        d = md5(JSON.stringify(table));                        
                    }
                }       
                await Request(Initial.url + path, method,  d, appState.user.token)
                    .then((res) => {
                        let status = res.status;
                        let jdata = res.json;
                          
                        if (status === 200) {
                            // New Data or data changed
                            //  
                            dispatch({ type: CC_DO_ACTION, pageName: pageName, data: { Table: jdata, msg: "", isLoading: false, gotData: true } });
                            //  
                        }else if (status === 208) {
                            //dispatch({ type: CC_DO_ACTION,pageName:pageName, data:{ Table: table,msg: "Done",isLoading:false,gotData:true} });
                        } else if (status === 403) {
                            // Not Logged in
                            dispatch({ type: CLEAR_USER_LOGOUT, user: Initial.UserState })
                        }
                        resolve(status);
                    }).catch((err: any) => {
                          
                        rejects (-1)
                        dispatch({ type: CC_DO_ACTION,pageName:pageName, data:{msg: "Error",isLoading:false,gotData:true} });
                        //dispatch({ type: Error_USERS,msg:"Error",Users:Us});
                    });
            } else {
                rejects (-2);
            }
        
        });


        
    }
    ,
    AddItem: (path:string , method:string,pageName:string,data:any): AppThunkAction<KnownAction | any> => async (dispatch, getState) => {
        return new Promise<any>(async (resolve, rejects) => {
            const appState = getState();
            if (appState && appState.componentCreator && appState.user) {
                let table: Array<any>=[];
                if(appState.componentCreator[pageName] !== undefined){
                    table = [...appState.componentCreator[pageName].Table] || [];
                }
                //  
                            
                await Request(Initial.url + path, method, data, appState.user.token)
                    .then((res) => {
                        let status = res.status;
                        let jdata = res.json;
                        //  
                        if (status === 200) {
                            // New Data or data changed
                            //  
                            //let x = []
                            //  
                            table.push(jdata.Result);
                            //  
                            dispatch({ type: CC_DO_ACTION,pageName:pageName, data:{Table: table, msg: ""} });
                            //  
                        } else if (status === 403) {
                            // Not Logged in
                            dispatch({ type: CLEAR_USER_LOGOUT, user: Initial.UserState })
                        }
                        resolve(status);
                    }).catch((err: any) => {
                          
                        rejects (-1)
                        //dispatch({ type: Error_USERS,msg:"Error",Users:Us});
                    });
            } else {
                rejects (-2);
            }
        
        });


        
    },
    EditItem: (path:string , method:string,pageName:string,data:any): AppThunkAction<KnownAction | any> => async (dispatch, getState) => {
        return new Promise<any>(async (resolve, rejects) => {
            const appState = getState();
            
            if (appState && appState.componentCreator && appState.user) {
                let table: Array<any>=[];
                if(appState.componentCreator[pageName] !== undefined){
                    table = [...appState.componentCreator[pageName].Table] || [];
                }
                           
                await Request(Initial.url + path, method, data, appState.user.token)
                    .then((res) => {
                        let status = res.status;
                        let jdata = res.json;
                        //  
                        if (status === 200) {
                            // New Data or data changed
                            let res = parseInt(data[Object.keys(data)[0]]);
                            if (res === NaN) {
                                res = data[Object.keys(data)[0]];
                            }
                            let oldObj = table.find(x =>                               
                                 x[Object.keys(x)[0]] === res
                            )[0];
                            let i = table.indexOf(oldObj);
                            table[i] = data;
                            dispatch({ type: CC_DO_ACTION,pageName:pageName, data:{Table: table, msg: ""} });
                            //  
                        } else if (status === 403) {
                            // Not Logged in
                            dispatch({ type: CLEAR_USER_LOGOUT, user: Initial.UserState })
                        }
                        resolve(status);
                    }).catch((err: any) => {
                        rejects (-1)
                        //dispatch({ type: Error_USERS,msg:"Error",Users:Us});
                    });
            } else {
                rejects (-2);
            }
        
        });


        
    },
    DeleteItem: (path:string , method:string,pageName:string,data:any): AppThunkAction<KnownAction | any> => async (dispatch, getState) => {
        return new Promise<any>(async (resolve, rejects) => {
            const appState = getState();
            
            if (appState && appState.componentCreator && appState.user) {
                let table: Array<any>=[];
                if(appState.componentCreator[pageName] !== undefined){
                    table = [...appState.componentCreator[pageName].Table] || [];
                }
                           
                await Request(Initial.url + path, method, data, appState.user.token)
                    .then((res) => {
                        let status = res.status;
                        let jdata = res.json;
                        //  
                        if (status === 200) {
                            // New Data or data changed
                            //  
                            //let x = []
                            let oldObj = table.find(x => x[Object.keys(x)[0]] === data)[0];
                            let i = table.indexOf(oldObj);
                            table.splice(i,1);
                            dispatch({ type: CC_DO_ACTION,pageName:pageName, data:{Table: table, msg: ""} });
                            //  
                        } else if (status === 403) {
                            // Not Logged in
                            dispatch({ type: CLEAR_USER_LOGOUT, user: Initial.UserState })
                        }
                        resolve(status);
                    }).catch((err: any) => {
                          
                        rejects (-1)
                        //dispatch({ type: Error_USERS,msg:"Error",Users:Us});
                    });
            } else {
                rejects (-2);
            }
        
        });


        
    }
};
 

export const reducer: Reducer<any> = (state: any | undefined, incomingAction: Action): any => {
    //  
    if (state === undefined) {
        return {};
    }
    
    const action = incomingAction as KnownAction;
    //  
    let data:any = {};
    data[action.pageName]=action.data;
    switch (action.type) { 
        case CC_DO_ACTION:
            //  {data });
            return {...state,...data}

    }
 
    return state;
};
