import React from "react";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { actionCreators as Login } from '../store/Login';
import { ApplicationState } from "../../store";
import { actionCreators as AssessmentActionCreator } from "../../store/Assessment";
import $ from "jquery";
import "../../css/DashBoard.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { makeId } from "../../helpers/func";
import { handleCount } from '../../helpers/validation';
import SweetAlert from "react-bootstrap-sweetalert";

import DifficultLevel from "./smallComponents/DifficultLevel";
// import { isNullOrUndefined } from '../../helpers/func';
// import Option, { OptionProps } from "./smallComponents/Option";
const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  AddAssessment: (data: any) =>
    dispatch(AssessmentActionCreator.AddAssessment(data)),
  GetAssessment: (id: any) =>
    dispatch(AssessmentActionCreator.GetAssessment(id)),
});


function shuffle(array: any) {
  var currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
class TrueOrFalse extends Component<any, any> {

  state = {
    id: makeId(10),
    type: "TrueOrFalse",
    showObjectives: false,
    shuffleOptions: false,
    objectives: "",
    feedback: "",
    mode: "editingMode",
    totalMark: "",
    score: "0",
    header:'<p></p>',
    headertxt:'',
    required:true,
    options: [
      {
        id: makeId(10),
        option: "True",
        authorNote: "",
        selected: false,
        isTrue: false,
      },
      {
        id: makeId(10),
        option: "False",
        authorNote: "",
        selected: false,
        isTrue: false,
      },
    ],
    choose_flag:false,
    difficult:"Low",
    cognitive:"Understanding",
    alert : null ,
  };
  constructor(props :any){
    super(props)
    if (this.props.quest != undefined) {
      this.state = this.props.quest;
    } 
    this.onConfirm = this.onConfirm.bind(this)
  }
  onConfirm (){
       this.setState({alert : null})
  }
  // state = obj;
   onSaveQuestion  (updatedState:any){
    let require=handleCount(this.state.header);
      if(require) {
       this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >  Question Header is required </SweetAlert> )})   
          }else if(this.state.totalMark.length==0){
            this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >    Total mark is required </SweetAlert> )})   
          }else if(this.state.choose_flag==false){
            this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >    Please select correct answer from answer mode  </SweetAlert> )})   
          }else{
            this.props.onSaveQuestion(updatedState)
          }
   }



  componentDidMount() {
    $("#preloader").hide();
    if (this.state.mode === "examMode" && this.state.shuffleOptions) {
      const shuffled = shuffle([...this.state.options]);
      this.setState({ options: shuffled, shuffleOptions: false });
    }
  }
 
  onChangeHandler = (value: any, id: any) => {
    let newOp = [
      ...this.state.options.map((op) => {
        if (op.id === id) {
          op.authorNote = value;
        }
        return op;
      }),
    ];
    this.setState({ options: newOp });
  };

  onClickHandler = (id: any) => {
    let newOp = [
      ...this.state.options.map((op:any) => {
        if (op.id === id) {
          op.selected = true;
        } else {
          op.selected = false;
        }
        return op;
      }),
    ];
    this.setState({ options: newOp });
    let options = newOp
    let updatedState = {...this.state , options}
    
if(this.state.mode == "examMode")
    this.onSaveQuestion(updatedState)
  };

  addScoreHandler = (id: any) => {
    let newOp = [
      ...this.state.options.map((op) => {
        if (op.id === id) {
          if (op.selected === true && op.isTrue === true) {
            let score = this.state.totalMark ;
             let updatedState = {...this.state , score}
            this.setState({ score: this.state.totalMark });
            this.onSaveQuestion(updatedState)
          } else {
            let score = 0 ;
            let updatedState = {...this.state , score}
           this.onSaveQuestion(updatedState)
            this.setState({ score: "0" });
          }
        }
        return op;
      }),
    ];
    this.setState({ options: newOp });
  };

  toggleHandler = () => {
    if (this.state.mode === "editingMode") {
      this.setState({ mode: "answeringMode" });
    } else {
      this.setState({ mode: "editingMode" });
    }
  };

  onChooseCorrect = (id: any) => {
    // console.log(id);
    let newOp = [
      ...this.state.options.map((op) => {
        if (op.id === id) {
          op.isTrue = true; 
          this.setState({choose_flag:"true"})
        } else {
          op.isTrue = false;
        }
        return op;
      }),
    ];
    this.setState({ options: newOp });
  };

  


  render() {
    let ops = this.state.options.map((op,index) => {
      return this.state.mode === "answeringMode" ? (
        <div
          className={`col-12 normal my-1 d-flex align-items-start p-2 fs-1 ${
            op.isTrue ? "selected" : "not-selected"
          }`}
          key={index}
        >
          <div className="col-auto mr-2">
            <input
              type="checkbox"
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
              onChange={() => this.onChooseCorrect(op.id)}
              checked={op.isTrue}
            />
          </div>
          <span
            className="col"
            style={{ cursor: "pointer" }}
            onClick={() => this.onChooseCorrect(op.id)}
          >
            {op.option}
          </span>
        </div>
      ) : this.state.mode === "editingMode" ? (
        <React.Fragment key={op.id}>
          <div className="col-12 normal my-1 d-flex align-items-start p-2 fs-1">
            <span className="col">{op.option}</span>
          </div>
          <div className="col-12  justify-content-center d-flex p-3 fs-1">
            <label className="col-auto text-white">Author note : </label>
           
            <input
            
              className="col-8 normal"
              value={op.authorNote}
              // onChange={this.onChangeHandler}
              onChange={(e) => this.onChangeHandler(e.target.value, op.id)}
            ></input>
          </div>
        </React.Fragment>
      ) : this.state.mode === "examMode" ? (
        <div
          className={`col-12 normal my-1 d-flex align-items-start p-2 fs-1 ${
            op.selected ? "choose-it" : "not-selected"
          }`}
          onClick={() => {
            this.onClickHandler(op.id);
            this.addScoreHandler(op.id);
          }}
          key={op.id}
        >
          <span className="col">{op.option}</span>
        </div>
      ) : this.state.mode === "gradingModeTeacher" ||
        this.state.mode === "gradingModeStudent" ? (
        <React.Fragment key={op.id}>
          <div
            className={`col-12 normal my-1 d-flex align-items-start p-2 fs-1 ${
              op.selected && op.isTrue
                ? "correct-answer"
                : op.selected && !op.isTrue
                ? "wrong-answer"
                : !op.selected && op.isTrue
                ? "normal"
                : ""
            }`}
            key={op.id}
          >
            <span className="col">{op.option}</span>
          </div>
          <div className="pl-3 row align-items-center" style={{ width: "90%" }}>
            <label className="col-auto text-white">
            {op.authorNote != "" && `Author note : ${op.authorNote }`}  
            </label>
          </div>
        </React.Fragment>
      ) : null;
    });
   
    return (
      <div className="mx-5">
    {this.state.alert}

        <div className="row mx-auto rounded my-5 p-4 shadow ">
          <div className="col-12 row mx-auto align-items-center bg-question rounded p-3 text-white">
            <div className="col-12 row justify-content-between">
              
              {this.state.mode == "editingMode" ? (<h3>Question Setup</h3>) : null}

              {this.state.mode == "answeringMode" ? (<h3>Correct answer setup</h3>) : null}

            

              <div className="d-flex flex-column">
                {this.state.mode === "editingMode" ||
                this.state.mode === "answeringMode" ? (
                  <button
                    className="btn btn-warning text-white "
                    onClick={this.toggleHandler}
                  >
                    {this.state.mode == "editingMode"
                      ? "Go to Answer mode"
                      : "Go to Question setup mode"}
                  </button>
                ) : null}
                               {this.state.mode =="examMode" && (<div> Q {this.props.index} <br></br> </div> )}  

                <div className="form-group d-flex align-items-center my-3">
                  <span className="col-auto text-shadow ">
                    Total mark : &nbsp;
                  </span>
                  {this.state.mode === "editingMode" ||
                  this.state.mode === "answeringMode" ? (
                    <input
                      type="number"
                      min={0}
                      className="col form-control"
                      name=""
                      id=""
                      value={this.state.totalMark}
                      onChange={(e) =>
                        this.setState({ totalMark: e.target.value })
                      }
                    />
                  ) : this.state.mode === "examMode" ||
                    this.state.mode === "gradingModeTeacher" ||
                    this.state.mode === "gradingModeStudent" ? (
                      <React.Fragment>

                   
                    <span className="text-shadow">{this.state.totalMark}</span>
                      </React.Fragment>
                  ) : null}
                  {this.state.mode === "gradingModeTeacher" || this.state.mode === "gradingModeStudent" ? (
                    <span className="border-left ml-2 pl-2 text-shadow">
                      Score : &nbsp;{this.state.score}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-12 mb-5 row justify-content-center align-items-center">
              {this.state.mode === "editingMode" ? (
                <div className="col-12 w-100 row justify-content-center align-items-center">
                  <RichBox
                    onChange={(v: any) => {this.setState({ header: v });}}
                    defaultValue={this.state.header}
                    height={"10px"}
                  ></RichBox>
                </div>
              ) : this.state.mode === "examMode" ||
                this.state.mode === "answeringMode" ||
                this.state.mode === "gradingModeTeacher" ||
                this.state.mode === "gradingModeStudent" ? (
                  <React.Fragment>

              <div
                  className="bg-white w-100 text-dark rounded row align-items-center p-3"
                  dangerouslySetInnerHTML={{ __html: this.state.header }}
                  ></div>
                  </React.Fragment>
              ) : (
                <div></div>
              )}
            </div>
            <h5 className="col-12">Options :</h5>
            <div className="col-12 row">
              <div
                className={`text-dark col-12 row align-items-center  mx-auto`}
              >
                {ops}
              </div>
              
              {this.state.mode === "gradingModeTeacher" ||
              this.state.mode === "gradingModeStudent" ? (
                <div className="col-12 row align-items-start align-content-start p-2 mx-auto">
                  <label className="align-self-start">Feedback</label>
                  <textarea
                    className="form-control align-self-stretch "
                    name=""
                    id=""
                    value={this.state.feedback}
                    rows={5}
                    disabled={
                      this.state.mode === "gradingModeStudent" ? true : false
                    }
                    onChange={(e) =>
                      {
                          let feedback = e.target.value ; 
                          let updatedState = {...this.state , feedback}
                        this.onSaveQuestion(updatedState)
                        this.setState({ feedback: e.target.value })
                      }
                    }
                  ></textarea>
                </div>
              ) : null}
            </div>
             <hr className="col-10"  style ={{color:"white" ,backgroundColor :"white"}}/>
            {this.state.mode === "editingMode" ? (
              <div className="col-12 row my-3 align-items-start ml-1">
                <input
                  type="checkbox"
                  name=""
                  className="mr-2"
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                  id="ShuffleOptions"
                  checked={this.state.shuffleOptions}
                  onChange={(e) =>
                    this.setState({ shuffleOptions: e.target.checked })
                  }
                />
                <label htmlFor="ShuffleOptions" style={{ cursor: "pointer" }}>
                  Shuffle Options
                </label>
              </div>
            ) : null}


{this.state.mode === "editingMode"  ? (
              <DifficultLevel 
               difficult = {this.state.difficult} 
                cognitive= {this.state.cognitive} 
                onChangeDifficult = {(difficult :any) => { this.setState({difficult :difficult}) }}
                onChangeCognitive = {(cognitive :any) => { this.setState({cognitive :cognitive}) }}
              ></DifficultLevel>

            ) : null}

         
            {this.state.mode === "editingMode" ? (
              <div className="col-12 my-3  d-flex justify-content-between ">
                <button
                  className="btn btn-success"
                  onClick={() =>
                    this.setState({
                      showObjectives: !this.state.showObjectives,
                    })
                  }
                >
                  Learning objective
                </button>
              </div>
            ) : null}
               {this.state.showObjectives && this.state.mode === "editingMode" && (
              <div className="col-12 row my-3 ">
                <label>Learning objective</label>
                <textarea
                  className="col-12 form-control"
                  rows={10}
                  value={this.state.objectives}
                  onChange={(e) =>
                    this.setState({ objectives: e.target.value })
                  }
                ></textarea>
              </div>
            )}

            { this.state.mode == "editingMode" && <div className="text-center col-12">
              <button
                className="btn btn-warning btn-lg rounded col-3"
                onClick={()=>this.onSaveQuestion(this.state)}
             
              >
                {" "}
                Save
              </button>
            </div>}
            
          </div>
        </div>
      </div>
    );
    // }
  }
}
function mapStateToProps(state: ApplicationState) {
  const Store = { store: state };
  return Store;
}

export default withRouter<any,any>(
  connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    mapDispatchToProps // Selects which action creators are merged into the component's props
  )(TrueOrFalse as any)
);
