import React from "react";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { actionCreators as Login } from '../store/Login';
import { ApplicationState } from "../../store";
import { actionCreators as UserActionCreator } from "../../store/User";
import $ from "jquery";
import "../../css/DashBoard.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { handleCount } from '../../helpers/validation';
import { makeId } from "../../helpers/func";
import SweetAlert from "react-bootstrap-sweetalert";
import DifficultLevel from "./smallComponents/DifficultLevel";
//import { count } from "console";
// import { isNullOrUndefined } from '../../helpers/func';
const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  GetUser: () => dispatch(UserActionCreator.GET_USER()),
  SetMenu: (menu: any) => dispatch(UserActionCreator.SET_USER_MENU(menu)),
  Logout: () => dispatch(UserActionCreator.CLEAR_USER_LOGOUT()),
});
let obj = {
  id: makeId(10),
  type:"EssayComponent",
  showObjectives: false,
  objectives: "",
  feedback: "",
  mode: "editingMode",
  totalMark: "",
  score:"0",
  header:'<p></p>',
  answerPlaceHolder:'',
  stdanswer:'<p></p>',
  stdanswerTxt:'',
  wordlimit: 100000,
  allowOverLimit:false,
  count:0,
  overcount:false,
  showWordCount:false,
  showWordLimit:false,
  headertxt:'',
  required:true,
  difficult:"Low",
  cognitive:"Understanding",
  alert :null
};
// editingMode
// examMode
// gradingModeTeacher
// gradingModeStudent
class Essay extends Component<any, any> {
 
  constructor(props :any){
    super(props)
    this.state = obj;
       //console.log("this is prop ", props)
       if (this.props.quest != undefined) {
         this.state = this.props.quest;
       } else {
         this.state = obj;
       }
       this.onConfirm = this.onConfirm.bind(this)
      }
      onConfirm (){
           this.setState({alert : null})
      }
      // state = obj;
       onSaveQuestion  (updatedState :any){
        // disabled={this.state.required||(this.state.totalMark.length==0)||this.state.answerPlaceHolder.length==0}

        let require=handleCount(this.state.header);
          if(require) {
           this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >  Question Header is required </SweetAlert> )})   
              }else if(this.state.totalMark.length==0){
                this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >    Total mark is required </SweetAlert> )})   
              }else{
                this.props.onSaveQuestion(updatedState)
              }
       }
  componentDidMount() {
    $("#preloader").hide();
  }
  onHandleCount=()=>{
    let require=handleCount(this.state.header);
    this.setState({required:require});
  }
  // setHeader = (contents: any) => {
  //   this.header = contents;
  // };

  handleCount() {
    var content = this.state.stdanswer;

    var tempallword = $('<div>').html(content).text();
    //const allword_2 = tempallword.split(/\S+/);
    //const allword = tempallword.split(/(\w)\w+/);
    //const allword = tempallword.matchAll(/[ ]*[\.]*[<A-z>'"|\d]+[\.]*[ ]*/);
    //console.log((allword));
    //console.log((allword.length));
    //console.log(tempallword);
    let regexp: RegExp = /[ ]*[\.]*[<A-z>'"|\d]+[\.]*[ ]*/g;
    const str = tempallword;
    //const array=regexp.exec(str);
    const array = [...str.matchAll(regexp)];

    this.setState({
      count: array.length
    })
    if(this.state.count>this.state.wordlimit){
      this.setState({
        overcount:true
      })
    }else{
      this.setState({overcount : false})
    }
  }
  render() {;
    return (
      <div className="mx-5">
        {this.state.alert}
        <div className="row mx-auto rounded my-5 p-4 shadow ">
        {this.state.mode =="examMode" && (<div> Q {this.props.index} <br></br> </div> )}  
          <div className="col-12 row mx-auto align-items-center bg-question rounded p-3 text-white">
            <div className="col-12 row justify-content-between">
              <h3 className="text-shadow">Question Setup</h3>
      
              <div className="d-flex flex-column">
                <div className="form-group d-flex align-items-center my-3">
                  <span className="col-auto text-shadow">
                    Total mark :
                  </span>
                  {this.state.mode === "editingMode"? (
                    <input
                      type="number"
                      min={0}
                      className="col form-control"
                      name=""
                      id=""
                      value={this.state.totalMark}
                      onChange={(e) =>
                        this.setState({ totalMark: e.target.value })
                      }
                      required
                    />
                  ) : this.state.mode === "examMode" ||
                    this.state.mode === "gradingModeTeacher" ||
                    this.state.mode === "gradingModeStudent" ? (
                    <span className="text-shadow">{this.state.totalMark}</span>
                  ) : null}
                  {this.state.mode === "gradingModeTeacher"? (
                    <>
                    <span className="col-auto border-left ml-2">Score : &nbsp;</span>
                    <input
                      type="number"
                      min={0}
                      className="col form-control"
                      name=""
                      id=""
                      value={this.state.score}
                      onChange={(e) =>{

                        let score = e.target.value
                        let updatedState = {...this.state , score }
                        this.onSaveQuestion(updatedState)
                        this.setState({ score: e.target.value })
                      }
                      }
                      required
                    />
                    </>
                  ) :this.state.mode === "gradingModeStudent" ? (
                    <>
                    <span className="border-left ml-2 pl-2 text-shadow">
                      Score : &nbsp;{this.state.score}
                    </span>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-12 row justify-content-center align-items-center">
              {this.state.mode === "editingMode" ? (
                <div className="col-12 w-100 row justify-content-center align-items-center">
                  <RichBox
                    onChange={(v: any) => {this.setState({ header: v });this.onHandleCount();}}
                    onChangeTxt={(a: any) => {this.setState({ headertxt: a });this.onHandleCount();}}
                    defaultValue={this.state.header}
                    height={"100%"}
                  ></RichBox>
                  <div className="mt-2 col-8 row justify-content-center">
                  <span className="mt-1">word limit : &nbsp;</span>
                    <input
                      type="number"
                      min={0}
                      className="col-12 form-control"
                      name=""
                      id=""
                      value={this.state.wordlimit}
                      onChange={(e) =>
                        this.setState({ wordlimit: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="mt-2 col-8 row justify-content-center">
                  <span className="mt-1"> Placeholder text for students : &nbsp;</span>
                    <textarea
                      className="col-12 form-control"
                      rows={3}
                      value={this.state.answerPlaceHolder}
                      onChange={(e) =>
                        this.setState({ answerPlaceHolder: e.target.value })
                      }
                      required
                    ></textarea>
                  </div>
             <hr className="col-10"  style ={{color:"white" ,backgroundColor :"white"}}/>

                  <div className="col-12 row my-3 align-items-start">
                  <input
                  type="checkbox"
                  name="show_word_count"
                  className="mr-2"
                  checked={this.state.showWordCount}
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                  id="show_word_count"
                  onChange={(e) =>
                    this.setState({ showWordCount: e.target.checked })
                  }
                  />
                  
                  <label htmlFor="show_word_count" style={{ cursor: "pointer" }}>
                  Show word count
                  </label>
                  </div>
                  <div className="col-12 row my-3 align-items-start">
                  <input
                  type="checkbox"
                  name="show_word_limit"
                  className="mr-2"
                  checked={this.state.showWordLimit}
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                  id="show_word_limit"
                  onChange={(e) =>
                    this.setState({ showWordLimit: e.target.checked })
                  }
                  />
                  <label htmlFor="show_word_limit" style={{ cursor: "pointer" }}>
                  Show word limit
                  </label>
                  </div>
                  <div className="col-12 row my-3 align-items-start">
                  <input
                  type="checkbox"
                  name="allow_over_count"
                  checked={this.state.allowOverLimit}
                  className="mr-2"
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                  id="allow_over_count"
                  onChange={(e) =>
                    this.setState({ allowOverLimit: e.target.checked })
                  }
                  />
                  <label htmlFor="allow_over_count" style={{ cursor: "pointer" }}>
                  allow submissions over word limit
                  </label>
                  </div>
                </div>
              ) : this.state.mode === "examMode" ||
                this.state.mode === "gradingModeTeacher" ||
                this.state.mode === "gradingModeStudent" ? (
                <div
                  className="bg-white w-100 text-dark rounded row align-items-center p-3"
                  dangerouslySetInnerHTML={{ __html: this.state.header }}
                ></div>
              ) : (
                <div></div>
              )}
            </div>
            
            {this.state.mode === "editingMode"  ? (
              <DifficultLevel 
               difficult = {this.state.difficult} 
                cognitive= {this.state.cognitive} 
                onChangeDifficult = {(difficult :any) => { this.setState({difficult :difficult}) }}
                onChangeCognitive = {(cognitive :any) => { this.setState({cognitive :cognitive}) }}
              />

            ) : null}   
            <div className="col-12 row justify-content-center align-items-center">
                {this.state.mode === "examMode" ? (
                  <>
                  <div className="col-12 w-100 row mt-3 justify-content-center align-items-center">
                    <RichBox
                      onChange={(a: any) => {this.setState({ stdanswer: a });this.handleCount();
                           this.onSaveQuestion(this.state)
                    }}
                      onChangeTxt={(a: any) => {this.setState({ stdanswerTxt: a });this.handleCount();}}
                      defaultValue={this.state.stdanswer}
                      height={"100%"}
                      //maxCharCount={1000}
                      charCounter={true}
                      placeholder={this.state.answerPlaceHolder}
                    ></RichBox>
                    <p className="mt-1 bg-danger text-white" style={{fontSize:"1.5rem"}}>{(this.state.overcount ? "Warning! you have exceeded word count!":"")}</p>
                  </div>
                  <div className="mt-2 col-12 row justify-content-center">
                  
                  {this.state.showWordCount===true?(<p className="p-3 m-1 bg-info text-white">word count: {this.state.count}</p>):null}
                  {this.state.showWordLimit===true?(<p className="p-3 m-1 bg-info text-white">Max word count: {this.state.wordlimit}</p>):null}
                  <p className="p-3 m-1 bg-info text-white">Allowed to submit exceeded word count: {(this.state.allowOverLimit ? "yes" : "no")}</p>
                  </div>
                  </>
                ) : 
                  this.state.mode === "gradingModeTeacher" ||
                  this.state.mode === "gradingModeStudent" ? (
                  <div
                    className="bg-white w-100 mt-4 text-dark rounded row align-items-center p-3"
                    dangerouslySetInnerHTML={{ __html: this.state.stdanswer }}
                  ></div>
                ) : (
                  <div></div>
                )}
              {this.state.mode === "gradingModeTeacher" ||
              this.state.mode === "gradingModeStudent" ? (
                <div className="col-12 mt-5 row align-items-start align-content-start mx-auto">
                  <label className="align-self-start">Feedback</label>
                  <textarea
                    className="form-control align-self-stretch "
                    name=""
                    id=""
                    value={this.state.feedback}
                    rows={5}
                    disabled={
                      this.state.mode === "gradingModeStudent" ? true : false
                    }
                    onChange={(e) =>{
                      let feedback = e.target.value ; 
                      let updatedState = {...this.state , feedback}
                      this.onSaveQuestion(updatedState)
                      this.setState({ feedback: e.target.value })
                    }
                    }
                  ></textarea>
                </div>
              ) : null}
            </div>
            {this.state.showObjectives && this.state.mode === "editingMode" && (
              <div className="col-12 row my-3">
                <label>Learning objective</label>
                <textarea
                  className="col-12 form-control"
                  rows={10}
                  value={this.state.objectives}
                  onChange={(e) =>
                    this.setState({ objectives: e.target.value })
                  }
                  disabled={
                    this.state.mode === "gradingModeTeacher" ? true : false
                  }
                ></textarea>
              </div>
            )}
            {this.state.mode === "editingMode"  ? (
              <div className="col-12 my-3  d-flex justify-content-between">
                <button
                  className="btn btn-success"
                  onClick={() =>
                    this.setState({
                      showObjectives: !this.state.showObjectives,
                    })
                  }
                >
                  Learning objective
                </button>
              </div>
            ) : null}
           
           { this.state.mode == "editingMode" && <div className="text-center col-12">
              <button
                className="btn btn-warning btn-lg rounded col-3"
                onClick={()=>this.onSaveQuestion(this.state)}
             
              >
                {" "}
                Save
              </button>
            </div>}
          </div>
        </div>
      </div>
    );
    // }
  }
}
function mapStateToProps(state: ApplicationState) {
  const Store = { store: state };
  return Store;
}

export default withRouter<any,any>(
  connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    mapDispatchToProps // Selects which action creators are merged into the component's props
  )(Essay as any)
);
