import React from "react";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { actionCreators as Login } from '../store/Login';
import { ApplicationState } from "../../store";
import { actionCreators as AssessmentActionCreator } from "../../store/Assessment";

import $ from "jquery";
import "../../css/DashBoard.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { makeId } from "../../helpers/func";
// import { isNullOrUndefined } from '../../helpers/func';
import { OptionProps } from "./smallComponents/OrderOption";
import { handleCount, shuffle } from '../../helpers/validation';

import MatchOption from "./smallComponents/MatchOption";
import SweetAlert from "react-bootstrap-sweetalert";
import DifficultLevel from "./smallComponents/DifficultLevel";
const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  onSaveQuestionHandler: (data: any) =>
    dispatch(AssessmentActionCreator.saveQuestion(data)),
});


let obj = {
  id: makeId(10),
  showObjectives: false,
  shuffleOptions: false,
  objectives: "",
  feedback: "",
  type: "MatchQComponent",
  mode: "editingMode",
  totalMark: "",
  score: "",
  scoreMode: "partialMatch",
  header: "<p><span style=\"font-size: 14px;\"><strong> </strong></span></span></span></p>",
  required: true,
  options: [
    {
      id: "Gww36bw4fp",
      option: "<p></p>",


    },

    {
      id: "riCGB7TiWz",
      option: "<p></p>",

    },

  ],

  PossibleAnswers: [
    {
      id: "Gww36bw4fn",
      option: "<p></p>",

    },
    {
      id: "3wBfSgxAru",
      option: "<p></p>",

    },

  ],
  correctPossibleAnswers: [
    {
      id: "Gww36bw4fn",
      option: "<p></p>"
    },

    {
      id: "3wBfSgxAru",
      option: "<p></p>"
    }
  ],
  difficult:"Low",
  cognitive:"Understanding",
  alert: null
};

// editingMode
// examMode
// gradingModeTeacher
// gradingModeStudent

class Match extends Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = obj;
    if (this.props.quest != undefined) {

      this.state = this.props.quest;
    } else {
      this.state = obj;
    }
    this.onConfirm = this.onConfirm.bind(this)

  }

  onConfirm(e: any) {
    this.setState({ alert: null })
  }


  onSaveQuestion(updatedState: any) {



    let optionsRequired = false;
    let posAnsRequired = false;
    let require = handleCount(this.state.header);
    updatedState.options.forEach((o: any) => {
      if (optionsRequired === false) {
        if (handleCount(o.option) === true) {
          optionsRequired = true;
        }
      }
    });
    updatedState.PossibleAnswers.forEach((o: any) => {
      if (posAnsRequired === false) {
        if (handleCount(o.option) === true) {
          posAnsRequired = true;
        }
      }
    });

    let optionscount = updatedState.options.length;
    let possibleAnscount = updatedState.PossibleAnswers.length;


    if (require || optionsRequired || posAnsRequired) {
      this.setState({ alert: (<SweetAlert error title="error" onConfirm={this.onConfirm} >  Question header and quetions options  are required </SweetAlert>) })
    } else if (updatedState.totalMark.length == 0) {
      this.setState({ alert: (<SweetAlert error title="error" onConfirm={this.onConfirm} >    Total mark is required </SweetAlert>) })
    } else if (optionscount < 2 || possibleAnscount < 2) {
      this.setState({ alert: (<SweetAlert error title="error" onConfirm={this.onConfirm} >   Options mustn't be less than two options  </SweetAlert>) })
    } else if (optionscount != possibleAnscount) {
      this.setState({ alert: (<SweetAlert error title="error" onConfirm={this.onConfirm} >   Options must equal to Possible answers  </SweetAlert>) })

    }

    else {
      this.props.onSaveQuestion(updatedState)
    }
  }

  componentDidMount() {
    $("#preloader").hide();
    this.calculateScore()
    if (this.state.mode === "examMode" && this.state.shuffleOptions) {
      const shuffled = shuffle([...this.state.PossibleAnswers]);
      this.setState({ PossibleAnswers: shuffled, shuffleOptions: false });
    }
  }






  addOptionHandler = () => {
    let id = makeId(10);
    let newOp = [
      ...this.state.options.concat({
        id: id,
        option: "<p></p>",
        required: true
      }),
    ];


    let answerId = makeId(10);
    let newAnsweredOp = [
      ...this.state.PossibleAnswers.concat({
        id: answerId,
        option: "<p></p>",
        required: true
      }),
    ];
    let newPossibleAnsweredOp = [
      ...this.state.correctPossibleAnswers.concat({
        id: answerId,
        option: "<p></p>",
        required: true

      }),
    ];
    this.setState({ PossibleAnswers: newAnsweredOp, correctPossibleAnswers: newPossibleAnsweredOp, options: newOp });

  };


  onOptionUpdate = (val: OptionProps) => {

    let option = this.state.options.find((x: any) => x.id === val.id);
    let options = this.state.options;
    let PossibleAnswers = this.state.PossibleAnswers;
    let correctPossibleAnswers = this.state.correctPossibleAnswers;

    if (option) {
      let index = this.state.options.indexOf(option);
      options[index] = {
        id: val.id,
        option: val.option,
      };
    }
    let PossibleAnswer = this.state.PossibleAnswers.find((x: any) => x.id === val.id);
    if (PossibleAnswer) {
      let index = this.state.PossibleAnswers.indexOf(PossibleAnswer);
      PossibleAnswers[index] = {
        id: val.id,
        option: val.option,
      };

    }



    let correctPossibleAnswer = this.state.correctPossibleAnswers.find((x: any) => x.id === val.id);
    if (correctPossibleAnswer) {
      let index = this.state.correctPossibleAnswers.indexOf(correctPossibleAnswer);
      correctPossibleAnswers[index] = {
        id: val.id,
        option: val.option,

      };

    }
    this.setState({ options: options, correctPossibleAnswers: correctPossibleAnswers, PossibleAnswers: PossibleAnswers });
  };

  onDeleteOption = (id: any) => {
    let options = this.state.options;
    let opIndex = this.state.PossibleAnswers.findIndex((op: any) => op.id == id)

    options.splice(opIndex, 1);
    let newAnsweredOp = [...this.state.PossibleAnswers.filter((op: any) => op.id !== id)];
    let correctPossibleAnswers = [...this.state.correctPossibleAnswers.filter((op: any) => op.id !== id)];

    this.setState({ options: options, PossibleAnswers: newAnsweredOp, correctPossibleAnswers: correctPossibleAnswers });


  };


  toggleHandler = () => {
    if (this.state.mode === "editingMode") {
      this.setState({ mode: "answeringMode" });
    } else {
      this.setState({ mode: "editingMode" });
    }
  };


  onDragOps = (e: any, index: any) => {

    e.dataTransfer.setData("id", index);
  };
  onDropOps = (e: any, index: any) => {
    if (this.state.mode == "editingMode" || this.state.mode == "examMode") {

      var comingele = e.dataTransfer.getData("id");

      let data = [...this.state.options];

      if (comingele) {

        let temp = data[comingele];
        data[comingele] = data[index];
        data[index] = temp;
        this.setState({ options: data });
      }
    } else if (this.state.mode == "answeringMode") {
      var comingele = e.dataTransfer.getData("id");

      let data = [...this.state.options];
      if (comingele) {
        let temp = data[comingele];
        data[comingele] = data[index];
        data[index] = temp;
        this.setState({ options: data });
      }
    }
  };
  onDragPossibleAnswer = (e: any, index: any) => {

    e.dataTransfer.setData("id", index);
  };
  onDropPossibleAnswer = (e: any, index: any) => {
    if (this.state.mode == "editingMode" || this.state.mode == "examMode") {

      var comingele = e.dataTransfer.getData("id");

      let data = [...this.state.PossibleAnswers];

      let temp = data[comingele];
      data[comingele] = data[index];
      data[index] = temp;
      this.setState({ PossibleAnswers: data });
      if (this.state.mode == "examMode") {
        let PossibleAnswers = data
        let updatedState = { ...this.state, PossibleAnswers }

        this.onSaveQuestion(updatedState)
      }
    } else if (this.state.mode == "answeringMode") {
      var comingele = e.dataTransfer.getData("id");

      let data = [...this.state.correctPossibleAnswers];

      let temp = data[comingele];
      data[comingele] = data[index];
      data[index] = temp;
      this.setState({ correctPossibleAnswers: data });

    }
  };
  onDragOver = (e: any) => {

    e.preventDefault();
  };
  calculateScore() {
    let numberOfQuestions = this.state.PossibleAnswers.length;
    let optionScore = +this.state.totalMark / numberOfQuestions;
    let studentScore = 0;
    if (this.state.scoreMode == "partialMatch") {
      for (let index = 0; index < this.state.PossibleAnswers.length; index++) {
        if (this.state.PossibleAnswers[index].id == this.state.correctPossibleAnswers[index].id) {
          studentScore += optionScore;
        }
      }
    } else if (this.state.scoreMode == "exactMatch") {
      for (let index = 0; index < this.state.options.length; index++) {
        if (this.state.PossibleAnswers[index].id == this.state.correctPossibleAnswers[index].id) {
          studentScore += optionScore;
        }
      }
      if (studentScore < +this.state.totalMark) studentScore = 0;
    }
    this.setState({score : +studentScore.toFixed(2)})
   
  }

  render() {
 
    let opsEditingMode = this.state.PossibleAnswers.map((op: any, index: any) => {
      return (
        <div

          key={op.id}
          className="col-12 m-2 row align-items-baseline"

        >
          <div className="col-6">


            <MatchOption
              correctAnswer={false}

              style={{ backgroundColor: "white" }}
              key={this.state.options[index].id}
              option={this.state.options[index].option}
              id={this.state.options[index].id}
              index={index + 1}
              mode={this.state.mode}

              onOptionUpdate={this.onOptionUpdate}
              onDeleteOption={this.onDeleteOption}
              required={true}
              delete={false}

            />
          </div>

          <div

            key={op.id}
            draggable={true}
            className="col-6  row align-items-baseline"
            onDragOver={this.onDragOver}
            onDragStart={(e) => { this.onDragPossibleAnswer(e, index) }}
            onDrop={(e) => { this.onDropPossibleAnswer(e, index) }}
          >

            <MatchOption
              correctAnswer={false}

              style={{ backgroundColor: "white" }}
              key={op.id}
              option={op.option}
              id={op.id}
              index={index + 1}
              mode={this.state.mode}

              onOptionUpdate={this.onOptionUpdate}
              onDeleteOption={this.onDeleteOption}
              required={true}
              delete={true}
            />
          </div>

        </div>
      );
    });

    let opsAnsweringMode = this.state.correctPossibleAnswers.map((op: any, index: any) => {
      return (
        <div

          key={op.id}
          className="col-12 m-2 row align-items-baseline"

        >
          <div className="col-6">


            <MatchOption
              correctAnswer={false}

              style={{ backgroundColor: "white" }}
              key={this.state.options[index].id}
              option={this.state.options[index].option}
              id={this.state.options[index].id}
              index={index + 1}
              mode={this.state.mode}

              onOptionUpdate={this.onOptionUpdate}
              onDeleteOption={this.onDeleteOption}
              required={true}
              delete={false}

            />
          </div>

          <div

            key={op.id}
            draggable={true}
            className="col-6  row align-items-baseline"
            onDragOver={this.onDragOver}
            onDragStart={(e) => { this.onDragPossibleAnswer(e, index) }}
            onDrop={(e) => { this.onDropPossibleAnswer(e, index) }}
          >

            <MatchOption
              correctAnswer={false}

              style={{ backgroundColor: "white" }}
              key={op.id}
              option={op.option}
              id={op.id}
              index={index + 1}
              mode={this.state.mode}

              onOptionUpdate={this.onOptionUpdate}
              onDeleteOption={this.onDeleteOption}
              required={true}
              delete={false}
            />
          </div>

        </div>
      );
    });




    let opsGradingMode = this.state.PossibleAnswers.map((op: any, index: any) => {

      return (
        <div
          key={op.id}
          className="col-12 m-2 row align-items-baseline"

        >


          {op.option == this.state.correctPossibleAnswers[index].option ?
            (

              <React.Fragment>
                <div className="col-6">


                  <MatchOption
                    correctAnswer={false}

                    style={{ backgroundColor: "white" }}
                    key={this.state.options[index].id}
                    option={this.state.options[index].option}
                    id={this.state.options[index].id}
                    index={index + 1}
                    mode={this.state.mode}

                    onOptionUpdate={this.onOptionUpdate}
                    onDeleteOption={this.onDeleteOption}
                    required={true}
                    delete={false}

                  />
                </div>
                <div className="col-6">

                  <MatchOption
                    correctAnswer={false}

                    style={{ backgroundColor  : "#9df3b1 ",
                      border: "4px solid #098434 " ,  textColor: "white !important"
                     }}
                    key={op.id}
                    option={op.option}
                    id={op.id}
                    index={index + 1}
                    mode={this.state.mode}
                    onOptionUpdate={this.onOptionUpdate}
                    onDeleteOption={this.onDeleteOption}
                    required={true}
                    delete={false}
                  />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="col-6">


                  <MatchOption
                    correctAnswer={false}

                    style={{ backgroundColor: "white" }}
                    key={this.state.options[index].id}
                    option={this.state.options[index].option}
                    id={this.state.options[index].id}
                    index={index + 1}
                    mode={this.state.mode}

                    onOptionUpdate={this.onOptionUpdate}
                    onDeleteOption={this.onDeleteOption}
                    required={true}
                    delete={false}

                  />
                </div>

                <div className="col">
                  <MatchOption
                    correctAnswer={false}

                    style={{ backgroundColor: "#ffe1e2", textColor: "white" }}
                    key={op.id}
                    option={op.option}
                    id={op.id}
                    index={index + 1}
                    mode={this.state.mode}
                    onOptionUpdate={this.onOptionUpdate}
                    onDeleteOption={this.onDeleteOption}
                    required={true}
                    delete={false}
                  />
                </div>
                <div className="col-auto ">Correct answer : <i className="fas fa-arrow-right"></i></div>
                <div className="col">


                  <MatchOption
                    correctAnswer={true}
                    style={{backgroundColor  : "#9df3b1 ",
                    border: "4px solid #098434 ", textColor: "white" }}
                    key={this.state.correctPossibleAnswers[index].id}
                    option={this.state.correctPossibleAnswers[index].option}
                    id={this.state.correctPossibleAnswers[index].id}
                    index={index + 1}
                    mode={this.state.mode}
                    onOptionUpdate={this.onOptionUpdate}
                    onDeleteOption={this.onDeleteOption}
                    required={true}
                    delete={false}

                  />
                </div>


              </React.Fragment>

            )
          }

        </div>
      );
    });
    // console.log(this.options, ops);
    // if (this.mode == "insertion") {

    // console.log(this.state);
    return (
      <div className="mx-5">
        {this.state.alert}

        <div className="row rounded my-5 p-4 shadow ">
          <div className="col-12 row align-items-center mx-auto  bg-question rounded p-3 text-white">
            <div className="col-12 row justify-content-between">
              {this.state.mode == "editingMode" ? (<h3>Question Setup</h3>) : null}
              {this.state.mode == "answeringMode" ? (<h3>Correct answer setup</h3>) : null}
              <div className="d-flex flex-column">
                {this.state.mode === "editingMode" ||
                  this.state.mode === "answeringMode" ? (
                  <button
                    className="btn btn-warning text-white "
                    onClick={this.toggleHandler}
                  >
                    {this.state.mode == "editingMode" ? "Go to Answer mode" : "Go to Question setup mode"}
                  </button>
                ) : null}
                {this.state.mode == "examMode" && (<div> Q {this.props.index} <br></br> </div>)}
                <div className="form-group d-flex align-items-center my-3">
                  {this.state.mode === "editingMode" ||

                    this.state.mode === "answeringMode" ? (
                    <React.Fragment>

                      <span className="col-auto">Total mark : &nbsp;</span>
                      <input
                        type="number"
                        min={0}
                        className="col form-control"
                        name=""
                        id=""
                        value={this.state.totalMark}
                        onChange={(e) =>
                          this.setState({ totalMark: e.target.value })
                        }
                      />
                    </React.Fragment>
                  ) :

                    this.state.mode === "gradingModeTeacher" ||
                      this.state.mode === "gradingModeStudent" ? (
                      <React.Fragment>

                        <span className="col-auto">Score  : &nbsp;</span>

                        <span>{this.state.score + "/" + this.state.totalMark}</span>
                      </React.Fragment>
                    ) : this.state.mode === "examMode" ? (<React.Fragment>

                      <span className="col-auto">Total mark   : &nbsp;</span>

                      <span>{this.state.totalMark}</span>
                    </React.Fragment>) : null}
                </div>
              </div>
            </div>
            <div className="col-12 mb-5 row justify-content-center align-items-center">
              {this.state.mode === "editingMode" ? (
                <div className="col-12 w-100 row justify-content-center align-items-center">
                  <RichBox
                    onChange={(v: any) => { this.setState({ header: v }); }}

                    defaultValue={this.state.header}
                    height={"10px"}
                  ></RichBox>
                </div>
              ) : this.state.mode === "examMode" ||
                this.state.mode === "answeringMode" ||
                this.state.mode === "gradingModeTeacher" ||
                this.state.mode === "gradingModeStudent" ? (
                <div

                  className="bg-white w-100 text-dark rounded row align-items-center p-3"
                  dangerouslySetInnerHTML={{ __html: this.state.header }}
                ></div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="col-12 row">


              <h5 className="col-6">Options :</h5>
              <h5 className="col-6">Possible Answers : {this.state.mode != "editingMode" ? "You should drag  option and drop it in another option to swap between them" : "" }</h5>

              {this.state.mode === "editingMode" || this.state.mode === "examMode" ? opsEditingMode : this.state.mode === "answeringMode" ? opsAnsweringMode :
                this.state.mode === "gradingModeTeacher" || this.state.mode === "gradingModeStudent"  ? opsGradingMode : null
              }
              {/* this.state.mode === "answeringMode" ?opsAnsweringMode: this.state.mode === "examMode" ? opsExamMode : opsGradingMode */}

              {/* <h5 className="col-9"> Possible Answers :</h5>


                {this.state.mode === "answeringMode" ? correctPossibleAnswers : null}
                {this.state.mode === "editingMode" ? PossibleAnswers : null}
                {this.state.mode === "examMode" ? PossibleAnswers : null}
                {this.state.mode === "gradingModeTeacher" ? gradingOptions : null} */}

            </div>



            {this.state.mode === "editingMode" ? (
              <div className="col-12 my-3  d-flex justify-content-between">
                <button
                  className="btn btn-success "
                  onClick={this.addOptionHandler}
                >
                  + Options
                </button>
                {/* <button
                  className="btn btn-success "
                  onClick={this.addPossibleAnswerHandler}
                >
                  + Possible Answers
                </button> */}

              </div>
            ) : null}
            <hr className="col-10" style={{ color: "white", backgroundColor: "white" }} />

            {this.state.mode === "editingMode" ? (
              <React.Fragment>

                <div className="col-12 row my-3 ml-5 ">
                  <input
                    type="checkbox"
                    name=""
                    className="mr-2"
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    id="ShuffleOptions"
                    checked={this.state.shuffleOptions}
                    onChange={(e) =>
                      this.setState({ shuffleOptions: e.target.checked })
                    }
                  />
                  <label htmlFor="ShuffleOptions" style={{ cursor: "pointer" }}>
                    Shuffle Options
                  </label>
                </div>
                <div className="col-12"> Scoring Mode :</div>
                <div className="col-12 row my-3 ml-5">
                  <input
                    type="radio"
                    name="match"
                    className="mr-2"
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    id="exactMatch"
                    value="exactMatch"
                    checked={this.state.scoreMode == "exactMatch" ? true : false}
                    onChange={(e) =>
                      this.setState({ scoreMode: e.target.value })
                    }
                  />
                  <label htmlFor="exactMatch" style={{ cursor: "pointer" }} className="mr-5">
                    Exact match
                  </label>
                  <input
                    type="radio"
                    name="match"
                    value="partialMatch"
                    checked={this.state.scoreMode == "partialMatch" ? true : false}

                    className="mr-2"
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    id="partialMatch"
                    onChange={(e) =>
                      this.setState({ scoreMode: e.target.value })
                    }
                  />
                  <label htmlFor="partialMatch" style={{ cursor: "pointer" }}>
                    Partial match
                  </label>
                </div>
              </React.Fragment>
            ) : null}
{this.state.mode === "editingMode"  ? (
              <DifficultLevel 
               difficult = {this.state.difficult} 
                cognitive= {this.state.cognitive} 
                onChangeDifficult = {(difficult :any) => { this.setState({difficult :difficult}) }}
                onChangeCognitive = {(cognitive :any) => { this.setState({cognitive :cognitive}) }}
              ></DifficultLevel>

            ) : null}

            {this.state.mode === "editingMode" ? (
              <div className="col-12 my-3  d-flex justify-content-between">

                <button
                  className="btn btn-success"
                  onClick={() =>
                    this.setState({
                      showObjectives: !this.state.showObjectives,
                    })
                  }
                >
                  Learning objective
                </button>
              </div>
            ) : null}


            {this.state.showObjectives && this.state.mode === "editingMode" && (
              <div className="col-12 row my-3">
                <label>Learning objective</label>
                <textarea
                  className="col-12 form-control"
                  rows={10}
                  value={this.state.objectives}
                  onChange={(e) =>
                    this.setState({ objectives: e.target.value })
                  }
                ></textarea>
              </div>
            )}
              {this.state.mode === "gradingModeTeacher" ||
                this.state.mode === "gradingModeStudent" ? (
                <div className="col-12 ml-4 row align-items-start align-content-start p-2">
                  <label className="align-self-start">Feedback</label>
                  <textarea
                    className="form-control align-self-stretch "
                    name=""
                    id=""
                    value={this.state.feedback}
                    rows={5}
                    disabled={
                      this.state.mode === "gradingModeStudent" ? true : false
                    }
                    onChange={(e) =>
                     { let feedback = e.target.value ; 
                      let updatedState = {...this.state , feedback}
                    this.onSaveQuestion(updatedState)
                    this.setState({ feedback: e.target.value })}
                    }
                  ></textarea>
                </div>
              ) : null}

            {this.state.mode == "editingMode" && <div className="text-center col-12">
              <button
                className="btn btn-warning btn-lg rounded col-3"
                onClick={() => this.onSaveQuestion(this.state)}

              >
                {" "}
                Save
              </button>
            </div>}
          </div>
        </div>
      </div>
    );
    // }
  }
}
function mapStateToProps(state: ApplicationState) {
  const Store = { store: state };
  return Store;
}

export default withRouter<any, any>(
  connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    mapDispatchToProps // Selects which action creators are merged into the component's props
  )(Match as any)
);
