import React from 'react';
import { Button} from 'reactstrap';
import {Input, Form} from './MyComponents/myForms';
import Msg from './MsgComponent';
import * as Initial from '../store/initialStatus';
import {withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { actionCreators} from '../store/Login';
import { ApplicationState } from '../store';
import { actionCreators as UserActionCreator } from '../store/User';
import  '../css/login.css';
import {Translate} from '../helpers/Translate';
import $ from 'jquery';
import { isNullOrUndefined } from 'util';
const required = (val: string) => val && val.length;
const maxLength = (len: number) => (val:string) => !(val) || (val.length <= len);
const minLength = (len: number) => (val:string) => val && (val.length >= len);


const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
    Login: (username: any, password: any,remember:boolean) => dispatch(actionCreators.RequestLogin(username, password,remember)),
    GetUser: () => dispatch(UserActionCreator.GET_USER()),
});

class Login extends React.Component<any>{
    /**
     *
     */
    t=new Translate();
    constructor(props:any) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        //  
        this.state = Initial.LoginState;
        let b = $("body");
        b.css("background-color", "#03589B");
        if (isNullOrUndefined(this.props.user) || this.props.user.Sid === "") {
            this.props.GetUser();
        }
    }
    //  
    componentWillUnmount() {
        let b = $("body");
        b.css("background-color", "white");
    }
    componentDidMount(){
        $("#preloader").hide();
      }
    handleLogin(vals:any){
        //  
        this.props.Login(vals.username, vals.password, vals.remember);
        //event.preventDefault();
    }
    render() {
        if(this.props.login.loggedin || (!isNullOrUndefined(this.props.user)&&(this.props.user.UserId !==0))){
            return(
                <Msg msg={"Welcome "+this.props.user.Name} url="/dashboard" />
            );
        }
        else
        return(
            <div id="loginContainer" className="container">
                <div className="row text-center align-content-center justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8 border border-dark rounded mt-5 p-5 color2">
                        <img src="assets/images/logo.png" alt="Logo" width="150" height="150" className="m-3"/>
                        <Form onSubmit={this.handleLogin}>                           
        <h4 className="m-2">{this.t.translate("Please sign in")}</h4>
                            {this.props.login.LoginFail===true?<label className="text-danger">Wrong Username Or Password</label>:null}
                            <h4 className="text-danger">{this.props.login.msg}</h4>
                            <Input type="text" name="username" placeholder = {this.t.translate("Username")} label= {this.t.translate("Username:")}
                                className="form-control"
                                validators={{
                                    required, minLength: minLength(3), maxLength: maxLength(15)
                                }}
                                messages={{
                                    required: 'Required',
                                    minLength: 'Must be greater than 2 characters',
                                    maxLength: 'Must be 15 characters or less'
                                }}
                            />
                            <Input type="password" name="password" placeholder={this.t.translate("Password")} label={this.t.translate("Password:")}
                                className="form-control"
                                validators={{
                                    required, minLength: minLength(3), maxLength: maxLength(30)
                                }}
                                messages={{
                                    required: 'Required',
                                    minLength: 'Must be greater than 2 characters',
                                    maxLength: 'Must be 15 characters or less'
                                }}
                            />
                            <Input type="checkbox" name="remember" label="Remember Me ?"
                                className="form-check-input"
                            />
                            <div className=" row form-group">
                                <div className=" col-xs-offset-0 col-xs-12 offset-sm-2 col-sm-10 ">
                                <Button type="submit" color="primary"className="btn btn-primary btn-lg btn-block" >
                            {this.t.translate("Sign in")}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                        
                        <p className="mt-5 mb-3 text-muted">© Copyright 2020 IVY International Schools <br /> Twenty-First century Education</p>
    
                    </div>
                    <div className={this.props.login.isLoading?"overlay show":"overlay"}></div>
                    <div className={this.props.login.isLoading?"spanner show":"spanner"}>
                        <div className="loader"></div>
                        <p>Loading...</p>
                    </div>                
                </div>
                
            </div>
    
        );
    }

}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps // Selects which action creators are merged into the component's props
)(Login as any));