import {  Reducer } from "redux";
import { AppThunkAction } from ".";
import {  requestWithAxios } from "../helpers/func";
import {  ADD_ASSESSMENT, SAVE_QUSTION, GET_EXAMS, GET_EXAM, LOADING, RESET_GOTDATA, RESET_ASSESSMENT, GET_GRADES, GET_SUBJECTS, ADD_SUBJECT, VIEW_EXAM, STUDENT_SUBMIT_EXAM, DELETE_QUESTION, GET_GROUPS, GET_TEACHERS, GET_STUDENTS_EXAMS, GRADING_EXAM, GET_STUDENT_EXAM, STUDENT_ASSESSMENT_LOADING, PUBLISH_ASSESSMENT } from "./ActionTypes";
import * as Initial from "./initialStatus";

import { url } from './initialStatus'
/**
 * status
 *  204 => ok same data in server
 *  401 => Error unauthorized
 *  403 => Error Forbidden not loggedin
 *  500 => Error server error
 *  -1 => Error
 */

interface AddAssessment {
  type: string;
  msg: string;
  Questions: [] | undefined;
}

type KnownAction = AddAssessment;

export const actionCreators = {
  AddAssessment:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: ADD_ASSESSMENT, value: true , MSG: "" })
       
        const res = await requestWithAxios(url + "admin/addexam.php", data);
        dispatch({ type: ADD_ASSESSMENT, value: false, MSG: res.data.msg })
      },
  StudentSubmiteExam:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: STUDENT_SUBMIT_EXAM, value: true , MSG: "" })
       
        const res = await requestWithAxios(url + "student/submitexam.php", data);
        dispatch({ type: STUDENT_SUBMIT_EXAM, value: false, MSG: res.data.msg })
      },
  AddSubject:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: ADD_SUBJECT, value: true })
        const res = await requestWithAxios(url + "admin/addsubject.php", data);
        dispatch({ type: ADD_SUBJECT, value: false, MSG: res.data.msg })
      },
  UpdateAssessment:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: ADD_ASSESSMENT, value: true })
        const res = await requestWithAxios(url + "admin/updateexam.php", data);
        dispatch({ type: ADD_ASSESSMENT, value: false, MSG: res.data.msg })


      },
  GetAssessments:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {

        const res = await requestWithAxios(url + "admin/getexams.php", data);
        
        dispatch({ type: GET_EXAMS, res: res.data.data })

      },
  GetStudentsExams:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {

        const res = await requestWithAxios(url + "admin/getstudentsexams.php", data);
        dispatch({ type: GET_STUDENTS_EXAMS, res: res.data.data.exams , count :res.data.data.count })

      },
  GetGrades:
    (): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        const res = await requestWithAxios(url + "admin/getgrades.php", "");
        dispatch({ type: GET_GRADES, res: res.data.data })
      },
  GetGroups:
    (): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        const res = await requestWithAxios(url + "admin/getgroups.php", "");
        dispatch({ type: GET_GROUPS, res: res.data.data })
      },
  GetSubjects:
    (): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        const res = await requestWithAxios(url + "admin/getsubjects.php", "");
        dispatch({ type: GET_SUBJECTS, res: res.data.data })
      },
  GetTeachers:
    (): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        const res = await requestWithAxios(url + "admin/getteachers.php", "");
        dispatch({ type: GET_TEACHERS, res: res.data.data })
      },
  DeleteQuestion:
    (id: any): AppThunkAction<any | any> =>
      (dispatch, getState) => {
        dispatch({ type: "DELETE_QUESTION", id: id })

    
      },
  GetAssessment:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {

        dispatch({ type: LOADING, value: true })
        const res = await requestWithAxios(url + "admin/getexam.php", { id: id });

        dispatch({ type: GET_EXAM, res: res.data.data })
        dispatch({ type: LOADING, value: false })

      },
  ViewAssessment:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {

        dispatch({ type: LOADING, value: true })
        const res = await requestWithAxios(url + "admin/getexam.php", { id: id });

        dispatch({ type: VIEW_EXAM, res: res.data.data })
        dispatch({ type: LOADING, value: false })

      },
  GradingAssessment:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {

        dispatch({ type: LOADING, value: true })
        const res = await requestWithAxios(url + "admin/getstudentsol.php", { id: id });
         
        dispatch({ type: GRADING_EXAM, res: res.data.data })
        dispatch({ type: LOADING, value: false })

      },
  PublishAssessment:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {

        dispatch({ type: LOADING, value: true })
        const res = await requestWithAxios(url + "admin/publishassessment.php", { id: id });
      
        dispatch({ type: GET_EXAMS, res: res.data.data })
        dispatch({ type: LOADING, value: false })

      },
  PullAssessment:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {

        dispatch({ type: LOADING, value: true })
        const res = await requestWithAxios(url + "admin/pullassessment.php", { id: id });
      
        dispatch({ type: GET_EXAMS, res: res.data.data })
        dispatch({ type: LOADING, value: false })

      },
  GetStudentAssessment:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {

        dispatch({ type: STUDENT_ASSESSMENT_LOADING, value: true })
        const res = await requestWithAxios(url + "admin/getstudentexam.php", { id: id });
         
        dispatch({ type: GET_STUDENT_EXAM, res: res.data.data })
        dispatch({ type: STUDENT_ASSESSMENT_LOADING, value: false })

      },

      UpdateGradingAssessment:
      (data: any): AppThunkAction<any | any> =>
        async (dispatch, getState) => {
          dispatch({ type: ADD_ASSESSMENT, value: true })
          const res = await requestWithAxios(url + "admin/gradeexam.php", data);
          dispatch({ type: ADD_ASSESSMENT, value: false, MSG: res.data.msg })
  
  
        },
  saveQuestion:
    (question: any): AppThunkAction<any | any> =>
      (dispatch, getState) => {
        dispatch({ type: SAVE_QUSTION, question: question });

      },
  resetGotData:
    (): AppThunkAction<any | any> =>
      (dispatch, getState) => {
        dispatch({ type: RESET_GOTDATA });
      },
  resetAssessment:
    (): AppThunkAction<any | any> =>
      (dispatch, getState) => {
        dispatch({ type: RESET_ASSESSMENT });
      },
};

export const reducer: Reducer<any> = (
  state: any | undefined,
  incomingAction: any
): any => {
  if (state === undefined) {
    return Initial.Assessment;
  }

  const action = incomingAction;
  switch (action.type) {
    case SAVE_QUSTION:
      let q = state.questions.find((q: any) => q.id == action.question.id)
      if (q) {
        let questions = [...state.questions];
        let index = questions.indexOf(q);
        questions[index] = action.question;

        return {
          ...state,
          questions: questions

        };

      } else {
        return {
          ...state,
          questions: state.questions.concat(action.question),

        };
      }

    case DELETE_QUESTION:
      return {
        ...state,
        questions: state.questions.filter((q: any) => q.id != action.id),
      };
    case GET_EXAMS:
      return {
        ...state,
        assessments: action.res,
      };
    case GET_STUDENTS_EXAMS:
      return {
        ...state,
        studentsExams: action.res,
        studentExamCount: action.count,
      };
    case GET_GRADES:
      return {
        ...state,
        grades: action.res,
      };
    case GET_SUBJECTS:
      return {
        ...state,
        subjects: action.res,
      };
    case GET_TEACHERS:
      return {
        ...state,
        teachers: action.res,
      };
    case GET_GROUPS:
      return {
        ...state,
        groups: action.res,
      };
    case GET_EXAM:
      return {
        ...state,
        assessment: action.res,
        questions: action.res.questions,
        gotdata: true
      };
    case VIEW_EXAM:
      return {
        ...state,
        assessment: action.res,
        questions: action.res.questions,
        viewdata: true
      };
    case GRADING_EXAM:
      return {
        ...state,
        assessment: action.res,
        questions: action.res.answerdquestions,
        viewdata: true
      };
    case GET_STUDENT_EXAM:
      return {
        ...state,
        assessment: action.res,
        questions: action.res.questions,
     
      };
    case LOADING:
      return {
        ...state,
        loading: action.value,
        gotdata: false
      };
    case STUDENT_ASSESSMENT_LOADING:
      return {
        ...state,
        studentAssessmentLoading: action.value,
      
      };
    case RESET_GOTDATA:
      return {
        ...state,

        gotdata: false,
        viewdata: false,
        addAssessmentloading: false,
        submitAssessmentMsg:"",
        assessmentMsg: "",
       
      };
    case RESET_ASSESSMENT:
      return {
        ...state,

        assessment: null,
        addAssessmentloading: false,
        addSubjectloading: false,
        assessmentMsg: "",
        subjectMsg: "",
        questions: []
      };
    case ADD_ASSESSMENT:
      return {
        ...state,
        addAssessmentloading :action.value,
        assessmentMsg: action.MSG
      };
    case STUDENT_SUBMIT_EXAM:
      return {
        ...state,
        submitAssessmentloading :action.value,
        submitAssessmentMsg: action.MSG
      };
    case ADD_SUBJECT:
      return {
        ...state,
        subjectMsg: action.MSG
      };
  }

  return state;
};
