export class Translate {

  static  lang = "EN"


    actions = [
        { word: "View Students", EN: "View Students", AR: "عرض  بيانات الطلاب" },
        { word: "Update Students", EN: "Update Students", AR: "تحديث بيانات الطلاب" },
        { word: "Add Students", EN: "Add Student", AR: "اضافة طالب" },
        { word: "Delete Students", EN: "Delete Student", AR: "حذف طالب" },
        { word: "View Minorgrades", EN: "View Minor grades", AR: "" },
        { word: "Update Minorgrades", EN: "Update Minor grades", AR: "" },
        { word: "Add Minorgrades", EN: "Add Minor grades", AR: "" },
        { word: "Delete Minorgrades", EN: "Delete Minor grades", AR: "" },
        { word: "View Majorgrades", EN: "View Major grades", AR: "" },
        { word: "Update Majorgrades", EN: "Update Major grades", AR: "" },
        { word: "Add Majorgrades", EN: "Add Major grades", AR: "" },
        { word: "Delete Majorgrades", EN: "Delete Major grades", AR: "" },
        { word: "View AcademicactivitiesLevel", EN: "View Academic activities Level", AR: "عرض مستوى الانشطة التعليمية" },
        { word: "Update AcademicactivitiesLevel", EN: "Update Academic activities Level", AR: " تحديث مستوى الانشطة التعليمية" },
        { word: "Add AcademicactivitiesLevel", EN: "Add Academic activities Level", AR: "اضافة  مستوى الانشطة التعليمية " },
        { word: "Delete AcademicactivitiesLevel", EN: "Delete Academic activities Level", AR: "حذف مستوى الانشطة التعليمية" },
        { word: "View Academicactivity", EN: "View Academic activity", AR: "عرض النشاط التعليمى" },
        { word: "Update Academicactivity", EN: "Update Academic activity", AR: "تحديث النشاط الاكاديمى" },
        { word: "Add Academicactivity", EN: "Add Academic activity", AR: "اضافة نشاط تعليمى" },
        { word: "Delete Academicactivity", EN: "Delete Academic activity", AR: "حذف نشاط تعليمى " },
        { word: "View Course", EN: "View Course", AR: "عرض دورة تعليمية" },
        { word: "Update Course", EN: "Update Course", AR: "تحديث  دورة تعليمية" },
        { word: "Add Course", EN: "Add Course", AR: "اضافة دورة تعليمية" },
        { word: "Delete Course", EN: "Delete Course", AR: "حذف دورة تعليمية" },
        { word: "View Courseattendance", EN: "View Course attendance", AR: " عرض حضور الدورة " },
        { word: "Update Courseattendance", EN: "Update Course attendance", AR: "تحديث حضور الدورة" },
        { word: "Add Courseattendance", EN: "Add Course attendance", AR: "اضافة حضور الدورة" },
        { word: "Delete Courseattendance", EN: "Delete Course attendance", AR: "حذف حضور الدورة" },
        { word: "View Dayattendance", EN: "View Day attendance", AR: "عرض الحضور اليوميى" },
        { word: "Update Dayattendance", EN: "Update Day attendance", AR: "تحديث الحضور اليومى" },
        { word: "Add Dayattendance", EN: "Add Day attendance", AR: "اضافة حضور يومى" },
        { word: "Delete Dayattendance", EN: "Delete Day attendance", AR: "حذفف الحضور اليومى " },
        { word: "View Exam", EN: "View Exam", AR: "عرض الامتحان" },
        { word: "Update Exam", EN: "Update Exam", AR: "تحديث الامتحان" },
        { word: "Add Exam", EN: "Add Exam", AR: "اضافة امتحان" },
        { word: "Delete Exam", EN: "Delete Exam", AR: "حذف امتحان" },
        { word: "View Group", EN: "View Group", AR: "عرض مجموعة" },
        { word: "Update Group", EN: "Update Group", AR: "تحديث مجموعة" },
        { word: "Add Group", EN: "Add Group", AR: "اضافة مجموعة" },
        { word: "Delete Group", EN: "Delete Group", AR: "حذف مجموعة" },
        { word: "View GroupsCourse", EN: "View Groups Course", AR: "" },
        { word: "Update GroupsCourse", EN: "Update Groups Course", AR: "" },
        { word: "Add GroupsCourse", EN: "Add Groups Course", AR: "" },
        { word: "Delete GroupsCourse", EN: "Delete Groups Course", AR: "" },


        { word: "View NonacademicactivitiesLevel", EN: "View Nonacademic activities Level", AR: "عرض مستوى النشاطات الغير تعليمية" },
        { word: "Update NonacademicactivitiesLevel", EN: "Update Nonacademic activities Level", AR: "تحديث مستوى النشاطات الغير تعليمية" },
        { word: "Add NonacademicactivitiesLevel", EN: "Add Nonacademic activities Level", AR: "اضافة مستوى النشاطات الغير تعليمية" },
        { word: "Delete NonacademicactivitiesLevel", EN: "Delete Nonacademic activities Level", AR: "حذف مستوى النشاطات الغير تعليمية" },
        { word: "View Nonacademicactivity", EN: "View Nonacademic activity", AR: "عرض نشاط غير تعليمى" },
        { word: "Update Nonacademicactivity", EN: "Update Nonacademic activity", AR: "تحديث نشاط غير تعليمى" },
        { word: "Add Nonacademicactivity", EN: "Add Nonacademic activity", AR: "اضافة نشاط غير تعليمى" },
        { word: "Delete Nonacademicactivity", EN: "Delete Nonacademic activity", AR: "حذف نشاط غير تعليمى" },
        { word: "View StudentAcademicactivitiesLevel", EN: "View Student Academic activities Level", AR: "عرض مستوى النشاط التعليمى للطالب" },
        { word: "Update StudentAcademicactivitiesLevel", EN: "Update Student Academic activities Level", AR: "تحديث مستوى النشاط التعليمى للطالب" },
        { word: "Add StudentAcademicactivitiesLevel", EN: "Add Student Academic activities Level", AR: "اضافة مستوى النشاط التعليمى للطالب" },
        { word: "Delete StudentAcademicactivitiesLevel", EN: "Delete Student Academic activities Level", AR: "حذف مستوى النشاط التعليمى للطالب" },
        { word: "View StudentNonacademicactivitiesLevel", EN: "View Student Nonacademic activities Level", AR: "عرض مستوى النشاط الغير التعليمى للطالب" },
        { word: "Update StudentNonacademicactivitiesLevel", EN: "Update Student Nonacademic activities Level", AR: "تحديث مستوى النشاط الغير التعليمى للطالب" },
        { word: "Add StudentNonacademicactivitiesLevel", EN: "Add Student Nonacademic activities Level", AR: "اضافة مستوى النشاط الغير التعليمى للطالب" },
        { word: "Delete StudentNonacademicactivitiesLevel", EN: "Delete Student Nonacademic activities Level", AR: "حذف مستوى النشاط الغير التعليمى للطالب" },

        { word: "View StudentRelative1", EN: "View Student Relative1", AR: "" },
        { word: "Update StudentRelative1", EN: "Update Student Relative1", AR: "" },
        { word: "Add StudentRelative1", EN: "Add Student Relative1", AR: "" },
        { word: "Delete StudentRelative1", EN: "Delete Student Relative1", AR: "" },

        { word: "View StudentRelatives", EN: "View Student's Relatives", AR: "عرض بيانات الاقارب" },
        { word: "Update StudentRelatives", EN: "Update Student's Relatives", AR: "تحديث بيانات الاقارب" },
        { word: "Add StudentRelatives", EN: "Add Student's Relatives", AR: "اضافة بيانات الاقارب" },
        { word: "Delete StudentRelatives", EN: "Delete Student's Relatives", AR: "حذف بيانات الاقارب" },

        { word: "View StudentsCoursesSubscription", EN: "View Students Courses Subscription", AR: "" },
        { word: "Update StudentsCoursesSubscription", EN: "Update Students Courses Subscription", AR: "" },
        { word: "Add StudentsCoursesSubscription", EN: "Add Students Courses Subscription", AR: "" },
        { word: "Delete StudentsCoursesSubscription", EN: "Delete Students Courses Subscription", AR: "" },
        { word: "View StudentsExam", EN: "View Students Exam", AR: "عرض امتحان الطلاب" },
        { word: "Update StudentsExam", EN: "Update Students Exam", AR: "تحديث امتحان الطلاب" },
        { word: "Add StudentsExam", EN: "Add Students Exam", AR: "اضافة امتحان للطلاب " },
        { word: "Delete StudentsExam", EN: "Delete Students Exam", AR: "حذف امتحان للطلاب" },
        { word: "View StudentsGroup", EN: "View Students Group", AR: "" },
        { word: "Update StudentsGroup", EN: "Update Students Group", AR: "" },
        { word: "Add StudentsGroup", EN: "Add Students Group", AR: "" },
        { word: "Delete StudentsGroup", EN: "Delete Students Group", AR: "" },
        { word: "View StudentsMajorgrade", EN: "View Students Major grade", AR: "" },
        { word: "Update StudentsMajorgrade", EN: "Update Students Major grade", AR: "" },
        { word: "Add StudentsMajorgrade", EN: "Add Students Major grade", AR: "" },
        { word: "Delete StudentsMajorgrade", EN: "Delete Students Major grade", AR: "" },
        { word: "View StudentsMinorgrade", EN: "View Students Minor grade", AR: "" },
        { word: "Update StudentsMinorgrade", EN: "Update Students Minor grade", AR: "" },
        { word: "Add StudentsMinorgrade", EN: "Add Students Minor grade", AR: "" },
        { word: "Delete StudentsMinorgrade", EN: "Delete Students Minor grade", AR: "" },
        { word: "View Users", EN: "View Users", AR: "عرض المستخدمين" },
        { word: "Add User", EN: "Add User", AR: "اضافة مستخدم" },
        { word: "Edit USER", EN: "Edit USER", AR: "تعديل مستخدم" },
        { word: "Delete User", EN: "Delete User", AR: "حذف مستخدم" },
        { word: "getRoles", EN: "get Roles", AR: "" },
        { word: "addRoles", EN: "add Roles", AR: "" },
        { word: "PutRole", EN: "Put Role", AR: "" },
        { word: "DeleteRoles", EN: "Delete Roles", AR: "" }]
    pages = [
        { word: "Students", EN: "Students", AR: "الطلاب" },
        { word: "Minorgrades", EN: "Minor grades", AR: "" },
        { word: "Majorgrades", EN: "Major grades", AR: "" },
        { word: "AcademicactivitiesLevel", EN: "Academic activities Level", AR: "مستوى النشاطات التعليمية" },
        { word: "Academicactivity", EN: "Academic activity", AR: "النشاط التعليمى" },
        { word: "Course", EN: "Course", AR: "دورة تعليمية" },
        { word: "Courseattendance", EN: "Course attendance", AR: "حضور الدورةالتعليمية" },
        { word: "Dayattendance", EN: "Day attendance", AR: "الحضور اليومى" },
        { word: "Exam", EN: "Exam", AR: "امتحان" },
        { word: "Group", EN: "Group", AR: "مجموعة" },
        { word: "GroupsCourse", EN: "Groups Course", AR: "" },
        { word: "NonacademicactivitiesLevel", EN: "Nonacademic activities Level", AR: "مستوى الانشطةالغير تعليمية" },
        { word: "Nonacademicactivity", EN: "Nonacademic activity", AR: "" },
        { word: "StudentAcademicactivitiesLevel", EN: "Student Academic activities Level", AR: "" },
        { word: "StudentNonacademicactivitiesLevel", EN: "Student Nonacademic activities Level", AR: "" },
        { word: "StudentRelative1", EN: "Student Relative1", AR: "" },
        { word: "StudentRelatives", EN: "Student Relatives", AR: "" },
        { word: "StudentsCoursesSubscription", EN: "Students Courses Subscription", AR: "" },
        { word: "StudentsExam", EN: "Students Exam", AR: "" },
        { word: "StudentsGroup", EN: "Students Group", AR: "" },
        { word: "StudentsMajorgrade", EN: "Students Majorgrade", AR: "" },
        { word: "StudentsMinorgrade", EN: "Students Minorgrade", AR: "" },
        { word: "Users", EN: "Users", AR: "المستخدمين" },
        { word: "Roles", EN: "Roles", AR: "" },
        { word: "Menu", EN: "Menu", AR: "القائمة" },
        { word: "Options", EN: "Options", AR: "الاختيارات" },
        { word: "View Log", EN: "View Log", AR: "سجل عرض" },
        { word: "actions", EN: "actions", AR: "" }
    ]

    menuItems = [
        { word: "Configuration", EN: "Configuration", AR: "الاعدادات" },
        { word: "Security", EN: "Security", AR: "الحماية" },
        { word: "Administration", EN: "Administration", AR: "الادارة" },
        { word: "students Affairs", EN: "Students Affairs", AR: "شئون الطلبة" },
        { word: "System Configuration", EN: "System Configuration", AR: " اعدادات النظام" },
        { word: "Actions", EN: "Actions", AR: " العمليات" },
    ]
    
    
    
    websitewords =[
        { word: "Home", EN: "Home", AR: "الصفحة الرئيسية" },
        { word: "MenuName", EN: "MenuName", AR: " اسم القائمة" },
        { word: "Add", EN: "Add", AR: "اضافة" },
        { word: "Edit", EN: "Edit", AR: "تعديل" },
        { word: "Delete", EN: "Delete", AR: "حذف" },
        { word: "Dashboard", EN: "Dashboard", AR: "لوحة القيادة" },
        { word: "IVY Administration", EN: "IVY Administration", AR: " IVY ادارة" },
        { word: "Settings", EN: "Settings", AR: "الاعدادات" },
        { word: "Activity Log", EN: "Activity Log", AR: "سجل النشاطات" },
        { word: "Logout", EN: "Logout", AR: "تسجيل الخروج" },
        { word: "Contact Us", EN: "Contact Us", AR: "تواصل معنا" },
        { word: "About Us", EN: "About Us", AR: "معلومات عنا " },
        { word: "Location Information", EN: "Location Information", AR: "معلومات عن الموقع" },
        { word: "Our Address", EN: "Our Address", AR: "العنوان" },
        { word: "123, Cairo Egypt", EN: "123, Cairo Egypt", AR: "القاهرة مصر , 123" },
        { word: "Al hadaba Alwosta", EN: "Al hadaba Alwosta", AR: "الهضبة الوسطى" },
        { word: "Map of our Location", EN: "Map of our Location", AR: " الخريطة" },
        { word: "Students", EN: "Students", AR: " الطلاب" },
        { word: "Logout", EN: "Logout", AR: " تسجيل الخروج" },
        { word: "Login", EN: "Login", AR: " تسجيل الدخول " },
        { word: "Please sign in", EN: "Please sign in", AR: " قم بتسجيل الدخول " },
        { word: "Password:", EN: "Password:", AR: " كلمة المرور " },
        { word: "Password", EN: "Password", AR: " كلمة المرور " },
        { word: "Username:", EN: "Username:", AR: "  اسم المستخدم " },
        { word: "Username", EN: "Username", AR: "  اسم المستخدم " },
        { word: "Sign in", EN: "Sign in", AR: "  تسجيل الدخول " },

    ]
    dic = [...this.actions, ...this.pages , ...this.websitewords,...this.menuItems]

    translate(word: any) {
        let index = this.dic.findIndex((w) => w.word === word)
        if (index !== -1 && Translate.lang === "EN") {
            return this.dic[index].EN
        } else if (index !== -1 && Translate.lang === "AR") {
            return this.dic[index].AR

        } else {
            return word;
        }
    }
}