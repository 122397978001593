import React from "react";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { actionCreators as Login } from '../store/Login';
import { ApplicationState } from "../../store";
import { actionCreators as UserActionCreator } from "../../store/User";
import $ from "jquery";
import "../../css/DashBoard.css";
import "../../css/wave.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { handleCount } from '../../helpers/validation';
import { makeId } from "../../helpers/func";
import { ReactMediaRecorder } from "react-media-recorder";
// import { isNullOrUndefined } from '../../helpers/func';
// import Option, { OptionProps } from "./smallComponents/Option";
const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  GetUser: () => dispatch(UserActionCreator.GET_USER()),
  SetMenu: (menu: any) => dispatch(UserActionCreator.SET_USER_MENU(menu)),
  Logout: () => dispatch(UserActionCreator.CLEAR_USER_LOGOUT()),
});

import AudioPreview from "./smallComponents/AudioPreview";
import SweetAlert from "react-bootstrap-sweetalert";
import DifficultLevel from "./smallComponents/DifficultLevel";
// let obj = {
//   feedback: "please be consistent",
//   mode: "editingMode",
//   totalMark: "20",
//   header:
//     '<p><span style="font-size: 14px;"><strong>Please C…g>2 * 5 + 4 = ?</strong></span></span></span></p>',
//   options: [
//     {
//       authorNote: "Correct",
//       id: "Gww36bw4fp",
//       option: "<p>14</p>",
//       selected: true,
//       isTrue: false,
//     },

//     {
//       authorNote: "You should do the multiplication first.",
//       id: "3wBfSgxArP",
//       option: "<p>18</p>",
//       selected: false,
//       isTrue: false,
//     },
//   ],
// };
// editingMode
// examMode
// gradingModeTeacher
// gradingModeStudent
class AudioComponent extends Component<any, any> {
  state = {
    id: makeId(10),
    type:"AudioComponent",
    isRecording: false,
    showObjectives: false,
    objectives: "",
    feedback: "",
    mode: "editingMode",
    totalMark: "",
    score:"0",
    header: "<p></p>",
    mediaBlobUrl: "",
    showRecordingOpt: true,
    headertxt:'',
    required:true,
    difficult:"Low",
    cognitive:"Understanding",
    alert :null
  };
  constructor(props :any){
    super(props)
    if (this.props.quest != undefined) {
      this.state = this.props.quest;
    } 
    this.onConfirm = this.onConfirm.bind(this)
  }
  onConfirm (e:any){
       this.setState({alert : null})
  }
  // state = obj;
   onSaveQuestion  (updatedState:any){
    let require=handleCount(this.state.header);
      if(require) {
       this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >  Question Header is required </SweetAlert> )})   
          }else if(this.state.totalMark.length==0){
            this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >    Total mark is required </SweetAlert> )})   
          }else{
            this.props.onSaveQuestion(updatedState)
          }
   }
  onHandleCount=()=>{
    let require=handleCount(this.state.header);
    this.setState({required:require});
  }
 
  componentDidMount() {
    $("#preloader").hide();
  }
  // setHeader = (contents: any) => {
  //   this.header = contents;
  // };

  onSaveVideo = async (mediaBlobUrl: any) => {
    // axios.get(mediaBlobUrl).then((r) => r.blob())
    let blob = await fetch(mediaBlobUrl)
      .then((r) => r.blob())
      .then((blobFile) => {
        let file = new File([blobFile], "fileNameGoesHere", {
          type: "audio/wav",
        });
        var reader: any = new FileReader();
        reader.readAsDataURL(file);
       
        reader.onload = () => {
          if (reader.result.split(":")[1].split("/")[0] === "audio") {
            this.setState({
              mediaBlobUrl: reader.result,
              showRecordingOpt: false,
            });
            let mediaBlobUrl =reader.result ;
            let updatedState = {...this.state , mediaBlobUrl}
            if(this.state.mode == "examMode")
            this.onSaveQuestion(updatedState)
          }
        };
      });
  };

  render() {
    return (
      <div className="mx-5">
        {this.state.alert}
        <div className="row mx-auto rounded my-5 p-4 shadow ">
        {this.state.mode =="examMode" && (<div> Q {this.props.index} <br></br> </div> )}  
          <div className="col-12 row mx-auto align-items-center bg-question rounded p-3 text-white">
            <div className="col-12 row justify-content-between">
              <h3 className="text-shadow">Question Setup</h3>
              {/*<button
                className="btn bg-info text-white"
                onClick={this.stateToJsonGradingMode}
              >
                show
              </button>*/}
              <div className="d-flex flex-column">
                <div className="form-group d-flex align-items-center my-3">
                  <span className="col-auto text-shadow ">
                    Total mark : &nbsp;
                  </span>
                  {this.state.mode === "editingMode" ? (
                    <input
                      type="number"
                      min={0}
                      className="col form-control"
                      name=""
                      value={this.state.totalMark}
                      id=""
                      onChange={(e) =>
                        this.setState({ totalMark: e.target.value })
                      }
                    />
                  ) : this.state.mode === "examMode" ||
                    this.state.mode === "gradingModeTeacher" ||
                    this.state.mode === "gradingModeStudent" ? (
                    <span className="text-shadow">{this.state.totalMark}</span>
                  ) : null}
                  {this.state.mode === "gradingModeTeacher"? (
                    <>
                    <span className="col-auto border-left ml-2">
                        Score : &nbsp;
                    </span>
                    <input
                      type="number"
                      min={0}
                      className="col form-control"
                      name=""
                      id=""
                      value={this.state.score}
                      onChange={(e) =>
                        {
                          let score = e.target.value
                          let updatedState = {...this.state , score }
                          this.onSaveQuestion(updatedState)
                          this.setState({ score: e.target.value })
                        }
                      }
                    />
                    </>
                  ) :this.state.mode === "gradingModeStudent" ? (
                    <>
                    <span className="border-left ml-2 pl-2 text-shadow">
                      Score : &nbsp;{this.state.score}
                    </span>
                    <span>{this.state.score}</span>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-12 mb-5 row justify-content-center align-items-center">
              {this.state.mode === "editingMode" ? (
                <div className="col-12 w-100 row justify-content-center align-items-center">
                  <RichBox
                    onChange={(v: any) => {this.setState({ header: v });this.onHandleCount();}}
                    onChangeTxt={(a: any) => {this.setState({ headertxt: a });this.onHandleCount();}}
                    defaultValue={this.state.header}
                    height={"10px"}
                  ></RichBox>

                </div>
              ) : this.state.mode === "examMode" ||
                this.state.mode === "gradingModeTeacher" ||
                this.state.mode === "gradingModeStudent" ? (
                <>
                <div
                  className="bg-white w-100 text-dark rounded row align-items-center p-3"
                  dangerouslySetInnerHTML={{ __html: this.state.header }}

                ></div>
                <div className="mt-2 col-8 row justify-content-center">
                {this.state.isRecording===true?(<div className="ocean">
                  <div className="wave"></div>
                </div>):null}
                </div>
                </>
              ) : (
                <div></div>
              )}
            </div>
            {this.state.mode === "editingMode"  ? (
              <DifficultLevel 
               difficult = {this.state.difficult} 
                cognitive= {this.state.cognitive} 
                onChangeDifficult = {(difficult :any) => { this.setState({difficult :difficult}) }}
                onChangeCognitive = {(cognitive :any) => { this.setState({cognitive :cognitive}) }}
              />

            ) : null}   

            {this.state.mode === "gradingModeTeacher" ||
            this.state.mode === "gradingModeStudent" ? (
              <div className="col-12 my-3">
                <audio src={this.state.mediaBlobUrl} controls />
              </div>
            ) : null}

           
            {this.state.mode === "examMode" ? (
              <div className="col-12 row my-3 align-items-start ml-1">
                <ReactMediaRecorder
                  audio
                  render={({
                    startRecording,
                    stopRecording,
                    mediaBlobUrl,
                    previewStream,
                  }) => {
                    return (
                      <div className=" row">
                        <p className="col-12">{status}</p>
                        <div className="col-12">
                          {this.state.isRecording ? (
                            <AudioPreview stream={previewStream} />
                          ) : null}
                          {mediaBlobUrl && !this.state.isRecording && (
                            <>
                              <audio
                                src={mediaBlobUrl}
                                controls
                                autoPlay
                              />
                              {/* <audio src={mediaBlobUrl} controls autoPlay loop /> */}
                            </>
                          )}
                        </div>
                        <div className="col-12">
                          {this.state.showRecordingOpt ? (
                            <>
                              <button
                                className="btn btn-primary text-white mx-1"
                                onClick={() => {
                                  startRecording();
                                  this.setState({ isRecording: true });
                                }}
                              >
                                Start Recording
                              </button>
                              <button
                                className="btn btn-warning text-white mx-1"
                                onClick={() => {
                                  stopRecording();
                                  this.setState({
                                    isRecording: false,
                                  });
                                }}
                              >
                                Stop Recording
                              </button>
                              <button
                                className="btn btn-warning text-white mx-1"
                                onClick={() => {
                                  this.onSaveVideo(mediaBlobUrl);
                                }}
                              >
                                Save and Submit
                              </button>
                            </>
                          ) : null}
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
            ) : null}

           
{this.state.mode === "editingMode" ? (
              <div className="col-12 my-3  d-flex justify-content-between ">
                <button
                  className="btn btn-success"
                  onClick={() =>
                    this.setState({
                      showObjectives: !this.state.showObjectives,
                    })
                  }
                >
                  Learning objective
                </button>
              </div>
            ) : null}
               {this.state.showObjectives && this.state.mode === "editingMode" &&(
              <div className="col-12 row my-3 ">
                <label>Learning objective</label>
                <textarea
                  className="col-12 form-control"
                  rows={10}
                  value={this.state.objectives}
                  onChange={(e) =>
                    this.setState({ objectives: e.target.value })
                  }
                ></textarea>
              </div>
            )}

{this.state.mode === "gradingModeTeacher" ||
              this.state.mode === "gradingModeStudent" ? (
                <div className="col-12 mt-5 row align-items-start align-content-start mx-auto">
                  <label className="align-self-start">Feedback</label>
                  <textarea
                    className="form-control align-self-stretch "
                    name=""
                    id=""
                    value={this.state.feedback}
                    rows={5}
                    disabled={
                      this.state.mode === "gradingModeStudent" ? true : false
                    }
                    onChange={(e) =>{
                      let feedback = e.target.value ; 
                      let updatedState = {...this.state , feedback}
                      this.onSaveQuestion(updatedState)
                      this.setState({ feedback: e.target.value })
                    }
                    }
                  ></textarea>
                </div>
              ) : null}
{  this.state.mode == "editingMode" && <div className="text-center col-12">
              <button
                className="btn btn-warning btn-lg rounded col-3"
                onClick={()=>this.onSaveQuestion(this.state)}
             
              >
                {" "}
                Save
              </button>
            </div>}
          </div>
        </div>
      </div>
    );
    // }
  }
}
function mapStateToProps(state: ApplicationState) {
  const Store = { store: state };
  return Store;
}

export default withRouter<any,any>(
  connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    mapDispatchToProps // Selects which action creators are merged into the component's props
  )(AudioComponent as any)
);
