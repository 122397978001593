import * as React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
// import Main from "./components/MainComponent";
import Login from "./components/loginComponent";
import MCQ from "./components/questions/MCQComponent";
import TrueOrFalse from "./components/questions//TrueOrFalse";
import FillTheBlank from "./components/questions//FillTheBlank";
import VideoComponent from "./components/questions//VideoComponent";
import DashBoard from "./components/DashBoard/DashBoardComponent";
import { ToastProvider } from "react-toast-notifications";
import OrderComponent from "./components/questions/OrderComponent";
import MatchQComponent from "./components/questions/MatchQComponent";
import EssayComponent from "./components/questions/EssayComponent";
import AudioComponent from "./components/questions/AudioComponent";
import LabelQComponent from "./components/questions/LabelQComponent";
import ImageHighlightComponent from "./components/questions/ImageHighlightComponent";
import FileUploadEnhanced from "./components/questions/FileUploadEnhanced";
import assessmentComponent from "./components/AssessmentView/assessmentComponent";
import assessmentViewComponent from "./components/AssessmentView/AssessmentView";
import SideNav from "./components/DashBoard/SideNavComponent";
import StudentAssessment from "./components/AssessmentView/StudentAssessment";

export default function App() {
  return (
    <React.Fragment>
      <ToastProvider>
        <Switch>
          <Route path="/mcq" component={MCQ} />
          <Route path="/Order" component={OrderComponent} />
          <Route path="/Match" component={MatchQComponent} />
          <Route path="/Label" component={LabelQComponent} />
          <Route path="/truefalse" component={TrueOrFalse} />
          <Route path="/essay" component={EssayComponent} />
          <Route path="/videoqst" component={VideoComponent} />
          <Route path="/audioqst" component={AudioComponent} />
          <Route path="/fileuploadenhanced" component={FileUploadEnhanced} />
          <Route path="/filltheblank" component={FillTheBlank} />
          <Route path="/imagehighlight" component={ImageHighlightComponent} />
          <Route path="/assessmentcomponent" component={assessmentComponent} />
          <Route path="/assessmentViewComponent" component={assessmentViewComponent} />
          <Route path="/assessment" component={StudentAssessment} />
          <Route path="/sidnav" component={SideNav} />
          <Route path="/login" component={Login} />
          <Route path="/dashboard" component={DashBoard} />
          <Redirect to="/dashboard" />
        </Switch>
      </ToastProvider>
    </React.Fragment>
  );
}
