import React from "react";
import { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import { actionCreators as AssessmentActionCreator } from "../../../store/Assessment";
import assessmentComponent from "../../AssessmentView/assessmentComponent";

import Pagination from "react-js-pagination";
import classes from '../../../css/Pagination.module.css'



const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  GetStudentsExams: (data: any) =>
    dispatch(AssessmentActionCreator.GetStudentsExams(data)),
  GradingAssessment: (id: any) =>
    dispatch(AssessmentActionCreator.GradingAssessment(id)),
  resetGotData: () =>
    dispatch(AssessmentActionCreator.resetGotData()),
  resetAssessment: () =>
    dispatch(AssessmentActionCreator.resetAssessment()),
  GetSubjects: () =>
    dispatch(AssessmentActionCreator.GetSubjects()),
  ViewAssessment: (id: any) =>
    dispatch(AssessmentActionCreator.ViewAssessment(id)),

});

class StudentsExams extends Component<any, any> {
  state = {

    pagenumber: 1,
    pagesize: 2,
    search: "",
    status: "",
    subject_id: ""

  };
  componentDidMount() {
    $("#preloader").hide();
    require('datatables.net');
    require('../../MyComponents/dataTables.bootstrap4.min');
    $('#dataTable').DataTable();
    this.props.GetStudentsExams(this.state)

  }

  componentDidUpdate() {
    this.props.GetSubjects();

    if (this.props.viewdata == true) {
      this.props.resetGotData()
      this.props.history.push({ pathname: "/assessmentViewComponent", state: { exammode: "gradingModeTeacher" } });
    }
  }
  grading = (id: any) => {
    this.props.GradingAssessment(id)
  }
  takeExam = (id: any) => {
    this.props.history.push({ pathname: "/assessment",   search: '?id=' +id });
    
  }


  render() {

    const handlePageChange = (pagenumber: any) => {
      this.setState({ ...this.state, pagenumber })
      let updatedState = { ...this.state, pagenumber };
      this.props.GetStudentsExams(updatedState)

    }
    const onStatusChange = (status: any) => {
      this.setState({ status: status })
      let updatedState = { ...this.state, status };

      this.props.GetStudentsExams(updatedState)

    }
    const onSubjectChange = (subject_id: any) => {
      this.setState({ subject_id: subject_id })
      let updatedState = { ...this.state, subject_id };

      this.props.GetStudentsExams(updatedState)

    }
    const searchHandler = (search: any) => {
        this.setState({ ...this.state, search })
        let updatedState = { ...this.state, search };
        this.props.GetStudentsExams(updatedState);
    }
    let arrowLeft = <i className="fas fa-angle-double-left"></i>;
    let arrowRight = <i className="fas fa-angle-double-right"></i>;
    return (
      <div>
        {/* <button className="btn bg-info text-white mb-2" onClick = {this.addAssessmentHandler}>Add Assessment</button> */}
        <div className="row">
          <div className="input-group mt-3 mb-3 col-6">
            <input
            style={{height:"100%"}}
              type="text"
              className="form-control"
              onChange={(e) => searchHandler(e.target.value)}
            />
            <div className="input-group-append" style={{height:"100%"}}>
              <button className="btn btn-primary">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <div className="col-3  mt-3 mb-3 ">
            <select
              className='form-control form-control-lg form-select'
              onChange={(e) => onStatusChange(e.target.value)}
              required
              // value={area_id ? area_id : 'disableEle'}
              value={this.state.status}
            >
              <option value='' >
                Status
              </option>
              <option value='Not yet'>
                Not yet
              </option>
              <option value='Submitted'>
                Submitted
              </option>
              <option value='Graded'>
                Graded
              </option>


            </select>

          </div>
          <div className="col-3  mt-3 mb-3 ">
            <select
              className='form-control form-control-lg form-select'
              onChange={(e) => onSubjectChange(e.target.value)}
              required
              // value={area_id ? area_id : 'disableEle'}
              value={this.state.subject_id}
            >
              <option value='' >
                Subject
              </option>
              {this.props.subjects?.map((subject: any) => { return (<option key={subject.id} value={subject.id}>{subject.subject}</option>) })}
            </select>

          </div>
        </div>

        <div>
          <table
            className="table-hover table-bordered text-center"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>

                <th>Exam name</th>
                <th>Subject</th>

                <th>Student Id</th>
                <th>Student name</th>
                <th>Group name</th>
                <th>Release date</th>
                <th>Submission date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.studentsExams?.map((studentsExam: any, index: any) => {


                return (
                  <tr key={index}>

                    <td>{studentsExam.examname}</td>
                    <td>{studentsExam.subject}</td>
                    <td>{studentsExam.student_id}</td>
                    <td>{studentsExam.studentname}</td>
                    <td>{studentsExam.groupname}</td>
                    <td>{studentsExam.releasedate}</td>
                    <td>{studentsExam.submitdate}</td>

                    <td>{studentsExam.status}</td>

                  {studentsExam.status == "Not yet" &&  studentsExam.published == 1? ( 
                    <td>  <button className="btn bg-info text-white mb-2" onClick={() => { this.takeExam(studentsExam.id) }}>Take exam</button> </td>
                      ) : studentsExam.status == "Submitted" ? (
                  <td><button className="btn bg-info text-white mb-2" onClick={() => { this.grading(studentsExam.id) }}>Grading</button>  <span>&nbsp;</span> </td>
                    
                    ) : null}
                   
                  </tr>

                )
              })}

            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-center align-items-center my-3 text-black-50"  >
          {this.props.studentsExams?.length !== 0 ? (
            <Pagination
              activePage={this.state.pagenumber}
              itemsCountPerPage={this.state.pagesize}
              totalItemsCount={+this.props.count}
              pageRangeDisplayed={5}
              onChange={(e) => handlePageChange(e)}
              firstPageText={"First"}
              lastPageText={"Last"}
              prevPageText={arrowLeft}
              nextPageText={arrowRight}
              activeClass={classes.active}
            />
          ) : <div> No data exists </div>}
        </div>
      </div>
    )
  }
}
export default withRouter(
  connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    mapDispatchToProps
  )(StudentsExams as any)
);
function mapStateToProps(state: any) {
  return {
    studentsExams: state.Assessment.studentsExams,
    loading: state.Assessment.loading,
    gotdata: state.Assessment.gotdata,
    viewdata: state.Assessment.viewdata,
    count: state.Assessment.studentExamCount,
    subjects: state.Assessment.subjects,
  };
}
