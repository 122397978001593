import { applyMiddleware, combineReducers, compose, createStore, AnyAction } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState, reducers } from './';
import {CLEAR_USER_LOGOUT } from './ActionTypes';

export default function configureStore(history: History, initialState?: ApplicationState) {
    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    const appReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });
    const rootReducer = (state: unknown, action: AnyAction) => {
        if (action.type === CLEAR_USER_LOGOUT ) {
            state = undefined
            localStorage.clear();
        }
        return appReducer(state, action);
      }
    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }
    const presistedState = loadFromLocalStorage();
    const store = createStore(
        rootReducer,
        //initialState,
        presistedState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
    store.subscribe(() => saveToLocalStorage(store.getState()));
    return store;
}

function saveToLocalStorage(state: any) {
    try {
        let serializedState = JSON.stringify(state);
        serializedState = serializedState.replace('"gotData":true', '"gotData":false');
        localStorage.setItem('state', serializedState);
    } catch (e) {
          
    }
}

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) return undefined;
        let state = JSON.parse(serializedState);
        //  
        for (var propName in state.componentCreator){           
            try {
                state[propName]['isLoading'] = true;
                state[propName]['gotData'] = false;
            }catch(e){}
        } 

        state["router"] = undefined;
        return state;
    } catch (e) {
          
        return undefined;
    }
}