import React, { Component } from "react";

import RichBox from "./RichBoxComponent";


// const selectedStyle = {
//   cursor: "pointer",
//   backgroundColor: "#9df3b1 !important",
//   border: "4px solid #098434 !important",
//   borderRadius: "10px !important",
// };

class DifficultLevel extends Component<any, any> {
  /*   state = {
    id: this.props.id,
    option: this.props.option ?? "",
    authorNote: this.props.authorNote ?? "",
  }; */
  // static getDrivedStateFromProps() {

  // }

  render() {
    // console.log(this.props);
    // if(this)

    return (
        <div className="col-12 row m-0 p-0 align-items-center align-items-center ">
        <div className="col-sm-6">
          
        <label className=" text-white ml-1">Difficult level: </label>
        <select
          className="form-control "
          name="difficult"
          id="difficult"
          defaultValue={this.props.difficult}
          onChange={(e: any) =>
            this.props.onChangeDifficult(e.target.value)
          }
          
          >
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </select>
        </div>

        <div className="col-sm-6"> 

        <label className="text-white">Cognitive level: </label>
        <select
          className="form-control "
          name="cognitive"
          id="cognitive"
          defaultValue={this.props.cognitive}
          onChange={(e: any) =>
           this.props.onChangeCognitive(e.target.value)
          }
         
          >
          <option value="Understanding">Understanding</option>
          <option value="Applying">Applying</option>
          <option value="Problemsolving">Problem solving / critical thinking</option>
        </select>
          </div>
      </div>
        )
  }
}

export default DifficultLevel;
