import $ from "jquery";
export function handleCount(h:any) {
    var content = h;
   
    var tempallword = $('<div>').html(content).text();
    let regexp: RegExp = /[ ]*[\.]*[<A-z>'"|\d]+[\.]*[ ]*/g;
    const str = tempallword;
    const array = [...str.matchAll(regexp)];
    let count = array.length;
    if(count < 1 &&  $('<div>').html(content).find('img').length < 1 ){
        return true;
    }
    else{
        return false;
    }
  }
export function shuffle(array:any) {
    var currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
  }