import React, { ReactFragment } from "react";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { actionCreators as Login } from '../store/Login';
//import { ApplicationState } from "../store";
import $ from "jquery";
import "../../css/DashBoard.css";
import "../../css/assessment.css";
import { actionCreators as AssessmentActionCreator } from "../../store/Assessment";
import MCQ from "../questions/MCQComponent";
import TrueOrFalse from "../questions/TrueOrFalse";
import OrderComponent from "../questions/OrderComponent";
import MatchQComponent from "../questions/MatchQComponent";
import EssayComponent from "../questions/EssayComponent";
import AudioComponent from "../questions/AudioComponent";
import LabelQComponent from "../questions/LabelQComponent";
import ImageHighlightComponent from "../questions/ImageHighlightComponent";
import FileUploadEnhanced from "../questions/FileUploadEnhanced";
import FillTheBlank from "../questions/FillTheBlank";
import VideoComponent from "../questions/VideoComponent";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { makeId } from "../../helpers/func";
import Select from 'react-select';
import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert";
const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  onSaveQuestionHandler: (data: any) =>
    dispatch(AssessmentActionCreator.saveQuestion(data)),
  AddAssessment: (data: any) =>
    dispatch(AssessmentActionCreator.AddAssessment(data)),
  DeleteQuestion: (id: any) =>
    dispatch(AssessmentActionCreator.DeleteQuestion(id)),
  GetAssessment: (id: any) =>
    dispatch(AssessmentActionCreator.GetAssessment(id)),
   UpdateAssessment: (data: any) =>
    dispatch(AssessmentActionCreator.UpdateAssessment(data)),
    resetAssessment: () =>
    dispatch(AssessmentActionCreator.resetAssessment()),
    GetGrades: () =>
    dispatch(AssessmentActionCreator.GetGrades()),
    GetSubjects: () =>
    dispatch(AssessmentActionCreator.GetSubjects()),
    GetGroups: () =>
    dispatch(AssessmentActionCreator.GetGroups()),
    GetTeachers: () =>
    dispatch(AssessmentActionCreator.GetTeachers()),
});

class assessmentComponent extends Component<any, any> {
  state = {
    id: makeId(10),
    name: "",
    grade: "",
    subject_id: "",
    startdate: "",
    enddate: "",
    nooftrials: 1,
  
    grading_teachers_ids: {},
    editing_teachers_ids: {},
    submitalert : false ,
   
    questions: [],
    open: false,
    selectedgroups:[],
    selectedteachers:[],
    weeknumber:1,
    istimed:0,
    duration:0
  };
  modalContent: any = null;
 

  componentDidMount() {
    $("#preloader").hide();
           const assessment = this.props.assessment
        if(assessment){

        this.setState({
          id: assessment.id,
          name :assessment.name ,     
          grade: assessment.grade,
          subject_id: assessment.subject_id,
          startdate: assessment.startdate,
          enddate: assessment.enddate,
          nooftrials: assessment.nooftrials ,
          
              
          questions: assessment.questions,
          open: assessment.open,
          selectedgroups:assessment.selectedgroups,
          selectedteachers:assessment.selectedteachers,
          weeknumber:assessment.weeknumber,
          istimed:assessment.istimed,
          duration:assessment.duration        })
        }
        this.props.GetGrades();
        this.props.GetSubjects();
        this.props.GetGroups();
        this.props.GetTeachers();
    
  }
 

  onCloseModal = (Q: any = null) => {
    // save q in array in the store

    this.setState({ open: false });
  };
  onSaveQuestion = (q: any) => {
    this.props.onSaveQuestionHandler(q);
    this.onCloseModal();
  };

  submitExam = () => {
    const {
      id,
      name,
      grade,
      subject_id,
      startdate,
      enddate,
      nooftrials,
 
      selectedgroups,
      selectedteachers,
      weeknumber,
      istimed,
      duration
    } = this.state;

    const data = {
      id,
      name,
      grade,
      subject_id,
      startdate,
      enddate,
      nooftrials,
 
      questions: this.props.questions,
      selectedgroups,
      selectedteachers,
      weeknumber,
      istimed,
      duration
    };
    if(this.props.assessment){
    
      this.props.UpdateAssessment(data);

    }else{

      this.props.AddAssessment(data);
    }
  };


   removeQuest =(e:any)=>{
     this.props.DeleteQuestion(e.id)
  }
  render() {
    const handleChange = (selectedgroups:any) => {
      this.setState({selectedgroups});
    };
    const selectedgroups  = this.state.selectedgroups;
    const options = 
    this.props.groups?.map((group:any) =>{
      return  { value: group.id, label: group.name }
    })
    const selectedteachers  = this.state.selectedteachers;
    const teacherOptions = 
    this.props.teachers?.map((teacher:any) =>{
      return  { value: teacher.id, label: teacher.name }
    })
  
    const QuestionsRenderer = (q :any) => {
      
      switch (q) {
        case "MCQ":
          this.modalContent = (
            <MCQ
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return;
        case "OrderComponent":
          this.modalContent = (
            <OrderComponent
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return;
        case "MatchQComponent":
          this.modalContent = (
            <MatchQComponent
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return;
        case "LabelQComponent":
          this.modalContent = (
          <LabelQComponent
            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
          />
        );
        return;
        case "TrueOrFalse":
          this.modalContent = (
          <TrueOrFalse
            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
          />
        );
        return;
        case "EssayComponent":
          this.modalContent = (
            <EssayComponent
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return;
        case "VideoComponent":
          this.modalContent = (
            <VideoComponent
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return;
        case "AudioComponent":
          this.modalContent = (
            <AudioComponent
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return;
        case "FileUploadEnhanced":
          this.modalContent = (
            <FileUploadEnhanced
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return;
        case "FillTheBlank":
          this.modalContent = (
            <FillTheBlank
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return;
        case "ImageHighlight":
          this.modalContent = (
            <ImageHighlightComponent
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return;
        default:
          return (
            <h1>
              Select Question Type From The Left Menu To Add It To The
              Assessment
            </h1>
          );
      }
    };
    const editQuestionsRenderer = (s: any, q: any) => {
    
      switch (s) {
        case "MCQ":
          this.modalContent = (
            <MCQ
              quest={q}
               onCloseModal={() => this.onCloseModal()}
             
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "OrderComponent":
          this.modalContent = (
            <OrderComponent
              quest={q}
              onCloseModal={() => this.onCloseModal() }
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "MatchQComponent":
      
          this.modalContent = (
            <MatchQComponent
              quest={q}
              onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "LabelQComponent":
          this.modalContent = (
            <LabelQComponent
              quest={q}
              onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "TrueOrFalse":
          this.modalContent = (
            <TrueOrFalse
              quest={q}
              onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "EssayComponent":
          this.modalContent = (
            <EssayComponent
              quest={q}
               onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "VideoComponent":
          this.modalContent = (
            <VideoComponent
              quest={q}
               onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "AudioComponent":
          this.modalContent = (
            <AudioComponent
              quest={q}
               onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "FileUploadEnhanced":
          this.modalContent = (
            <FileUploadEnhanced
              quest={q}
               onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "FillTheBlank":
          this.modalContent = (
            <FillTheBlank
              quest={q}
               onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "ImageHighlight":

          this.modalContent = (
            <ImageHighlightComponent
              quest={q}
               onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        default:
          return (
            <h1>
              Select Question Type From The Left Menu To Add It To The
              Assessment
            </h1>
          );
      }
    };
    //this.props.questions.find
     const onConfirm = () =>{
    
            this.props.resetAssessment()
            this.props.history.push("/dashboard/assessments");

     }
     const onConfirmSubmit = () =>{
    
           this.setState({submitalert : false})
           this.submitExam()
     }
     const onCancelSubmit = () =>{
    
           this.setState({submitalert : false})
       
     }


let MSG = null ;
if( this.props.assessmentMsg !="" ){
  MSG =(
<SweetAlert
success
  title= "success"
  onConfirm={onConfirm}
  onCancel={onConfirm}
  
  >
   {this.props.assessmentMsg}
 </SweetAlert>
  )
}
let submitalert = null ;
if( this.state.submitalert !=false ){
  submitalert =(
<SweetAlert
warning
  title= "Are you sure publishing this exam? "
  onConfirm={onConfirmSubmit}
  onCancel={onCancelSubmit}
  showCloseButton
  >
   {this.props.assessmentMsg}
 </SweetAlert>
  )
}

  


    return (
      
     this.props.addAssessmentloading != true ? (
       <div>
        {MSG}
        {submitalert}
        <div className=" d-flex d-md-block flex-nowrap wrapper ">
          <div
            className="col-md-2 float-left col-1 pl-0 pr-0 collapse width show"
            id="sidebar"
          >
            <div className="list-group border-0 card text-center text-md-left">
              <div
                className="list-group-item pointer ml-3 d-inline-block collapsed"
                data-parent="#sidebar"
                onClick={() => {
                  QuestionsRenderer("TrueOrFalse");
                  this.setState({ open: true });
                }}
              >
                <i
                  className="fas fa-check-circle"
                  style={{ marginRight: "2px" }}
                  aria-hidden="true"
                ></i>
                <i className="fas fa-times-circle" aria-hidden="true"></i>
                <span className="d-none d-lg-inline">+ True/False </span>
              </div>
              <div
                className="list-group-item pointer ml-3 d-inline-block collapsed"
                data-parent="#sidebar"
                onClick={() => {
                  QuestionsRenderer("MCQ");

                  this.setState({ open: true });
                 
                }}
              >
                <i className="fas fa-tasks"></i>{" "}
                <span className="d-none d-lg-inline">+ Multiple Choice </span>
              </div>
              <div
                className="list-group-item pointer ml-3 d-inline-block collapsed"
                data-parent="#sidebar"
                onClick={() => {
                  QuestionsRenderer("MatchQComponent");
                  this.setState({
                    open: true,
                 
                  });
                }}
              >
                <i className="fas fa-bars"></i>{" "}
                <span className="d-none d-lg-inline">+ Matching </span>
              </div>
              <div
                className="list-group-item pointer ml-3 d-inline-block collapsed"
                data-parent="#sidebar"
                onClick={() => {
                  //this.setState({  selectedQuestion: "OrderComponent" });
                  QuestionsRenderer("OrderComponent");
                  this.setState({ open: true });
                }}
              >
                <i className="fas fa-sort"></i>{" "}
                <span className="d-none d-lg-inline">+ Ordering </span>
              </div>
              {/* <div
                className="list-group-item pointer ml-3 d-inline-block collapsed"
                data-parent="#sidebar"
                onClick={() => {
                  QuestionsRenderer("FillTheBlank");
                  this.setState({ open: true });
                }}
              >
                <i className="fas fa-underline"></i>{" "}
                <span className="d-none d-lg-inline">+ Fill In The Blank</span>
              </div> */}
              <div
                className="list-group-item pointer ml-3 d-inline-block collapsed"
                data-parent="#sidebar"
                onClick={() => {
                  QuestionsRenderer("EssayComponent");
                  this.setState({ open: true });
                }}
              >
                <i className="fas fa-keyboard"></i>{" "}
                <span className="d-none d-lg-inline">+ Short Answer/Essay</span>
              </div>
              <div
                className="list-group-item pointer ml-3 d-inline-block collapsed"
                data-parent="#sidebar"
                onClick={() => {
                  QuestionsRenderer("AudioComponent");
                  this.setState({ open: true });
                }}
              >
                <i className="fas fa-microphone"></i>{" "}
                <span className="d-none d-lg-inline">+ Audio</span>
              </div>
              <div
                className="list-group-item pointer ml-3 d-inline-block collapsed"
                data-parent="#sidebar"
                onClick={() => {
                  QuestionsRenderer("VideoComponent");
                  this.setState({ open: true });
                }}
              >
                <i className="fas fa-video"></i>{" "}
                <span className="d-none d-lg-inline">+ Video</span>
              </div>
              <div
                className="list-group-item pointer ml-3 d-inline-block collapsed"
                data-parent="#sidebar"
                onClick={() => {
                  QuestionsRenderer("FileUploadEnhanced");
                  this.setState({ open: true });
                }}
              >
                <i className="fas fa-file-upload"></i>{" "}
                <span className="d-none d-lg-inline">+ File Upload</span>
              </div>
              {/* <div
                className="list-group-item pointer ml-3 d-inline-block collapsed"
                data-parent="#sidebar"
                onClick={() => {
                  QuestionsRenderer("LabelQComponent");
                  this.setState({ open: true });
                }}
              >
                <i className="fas fa-tag"></i>{" "}
                <span className="d-none d-lg-inline">+ Label Image</span>
              </div> */}
              <div
                className="list-group-item pointer ml-3 d-inline-block collapsed"
                data-parent="#sidebar"
                onClick={() => {
                  QuestionsRenderer("ImageHighlight");
                  this.setState({ open: true });
                  
                }}
              >
                <i className="fas fa-highlighter"></i>{" "}
                <span className="d-none d-lg-inline">+ Highlight Image</span>
              </div>
            </div>
          </div>
          <div className="col-md-9 float-left mx-auto pl-md-2 pt-2 main">
            <div>
              <div className="col-12 mx-auto align-items-center bg-question rounded p-3 text-white">
                <h3 className="text-shadow pl-1">Assessment Setup</h3>
                <div className="row">
                  <div className="col-md-6 row align-items-start align-content-start mx-auto">
                    <label
                      className="align-self-start ml-1 mt-1"
                      htmlFor="title"
                    >
                      Title: &nbsp;
                    </label>
                    <input
                      id="title"
                      name="title"
                      type="text"
                      className="form-control"
                      value={this.state.name}
                      onChange={(e) =>
                        this.setState({ name: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-6 row align-items-start align-content-start mx-auto">
                    <label
                      className="align-self-start ml-1 mt-1"
                      htmlFor="grade"
                    >
                      Grade: &nbsp;
                    </label>
                    <select
                      className="form-control"
                      name="grade"
                      id="grade"
                      value={this.state.grade}
                      onChange={(e: any) =>
                        this.setState({
                          grade: e.target.value,
                        })
                      }
                    >
                      {this.props.grades?.map((grade:any) =>{return (<option key={grade.id} value={grade.id}>{grade.grade}</option>)})}
                    
                    </select>
                  </div>
                  <div className="col-md-6 mt-2 row align-items-start align-content-start mx-auto">
                    <label
                      className="align-self-start ml-1 mt-1"
                      htmlFor="subject_id"
                    >
                      Subject: &nbsp;
                    </label>
                    <select
                      className="form-control"
                      name="subject_id"
                      id="subject_id"
                      value={this.state.subject_id}
                      onChange={(e: any) =>
                        this.setState({
                          subject_id: e.target.value,
                        })
                      }
                    >
                      <option value="">Select</option>
                      {this.props.subjects?.map((subject:any) =>{return(  <option key={subject.id} value={subject.id}>{subject.subject}</option>)})}
                     
                    </select>
                  </div>
                  <div className="col-md-6 mt-2 align-items-start align-content-start mx-auto">
                    <label
                      className="align-self-start mt-1 ml-1"
                      htmlFor="selectedgroups"
                    >
                      Assigned group: &nbsp;
                    </label>
                    <Select
                      isMulti
                      name="selectedgroups"
                      options={options}
                      className="basic-multi-select text-dark"
                      classNamePrefix="select"
                      onChange={handleChange}
                      value={selectedgroups}
                    />
                  </div>
                  <div className="col-md-6 mt-2 row align-items-start align-content-start mx-auto">
                    <label
                      className="align-self-start ml-1 mt-1"
                      htmlFor="startDate"
                    >
                      Start date: &nbsp;
                    </label>
                    <input
                      id="startDate"
                      name="startDate"
                      type="datetime-local"
                      className="form-control"
                      value={this.state.startdate}
                      onChange={(e) =>
                        this.setState({ startdate: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-6 mt-2 row align-items-start align-content-start mx-auto">
                    <label
                      className="align-self-start ml-1 mt-1"
                      htmlFor="endDate"
                    >
                      End date: &nbsp;
                    </label>
                    <input
                      id="endDate"
                      name="endDate"
                      type="datetime-local"
                      className="form-control"
                      value={this.state.enddate}
                      onChange={(e) =>
                        this.setState({ enddate: e.target.value })
                      }
                    />
                  </div>

                  <div className="col-md-6 mt-2 align-items-start align-content-start mx-auto">
                    <label
                      className="align-self-start mt-1 ml-1"
                      htmlFor="selectedgroups"
                    >
                      Assigned teachers: &nbsp;
                    </label>
                    <Select
                      isMulti
                      name="selectedgroups"
                      options={teacherOptions}
                      className="basic-multi-select text-dark"
                      classNamePrefix="select"
                      onChange={(e)=>{ this.setState({selectedteachers:e})}}
                      value={selectedteachers}
                    />
                  </div>


                  <div className="col-md-6 mt-2 row align-items-start align-content-start mx-auto">
                    <label
                      className="align-self-start ml-1 mt-1"
                      htmlFor="nooftrials"
                    >
                      Number of trials: &nbsp;
                    </label>
                    <input
                      type="number"
                      min={1}
                      className="form-control"
                      name="nooftrials"
                      id="nooftrials"
                      value={this.state.nooftrials}
                      onChange={(e) =>
                        this.setState({ nooftrials: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-6 mt-2 align-items-start align-content-start mx-auto">
                    <label
                      className="align-self-start mt-1 ml-1"
                      htmlFor="weeknumber"
                    >
                      Week No. : &nbsp;
                    </label>
                    <input
                      type="number"
                      min={1}
                      className="form-control"
                      name="weeknumber"
                      id="weeknumber"
                      value={this.state.weeknumber}
                      onChange={(e) =>
                        this.setState({ weeknumber: e.target.value })
                      }
                    />
                  </div>
                <div className="col-md-6">

                </div>
                  {this.state.istimed == 0  ? 
                  <div className="col-md-6 mt-2 align-items-center align-content-center align-self-center">
                    <label
                      className="align-self-center mt-1 ml-1"
                      htmlFor="timed"
                    >
                      Timed: &nbsp;
                    </label>
                    <input
                      type="checkbox"
                      name="timed"
                      className="align-self-start"
                      checked={this.state.istimed == 0 ? false :true}
                      id="timed"
                      onChange={(e) =>{
                        this.setState({ istimed:  e.target.checked == false ? 0 :1 })

                      }
                      }
                    />
                  </div>:(
                    <React.Fragment>

                    <div className="col-md-6 mt-2 align-items-center align-content-center align-self-center">
                    <label
                      className="align-self-center mt-1 ml-1"
                      htmlFor="timed"
                      >
                      Timed: &nbsp;
                    </label>
                    <input
                      type="checkbox"
                      name="timed"
                      className="align-self-start"
                      checked={this.state.istimed == 0 ? false :true}
                      id="timed"
                      onChange={(e) =>
                        {
                         
                          this.setState({ istimed:  e.target.checked == false ? 0 :1 })
                        }
                      }
                      />
                  </div>
                  
                  <div className="col-md-6 mt-2 align-items-start align-content-start mx-auto">
                    <label
                      className="align-self-start mt-1 ml-1"
                      htmlFor="duration"
                      >
                      Duration: (minutes) &nbsp;
                    </label>
                    <input
                      type="number"
                      min={1}
                      className="form-control"
                      name="duration"
                      id="duration"
                      value={this.state.duration}
                      onChange={(e) =>
                        this.setState({ duration: e.target.value })
                      }
                      />
                  </div>
               </React.Fragment>
                  )}
                 
                  
                </div>
                <h3 className="text-shadow mt-2 ml-3">Questions : </h3>
                {this.props.questions.map((q: any, index: number) => {
                  return (
                    <div className="row  mx-auto align-items-center" key={index}>
                      <div className="col-md-10 mt-2 ">
                        <div
                          className="bg-white text-dark rounded row align-items-center mt-1 pl-5 "
                          dangerouslySetInnerHTML={{ __html: q.header }}
                        ></div>
                      </div>
                      <div className="col-md-1 mt-2">
                        <button
                          type="button"
                          onClick={() => {
                            editQuestionsRenderer(q.type, q);
                            this.setState({ open: true });
                          }}
                          className="btn btn-block text-white btn-warning mt-1"
                        >
                          Edit
                        </button>
                      </div>
                      <div className="col-md-1 mt-2">
                        <button
                          type="button"
                          className="btn btn-block text-white btn-danger mt-1"
                          onClick={() => this.removeQuest(q)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  );
                })}
                <div className="text-center mt-5 ">
                  <button
                    className="btn btn-warning text-white"
                    onClick={this.submitExam}
                    disabled={this.state.name?.length==0 || this.state.selectedgroups?.length == 0 || this.state.selectedteachers?.length == 0
                    || this.state.subject_id == ""
                    }
                  >
                    Submit exam
                  </button>
                </div>
              </div>
              <Modal
                classNames={{
                  modal: "customModal",
                }}
                open={this.state.open}
                onClose={() => this.onCloseModal()}
                center
              >
                {this.modalContent}
                {/*QuestionsRenderer()*/}
              </Modal>
            </div>
          </div>
        </div>
      </div>
     
     
      ): (
        <div className="d-flex justify-content-center align-items-center mt-5">
        <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
      </div>
      )
     
    );
    // }
  }
}
function mapStateToProps(state: any) {
  return {
    questions: state.Assessment.questions,
    assessment: state.Assessment.assessment,
    loading: state.Assessment.loading,
    assessmentMsg: state.Assessment.assessmentMsg,
    addAssessmentloading: state.Assessment.addAssessmentloading,
    grades: state.Assessment.grades,
    subjects: state.Assessment.subjects,
    groups: state.Assessment.groups,
    teachers: state.Assessment.teachers,
  };
}

export default withRouter(
  connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    mapDispatchToProps // Selects which action creators are merged into the component's props
  )(assessmentComponent as any)
);
