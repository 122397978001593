import React from "react";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { actionCreators as Login } from '../store/Login';
import { ApplicationState } from "../../store";
import { actionCreators as UserActionCreator } from "../../store/User";
import $ from "jquery";
import "../../css/DashBoard.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { makeId } from "../../helpers/func";
// import { isNullOrUndefined } from '../../helpers/func';
import Option, { OptionProps } from "./smallComponents/Option";
import { handleCount } from '../../helpers/validation';
import SweetAlert from "react-bootstrap-sweetalert";
import DifficultLevel from "./smallComponents/DifficultLevel";
const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  GetUser: () => dispatch(UserActionCreator.GET_USER()),
  SetMenu: (menu: any) => dispatch(UserActionCreator.SET_USER_MENU(menu)),
  Logout: () => dispatch(UserActionCreator.CLEAR_USER_LOGOUT()),
});
function shuffle(array: any) {
  var currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
let obj = {
  id: makeId(10),
  type: "MCQ",
  showObjectives: false,
  shuffleOptions: false,
  objectives: "",
  feedback: "",
  mode: "editingMode",
  totalMark: "",
  score: "0",
  header: '<p></p>',
  isPartial: true,
  headertxt: '',
  required: true,
  alert : null,
  options: [
    {
      authorNote: "",
      id: makeId(10),
      option: "<p> </p>",
      selected: false,
      isTrue: false,
     
    },
    {
      authorNote: "",
      id: makeId(10),
      option: "<p> </p>",
      selected: false,
      isTrue: false,
     
    },

  ],
  difficult:"Low",
  cognitive:"Understanding",
  oneLineOptions :false
};
// editingMode
// examMode
// gradingModeTeacher
// gradingModeStudent
class MCQ extends Component<any, any> {
  

  constructor(props: any) {
    super(props)
    this.state = obj;

    if (this.props.quest != undefined) {
      this.state = this.props.quest;
    } else {
      this.state = obj;
    }
    this.onConfirm = this.onConfirm.bind(this)
  }

  componentDidMount() {
    $("#preloader").hide();
    if (this.state.mode === "examMode" && this.state.shuffleOptions) {
      const shuffled = shuffle([...this.state.options]);
      this.setState({ options: shuffled, shuffleOptions: false });
    }
  }
  onConfirm (){
    this.setState({alert : null})
}
  onSaveQuestion  (updatedState:any){

    //sdjkldjl
 // check required on options and header
 let optionsrequired = false;
 // check answer mode 
 let answered = false;
 let require = handleCount(this.state.header);
 this.state.options.forEach((o: any) => {
   if (optionsrequired === false) {
     if (handleCount(o.option) === true) {
       optionsrequired = true;
     }
   }
   if (answered === false) {
     if (o.isTrue === true) {
       answered = true;
     }
   }
 });
 // count of options must be => 2 
 let count = this.state.options.length;
    if(require || optionsrequired) {
     this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >  Question header and quetions options  are required </SweetAlert> )})   
        }else if(this.state.totalMark.length==0){
          this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >    Total mark is required </SweetAlert> )})   
        }else if(count < 2){
          this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >   Options mustn't be less than two options  </SweetAlert> )})   
        }else if(answered ==false){
          this.setState({ alert : (<SweetAlert error   title= "error" onConfirm={this.onConfirm} >    Please select correct answer from answer mode  </SweetAlert> )})   

        }
        
        else{
          this.props.onSaveQuestion(updatedState)
        }
 }


 
  // setHeader = (contents: any) => {
  //   this.header = contents;
  // };

  addOptionHandler = () => {
    let newOp = [
      ...this.state.options.concat({
        id: makeId(10),
        option: "<p> </p>",
        authorNote: "",
        selected: false,
        isTrue: false,
        required: true,
      }),
    ];
    this.setState({ options: newOp });
   
  };

  onOptionUpdate = (val: OptionProps) => {
    let option = this.state.options.find((x: any) => x.id === val.id);
    if (option) {
      let index = this.state.options.indexOf(option);
      let options = this.state.options;
      options[index] = {
        id: val.id,
        option: val.option,
        authorNote: val.authorNote,
        selected: false,
        isTrue: val.isTrue,
      };
      this.setState({ options: options });
    }
  };

  onDeleteOption = (id: any) => {

    let newOp = [...this.state.options.filter((op: any) => op.id !== id)];
    this.setState({ options: newOp });

  };


  


  onClickHandler = (id: any) => {
    let correctArray = this.state.options.filter((op:any) => op.isTrue == true);
    let isSelectedArr = this.state.options.filter((op:any) => op.selected == true);

      let newOp = [
        ...this.state.options.map((op:any) => {
          if (op.id === id  ) {
            if( op.selected ){

              op.selected = !op.selected;
            }else if(!op.selected && isSelectedArr.length < correctArray.length ){
              op.selected = !op.selected;
            }
          }
          return op;
        }),
      ];
      this.setState({ options: newOp });
      
      let options = newOp
      let updatedState = {...this.state , options}
      
      if(this.state.mode == "examMode")
      this.onSaveQuestion(updatedState)
    
  };
  onAddScoreHandler = () => {
   
    if (this.state.isPartial === false) {
      var willgetscore = false;
      var atleastonewrong = false;
      for (let i = 0; i < this.state.options.length; i++) {
        if (
          this.state.options[i].isTrue === true &&
          this.state.options[i].selected === true
        ) {
          if (atleastonewrong === false) {
            willgetscore = true;
          } else {
            willgetscore = false;
          }
        }
        if (
          this.state.options[i].isTrue === false &&
          this.state.options[i].selected === true
        ) {
          willgetscore = false;
          atleastonewrong = true;
        }
        if (
          this.state.options[i].isTrue === true &&
          this.state.options[i].selected === false
        ) {
          willgetscore = false;
          atleastonewrong = true;
        }
      }
      this.setState({ score: willgetscore ? this.state.totalMark : "0" });
      let score = willgetscore ? this.state.totalMark : "0";
      let updatedState = {...this.state ,score};
      this.onSaveQuestion(updatedState)
    }
    if (this.state.isPartial === true) {
      var final_calculated = 0;
      var selected_correct_choices = 0;
      var selected_incorrectchoices = 0;
      var correct_choices = 0;
      var incorrectchoices = 0;
      for (let i = 0; i < this.state.options.length; i++) {
        if (this.state.options[i].isTrue === true) {
          correct_choices++;
        }
        if (this.state.options[i].isTrue === false) {
          incorrectchoices++;
        }
      }
      for (let i = 0; i < this.state.options.length; i++) {
        if (
          this.state.options[i].isTrue === true &&
          this.state.options[i].selected === true
        ) {
          selected_correct_choices++;
        }
        if (
          this.state.options[i].isTrue === false &&
          this.state.options[i].selected === true
        ) {
          selected_incorrectchoices++;
        }
      }

      final_calculated =
        (100 / correct_choices) * selected_correct_choices -
        (100 / correct_choices) * selected_incorrectchoices;
      // for(let i = 0; i < this.state.options.length; i++){
      //   if(this.state.options[i].isTrue === true && this.state.options[i].selected === true) {
      //     final_calculated+=(this.state.options[i].percentage/100)*Number(this.state.totalMark);
      //   }
      //   if(this.state.options[i].isTrue === false && this.state.options[i].selected === true) {
      //     final_calculated-=(this.state.options[i].percentage/100)*Number(this.state.totalMark);
      //   }
      // }
      if (final_calculated < 0) {
        final_calculated = 0;
      }

      
      let score = (final_calculated / 100) * Number(this.state.totalMark);
      console.log("sdk;lsd " , score)
      let updatedState = {...this.state ,score};
      this.setState({
        score:score,
      });
      this.onSaveQuestion(updatedState)
    }
  };

  toggleHandler = () => {
    if (this.state.mode === "editingMode") {
      this.setState({ mode: "answeringMode" });
    } else {
      this.setState({ mode: "editingMode" });
    }
  };


  onChooseCorrect = (id: any) => {
    let newOp = [
      ...this.state.options.map((op: any) => {
        if (op.id === id) {
          op.isTrue = !op.isTrue;
        }
        return op;
      }),
    ];
    this.setState({ options: newOp });
  };

  render() {

    let ops = this.state.options.map((op: any, index: any) => {
      return (
        <Option
          key={op.id}
          option={op.option}
          id={op.id}
          index={index + 1}
          authorNote={op.authorNote}
          mode={this.state.mode}
          selected={op.selected}
          isTrue={op.isTrue}
          partialMode={this.state.isPartial}
          onOptionUpdate={this.onOptionUpdate}
          onAddScoreHandler={this.onAddScoreHandler}
          onDeleteOption={this.onDeleteOption}
          onClickHandler={this.onClickHandler}
          onChooseCorrect={this.onChooseCorrect}
          optiontxt={op.option}
          required={true}
          oneLineOptions ={this.state.oneLineOptions}
        />
      );
    });

    return (
      <div className="mx-5">
        {this.state.alert}
       
        <div className="row rounded my-5 p-4 shadow ">
          <div className="col-12 mx-auto row align-items-center bg-question rounded p-3 text-white">
            <div className="col-12 mx-auto row justify-content-between">
           
              {this.state.mode == "editingMode" ? (<h3>Question Setup</h3>) : null}

{this.state.mode == "answeringMode" ? (<h3>Correct answer setup</h3>) : null}
         
              {/*<button
                className="btn bg-info text-white"
                onClick={this.stateToJsonGradingMode}
              >
                show
              </button>*/}
              <div className="d-flex flex-column">
                {this.state.mode === "editingMode" ||
                  this.state.mode === "answeringMode" ? (
                  <button
                    className="btn btn-warning text-white "
                    onClick={this.toggleHandler}
                  >
                    {this.state.mode == "editingMode"
                      ? "Go to Answer mode"
                      : "Go to Question setup mode"}
                  </button>
                ) : null}
                                 {this.state.mode =="examMode" && (<div> Q {this.props.index} <br></br> </div> )}  

                <div className="form-group d-flex align-items-center my-3">
                  <span className="col-auto">Total mark : </span>
                  {this.state.mode === "editingMode" ||
                    this.state.mode === "answeringMode" ? (
                    <input
                      type="number"
                      min={0}
                      className="col form-control"
                      name=""
                      id=""
                      value={this.state.totalMark}
                      onChange={(e) =>
                        this.setState({ totalMark: e.target.value })
                      }
                    />
                  ) : this.state.mode === "examMode" ||
                    this.state.mode === "gradingModeTeacher" ||
                    this.state.mode === "gradingModeStudent" ? (
                    <span>{this.state.totalMark}</span>
                  ) : null}
                  {this.state.mode === "gradingModeTeacher" ||
                    this.state.mode === "gradingModeStudent" ? (
                    <>
                      <span className="border-left ml-2 pl-2 col-auto">Score : </span>
                      <span>{this.state.score}</span>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-12 mb-5 row justify-content-center align-items-center">
              {this.state.mode === "editingMode" ? (
                <div className="col-12 w-100 row justify-content-center align-items-center">
                  <RichBox
                    onChange={(v: any) => { this.setState({ header: v }); }}
                   
                    defaultValue={this.state.header}
                    height={"10px"}

                  ></RichBox>
                </div>
              ) : this.state.mode === "examMode" ||
                this.state.mode === "answeringMode" ||
                this.state.mode === "gradingModeTeacher" ||
                this.state.mode === "gradingModeStudent" ? (
                <div
                  className="bg-white w-100 text-dark rounded row align-items-center p-3"
                  dangerouslySetInnerHTML={{ __html: this.state.header }}
                ></div>
              ) : (
                <div></div>
              )}
            </div>
            <h5 className="col-12">Options :</h5>
            <div className="col-12 row">
              <div className={`col-12 row align-items-center mx-auto`}>
                {ops}
              </div>


              {this.state.mode === "editingMode" ?  (
                <div className="col-12 my-3  d-flex justify-content-between">
                  <button
                    className="btn btn-success "
                    onClick={this.addOptionHandler}
                  >
                    + Options
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() =>
                      this.setState({
                        showObjectives: !this.state.showObjectives,
                      })
                    }
                  >
                    Learning objective
                  </button>
                </div>
              ) : null}

              {this.state.mode === "gradingModeTeacher" ||
                this.state.mode === "gradingModeStudent" ? (
                <div className="col-12 ml-4 row align-items-start align-content-start p-2">
                  <label className="align-self-start">Feedback</label>
                  <textarea
                    className="form-control align-self-stretch "
                    name=""
                    id=""
                    value={this.state.feedback}
                    rows={5}
                    disabled={
                      this.state.mode === "gradingModeStudent" ? true : false
                    }
                    onChange={(e) =>
                     { let feedback = e.target.value ; 
                      let updatedState = {...this.state , feedback}
                    this.onSaveQuestion(updatedState)
                    this.setState({ feedback: e.target.value })}
                    }
                  ></textarea>
                </div>
              ) : null}
            </div>

            {this.state.showObjectives && this.state.mode === "editingMode" && (
              <div className="col-12 row my-3">
                <label>Learning objective</label>
                <textarea
                  className="col-12 form-control"
                  rows={10}
                  value={this.state.objectives}
                  onChange={(e) =>
                    this.setState({ objectives: e.target.value })
                  }
                ></textarea>
              </div>
            )}
            <hr className="col-10" style={{ color: "white", backgroundColor: "white" }} />


            {this.state.mode === "editingMode" ? (
              <>

<div className="ml-4 col-12 row my-3 align-items-start">

                  <input
                    type="checkbox"
                    name=""
                    className="mr-2"
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    id="oneLineOptions"
                    checked={this.state.oneLineOptions}
                    onChange={(e) =>
                      this.setState({ oneLineOptions: e.target.checked })
                    }
                  />
                  <label htmlFor="oneLineOptions" style={{ cursor: "pointer" }}>
                    Options in one line
                  </label>
                </div>
                <div className="ml-4 col-12 row my-3 align-items-start">
                  <input
                    type="checkbox"
                    name=""
                    className="mr-2"
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    id="ShuffleOptions"
                    checked ={this.state.shuffleOptions}
                    onChange={(e) =>
                      this.setState({ shuffleOptions: e.target.checked })
                    }
                  />
                  <label htmlFor="ShuffleOptions" style={{ cursor: "pointer" }}>
                    Shuffle Options
                  </label>
                </div>
                <div className="ml-4 col-12 row my-3 align-items-start">
                  <input
                    type="checkbox"
                    name=""
                    className="mr-2"
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    id="PartialSwitch"
                    checked ={this.state.isPartial}
                    onChange={(e) =>
                      this.setState({ isPartial: e.target.checked })
                    }
                  />
                  <label htmlFor="PartialSwitch" style={{ cursor: "pointer" }}>
                    Allow Partial Scoring
                  </label>
                </div>
              </>
            ) : null}

 
{this.state.mode === "editingMode"  ? (
              <DifficultLevel 
               difficult = {this.state.difficult} 
                cognitive= {this.state.cognitive} 
                onChangeDifficult = {(difficult :any) => { this.setState({difficult :difficult}) }}
                onChangeCognitive = {(cognitive :any) => { this.setState({cognitive :cognitive}) }}
              />

            ) : null}          
{ this.state.mode == "editingMode" && <div className="text-center col-12 mt-4">
              <button
                className="btn btn-warning btn-lg rounded col-3"
                onClick={()=>this.onSaveQuestion(this.state)}
             
              >
                {" "}
                Save
              </button>
            </div>}
          </div>
        </div>
      </div>
    );
    // }
  }
}
function mapStateToProps(state: ApplicationState) {
  const Store = { store: state };
  return Store;
}

export default withRouter<any, any>(
  connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    mapDispatchToProps // Selects which action creators are merged into the component's props
  )(MCQ as any)
);
