import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { Request} from '../helpers/func';
import {Login_Faild, Login_Success , Login_Loading,Request_Login,SET_USER_LOGIN,CLEAR_USER_LOGOUT} from './ActionTypes';
import * as Initial from './initialStatus';
import md5 from 'js-md5';
import { RandomString } from '../helpers/func';
import {Convert} from '../helpers/typeConv';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.



export interface LoginState {
    isLoading: boolean;
    msg?: string;
    user: User;
    loggedin: boolean;
    LoginFail: boolean;
}

export interface User {
    UserName: string,
    Password:string,
    remember:boolean
}
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface RequestLogin {
    type: string ,
    user:any
};
interface LoginFaild {
    type: string;
    user:any
};
interface LoginSuccess {
    type: string;
    user: any;
};
interface LoginLoading {
    type: string;
    user: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestLogin | LoginFaild | LoginSuccess | LoginLoading;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    RequestLogin: (userName: string , password:string,remember:boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.login && userName !== appState.login.user.UserName && password !== appState.login.user.Password) {
            dispatch({ type: Login_Loading, user: { UserName: userName, password: password } });
            let CAES = RandomString(32);
            await Request(Initial.url+'/api/account/login',"POST", { UserName: userName , PasswordHash:md5(password)},"")
                 .then((res) => {
                     let status = res.status;
                     let jdata = res.json;
                //  // JSON data parsed by `response.json()` call
                if (status === 200) {
                    //  
                    jdata.User.SAES = jdata.SAES;
                    jdata.User.CAES = CAES;
                    jdata.User.ApiKey = jdata.ApiKey
                    sessionStorage.setItem('SAES', jdata.SAES);
                    sessionStorage.setItem('CAES', CAES);
                    sessionStorage.setItem('ApiKey', jdata.ApiKey);
                    dispatch({ type: SET_USER_LOGIN, user: jdata.User });
                    dispatch({ type: Login_Success, user: Initial.UserState });
                } else {
                    dispatch({type:Login_Faild,user:{UserName:userName,Password:password}})
                }            
                 }).catch((err: any) => {
                dispatch({type:Login_Faild,user:{UserName:userName,password:password}})
                  
            });

        }
    },

};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.



export const reducer: Reducer<LoginState> = (state: LoginState | undefined, incomingAction: Action): LoginState => {
    if (state === undefined) {
        return Initial.LoginState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case Request_Login:
            return {
                isLoading: true,
                loggedin: false,
                user: Initial.LoginState.user,
                LoginFail: false
            };
        case Login_Success:
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            return {
                isLoading: false,
                loggedin: true,
                user: action.user,
                LoginFail: false
            }
        case Login_Loading:
            return {
                isLoading: true,
                loggedin: false,
                user: Initial.LoginState.user,
                LoginFail:false
            }
        case Login_Faild:
            return {
                isLoading: false,
                loggedin: false,
                user: Initial.LoginState.user,
                LoginFail:true
            }
        case CLEAR_USER_LOGOUT:
            return {
                isLoading: false,
                loggedin: false,
                user: Initial.LoginState.user,
                LoginFail:false
            }
    }

    return state;
};
