import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { isNullOrUndefined, Request} from '../helpers/func';
import { GET_USERS, ADD_USER, DEL_USER, Edit_USER, Error_USERS, CLEAR_USER_LOGOUT } from './ActionTypes';
import * as Initial from './initialStatus';
import md5 from 'js-md5';



/** 
 * status
 *  204 => ok same data in server
 *  401 => Error unauthorized
 *  403 => Error Forbidden not loggedin
 *  500 => Error server error
 *  -1 => Error 
 */

export interface UsersPage {
    msg: string;
    Users:[]| undefined
}



interface GetUsers {
    type: string;
    msg: string;
    Users:[] | undefined
};

interface AddUser {
    type: string;
    msg: string;
    Users:[]| undefined
};
interface EditUser{
    type: string;
    msg: string;
    Users:[]| undefined
};
interface DelUser {
    type: string;
    msg: string;
    Users:[]| undefined
}

type KnownAction = GetUsers | AddUser | EditUser | DelUser;

export const actionCreators = {
    GetUsers: (): AppThunkAction<KnownAction | any> => async (dispatch, getState) => {
        return new Promise<any>(async (resolve, rejects) => {
            const appState = getState();
            if (appState && appState.usersPage && appState.user) {
                let Us = appState.usersPage.Users || [];
                let sign = md5(JSON.stringify(Us));
                await Request(Initial.url + '/getusers', "POST",  sign, appState.user.token)
                    .then((res) => {
                        let status = res.status;
                        let jdata = res.json;
                        //  
                        if (status === 200) { 
                            // New Data or data changed
                            dispatch({ type: GET_USERS, Users: jdata, msg: "" });
                        } else if (status === 403) {
                            // Not Logged in
                            dispatch({ type: CLEAR_USER_LOGOUT, user: Initial.UserState })
                        }
                        resolve(status);
                    }).catch((err: any) => {
                          
                        rejects (-1)
                        //dispatch({ type: Error_USERS,msg:"Error",Users:Us});
                    });
            } else {
                rejects (-2);
            }
        
        });


        
    }
    ,
    AddUser: (usr:any): AppThunkAction<KnownAction | any> => async (dispatch, getState) => {
        return new Promise<any>(async (resolve, rejects) => {
            const appState = getState();
            if (appState && appState.usersPage && appState.user) {
                let table: Array<any> = []
                if(appState.usersPage.Users !== undefined)table= [...appState.usersPage.Users];
                await Request(Initial.url + '/postusers', "POST", usr, appState.user.token)
                    .then((res) => {
                        let status = res.status;
                        let jdata = res.json;
                        //  
                        if (status === 200) { 
                            // New Data or data changed
                            if(isNullOrUndefined(table)){table=[]}
                            table.push(jdata);
                            dispatch({ type: GET_USERS, Users: table, msg: "" });
                        } else if (status === 403) {
                            // Not Logged in
                            dispatch({ type: CLEAR_USER_LOGOUT, user: Initial.UserState })
                        }
                        resolve(status);
                    }).catch((err: any) => {
                          
                        rejects (-1)
                        //dispatch({ type: Error_USERS,msg:"Error",Users:Us});
                    });
            } else {
                rejects (-2);
            }
        
        });
    },
    EditUser: (usr:any): AppThunkAction<KnownAction | any> => async (dispatch, getState) => {
        return new Promise<any>(async (resolve, rejects) => {
            const appState = getState();
              
            if (appState && appState.usersPage && appState.user) {
                let table: Array<any> = []
                if(appState.usersPage.Users !== undefined)table= [...appState.usersPage.Users];
                await Request(Initial.url + '/putusers', "PUT", usr, appState.user.token)
                .then((res) => {
                          
                        let status = res.status;
                        let jdata = res.json;
                        //  
                        if (status === 200) { 
                            // New Data or data changed
                            let index = table.findIndex((a: any) => a.UserId === usr.UserId);
                            table[index] = usr;
                            dispatch({ type: GET_USERS, Users: table, msg: "" });
                        } else if (status === 403) {
                            // Not Logged in
                            dispatch({ type: CLEAR_USER_LOGOUT, user: Initial.UserState })
                        } else {
                              
                        }
                        resolve(status);
                    }).catch((err: any) => {
                          
                        rejects (-1)
                        //dispatch({ type: Error_USERS,msg:"Error",Users:Us});
                    });
            } else {
                rejects (-2);
            }
        
        });
    },
    DelUser: (usr:any): AppThunkAction<KnownAction | any> => async (dispatch, getState) => {
        return new Promise<any>(async (resolve, rejects) => {
            const appState = getState();
            if (appState && appState.usersPage && appState.user) {
                let table: Array<any> = []
                if (appState.usersPage.Users !== undefined) table = [...appState.usersPage.Users];
                //  
                await Request(Initial.url + '/deleteusers', "Delete", usr, appState.user.token)
                    .then((res) => {
                        let status = res.status;
                        let jdata = res.json;
                        //  
                        if (status === 200) { 
                            // New Data or data changed
                            let index = table.findIndex((a: any) => a.UserId === usr);
                            table.splice(index,1);
                            dispatch({ type: GET_USERS, Users: table, msg: "" });
                            resolve(status);
                        } else if (status === 403) {
                            // Not Logged in
                            dispatch({ type: CLEAR_USER_LOGOUT, user: Initial.UserState })
                        } else {
                            rejects("Error");
                        }
                        resolve(status);
                    }).catch((err: any) => {
                          
                        rejects (-1)
                        //dispatch({ type: Error_USERS,msg:"Error",Users:Us});
                    });
            } else {
                rejects (-2);
            }
        
        });
    },
};
 

export const reducer: Reducer<UsersPage> = (state: UsersPage | undefined, incomingAction: Action): UsersPage => {
    if (state === undefined) {
        return Initial.UsersPage;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case GET_USERS:
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            return {
                Users: action.Users,
                msg: ""
            }
        case Error_USERS:
            return {
                Users: action.Users,
                msg:action.msg
            }
        case Edit_USER:
            return {
                Users: action.Users,
                msg:action.msg
            }
        case DEL_USER:
            return {
                Users: action.Users,
                msg:action.msg
            }
        case ADD_USER:
            return {
                Users: action.Users,
                msg:action.msg
            }
    }
 
    return state;
};
