export const Request_Login = "Request_Login";
export const Login_Faild = "Login_Faild";
export const Login_Success = "Login_Success";
export const Login_Loading = "Login_Loading";
export const RECEIVE_WEATHER_FORECASTS = "RECEIVE_WEATHER_FORECASTS";
export const REQUEST_WEATHER_FORECASTS = "REQUEST_WEATHER_FORECASTS";
export const SET_USER_LOGIN = "SET_USER_LOGIN";
export const CLEAR_USER_LOGOUT = "CLEAR_USER_LOGOUT";
export const GET_USER = "GET_USER";
export const DEL_USER = "DEL_USER";
export const ADD_USER = "ADD_USER";
export const Edit_USER = "Edit_USER";
export const GET_USERS = "GET_USERS";
export const Loading_USERS = "Loading_USERS";
export const Error_USERS = "ERROR_USERS";
export const CC_DO_ACTION = "CC_DO_ACTION";
export const ADD_ASSESSMENT = "ADD_ASSESSMENT";
export const SAVE_QUSTION = "SAVE_QUSTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const GET_EXAMS = "GET_EXAMS";
export const GET_EXAM = "GET_EXAM";
export const LOADING = "LOADING";
export const RESET_GOTDATA = "RESET_GOTDATA";
export const RESET_ASSESSMENT = "RESET_ASSESSMENT";
export const ADD_SUBJECT= "ADD_SUBJECT";
export const GET_GRADES = "GET_GRADES";
export const GET_SUBJECTS = "GET_SUBJECTS";
export const GET_GROUPS = "GET_GROUPS";
export const GET_TEACHERS = "GET_TEACHERS";
export const VIEW_EXAM = "VIEW_EXAM";
export const STUDENT_SUBMIT_EXAM = "STUDENT_SUBMIT_EXAM";
export const GET_STUDENTS_EXAMS = "GET_STUDENTS_EXAMS";
export const GRADING_EXAM = "GRADING_EXAM";
export const GET_STUDENT_EXAM = "GET_STUDENT_EXAM";
export const STUDENT_ASSESSMENT_LOADING = "studentAssessmentLoading";
export const PUBLISH_ASSESSMENT = "PUBLISH_ASSESSMENT";

