import React from "react";
import { Component } from "react";
import {NavLink, withRouter} from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import { actionCreators as AssessmentActionCreator } from "../../../store/Assessment";
import assessmentComponent from "../../AssessmentView/assessmentComponent";
import Loader from "react-loader-spinner";



const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  getExams: (data: any) =>
    dispatch(AssessmentActionCreator.GetAssessments(data)),
    GetAssessment: (id: any) =>
    dispatch(AssessmentActionCreator.GetAssessment(id)),
    resetGotData: () =>
    dispatch(AssessmentActionCreator.resetGotData()),
    resetAssessment: () =>
    dispatch(AssessmentActionCreator.resetAssessment()),
  
    ViewAssessment: (id:any) =>
    dispatch(AssessmentActionCreator.ViewAssessment(id)),
    PublishAssessment: (id:any) =>
    dispatch(AssessmentActionCreator.PublishAssessment(id)),
    PullAssessment: (id:any) =>
    dispatch(AssessmentActionCreator.PullAssessment(id)),
  
});

class Assessments extends Component<any, any> {
  state = {
  };
  componentDidMount() {
    $("#preloader").hide();
    require('datatables.net');
    require('../../MyComponents/dataTables.bootstrap4.min');
    $('#dataTable').DataTable();
    this.props.getExams("1")

  }

  componentDidUpdate(){
    if(this.props.gotdata == true ){
     this.props.resetGotData()
    this.props.history.push("/assessmentcomponent");
    }
    if(this.props.viewdata == true ){
     this.props.resetGotData()
    this.props.history.push({pathname : "/assessmentViewComponent" , state :  {exammode: "examMode"}});
    }
  }
   editAssessmentHandelr = (id:any) => {
    this.props.GetAssessment(id)
   }
   publishAssessment = (id:any) => {
    this.props.PublishAssessment(id)

   }
   pullAssessment = (id:any) => {
    this.props.PullAssessment(id)

   }
   viewAssessment = (id:any) => {
    this.props.ViewAssessment(id)
   }
   addAssessmentHandler = () => {
    this.props.resetAssessment()
    this.props.history.push("/assessmentcomponent");
   }
  render() {
    return (
     
        <div>
        <button className="btn bg-info text-white mb-2" onClick = {this.addAssessmentHandler}>Add Assessment</button>
        <table id="dataTable">
            <thead>
            <tr>
                <th>title</th>
                <th>Start date</th>
                <th>End date</th>
                <th>Week number</th>
                <th>Assigned groups</th>
                <th>Published</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
              {this.props.assessments ? this.props.assessments.map((assessment :any,index:any) =>{
  
                let groups = assessment.groups?.map((group:any) => " - " +group.groupname +" - " );
                      return(
                        <tr key ={index}>
                        <td>{assessment.name}</td>
                        <td>{assessment.startdate }</td>
                        <td>{assessment.enddate }</td>
                        <td>{assessment.weeknumber }</td>
                        <td>{groups }</td>
                        <td>{assessment.published == "1" ? "Yes " : "No"}</td>
                        <td> 
                           <button className="btn bg-info text-white mb-2" onClick ={()=> { this.editAssessmentHandelr(assessment.id)}}>Edit</button>  <span>&nbsp;</span>
                           <button className="btn bg-info text-white mb-2" onClick ={()=> { this.viewAssessment(assessment.id)}}>Previwe assessment</button> <span>&nbsp;</span>
                           {assessment.published != "1" ? (
                           <button className="btn bg-info text-white mb-2" onClick ={()=> { this.publishAssessment(assessment.id)}}>Publish assessment</button> 
  
                           ) :  <button className="btn bg-info text-white mb-2" onClick ={()=> { this.pullAssessment(assessment.id)}}>Pull assessment</button> }
                        </td>
                    </tr>
  
                      )
              }) : " "}
               
            </tbody>
        </table>
        </div>
     
     
    )}
}
export default withRouter(
  connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    mapDispatchToProps
  )(Assessments as any)
);
function mapStateToProps(state: any) {
  return {
    assessments: state.Assessment.assessments,
    loading: state.Assessment.loading,
    gotdata: state.Assessment.gotdata,
    viewdata: state.Assessment.viewdata,
  };
}
