//import { resolve } from "dns";
//import { rejects } from "assert";
//import * as Fingerprint2 from 'fingerprintjs2';
import { Convert } from './typeConv';
//import { isNullOrUndefined } from 'util';
import * as Initial from '../store/initialStatus';

import { url as Durl , ConnString } from '../store/initialStatus';
import axios from 'axios';
import { resolve } from 'dns';




export  async function requestWithAxios(url :any, data :any){

 var result =  await axios
  .post(
    url,
    data
  )
  .then((res) => {return res})
  .catch((err) => {return err});
    return result ;
}

export var Request = (url = '', method = 'POST',  data = {},token="")=>{
  if (url.search('http:') === -1 && url.search('https:') === -1) { url = Durl + url;}
  let FingerPrint = sessionStorage.getItem('fp');
  if (FingerPrint === null)
  {
    FingerPrint=RandomString(32);
    sessionStorage.setItem('fp',FingerPrint);
      
  }
  return new Promise<{status:number,json:any}>(async (resolve, rejects) => {
    let body: string;
    if (url === Initial.url+"/api/account/login") {
      let MSG = {
        Body: JSON.stringify(data)
      };     
      body = (JSON.stringify(MSG));
    } else {
      let MSG = {
        Body:JSON.stringify(data) 
      }
      body = (JSON.stringify(MSG));

    }
    let MyBody = { data: body }
    try {
      await fetch(url, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'no-cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
         
          'time':(new Date()).toDateString(),
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        //form: JSON.stringify({id:"DSdsdsdsd"}),
        
        body: JSON.stringify(MyBody)  // body data type must match "Content-Type" header
      }).then(async (response) => {
        var json: any = {};
/*          response.text().then(function (text) {
            
        });  */
        try { 
          //  
          json =await response.json();
        } catch (err) {
            
        } 
        //  
        /* let decryptedObj;
        try {
          //  
          decryptedObj =  json.data;  
          delete decryptedObj['__type'];
          for(var i in decryptedObj){
            delete decryptedObj[i]['__type'];
          } 
        } catch (err) {
          decryptedObj = {}
       } */
       
        resolve({status: response.status, json:json });
      }).catch((err) => {
          
        rejects(err);
      });
    } catch (error) {
        
      rejects(error)
    }
  })
  // Default options are marked with *

  //return response.json(); // parses JSON response into native JavaScript objects
}

export var ShortRequest = (url = '', method = 'post', data = {}) => {
  //  
  let conString = ConnString();
  let res=Request(url, method, data, conString.token); 
  return res; 
}

export function setCookie(name: string, val: string) {
  const date = new Date();
  const value = val;

  // Set it expire in 3 days
  date.setTime(date.getTime() + (3 * 24 * 60 * 60 * 1000));

  // Set it
  document.cookie = name+"="+value+"; expires="+date.toUTCString()+"; path=/";
}
export function getCookie(name: string) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  
  if (parts? true:false && parts.length === 2) {
    let x = parts.pop();

    return x? x.split(";").shift(): "";
  }
  return "";
}

export function deleteCookie(name: string) {
  const date = new Date();

  // Set it expire in -1 days
  date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

  // Set it
  document.cookie = name+"=; expires="+date.toUTCString()+"; path=/";
}

export function asciiToUint8Array(s: string) {
  return  new TextEncoder().encode(s);
}

export function RandomString(length: number) {
  var result = window.crypto.getRandomValues(new Uint8Array(length))
  return Convert.ab2base64str(result);
}

export function makeId(length:number) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export function objectsAreSame(x:any, y:any) {
  var objectsAreSame = true;
  for(var propertyName in x) {
     if(x[propertyName] !== y[propertyName]) {
        objectsAreSame = false;
        break;
     }
  }
  return objectsAreSame;
}

export function Masker(Arr:Array<any>,Mask:number|null|undefined){
  let res:Array<any> = []; 
  if (Mask === null || Mask === undefined) {
    for (let i = 0; i < Arr.length; i++){
      if (!Arr[i].IsHidden) { res.push(Arr[i]); }
      
    }
    return res;
  }
  var bitArr = Convert.int2Binary(Mask);
  for (let index = 0; index < bitArr.length; index++) {
    if(bitArr[index] && !Arr[index].IsHidden){res.push(Arr[index]);}
    
  }
  return res;
}
export function GenMask(Arr:Array<any>,subArr:Array<any>){
  let res:Array<number> = [];
  for (let index = 0; index < Arr.length; index++) {
    let flag = false;
    for (let j = 0; j < subArr.length; j++){
      if (Arr[index].value === subArr[j].value) {
        flag = true;
        break;
      }
    }
    if (flag) { res.push(1); }
    else {
      res.push(0);
    }
  }
  var Mask = 0;
  for (let index = 0; index < res.length; index++){
    if (res[index] === 1) {
      Mask += Math.pow(2, index);
    }
  }
  return Mask;
}

export function LoadFromStore(VarName:string){
  const serializedState = localStorage.getItem(VarName);
  if (serializedState === null) return undefined;
  let state = JSON.parse(serializedState);
  return state;
}

export function ListOfObj2SelectOptions(array:Array<any>){
  //  
  let result : Array<{value:string ,label:string , color:string , isFixed:boolean , isDisabled?:boolean }> = [];
  let i = 1;
  array.forEach(element => {
    //  
    if(typeof(element)==="object")
    result.push({value: element[Object.keys(element)[0]],label: element[Object.keys(element)[1]],isFixed:true , color:'black'});
    else{
    result.push({value: element,label: element,isFixed:true , color:"black"});
      i++;
    }
  });
  //  
  return result;
}

export function isNullOrUndefined(obj:any){
  if(obj === null || obj === undefined){
    return true;
  }
  return false;
}


export function isHtmlElement(obj:any) {
  try {
    //Using W3 DOM2 (works for FF, Opera and Chrome)
    let _ = obj instanceof HTMLElement;
    if (_ === null) { return false;}
    return true;
  }
  catch(e){
    //Browsers not supporting W3 DOM2 don't have HTMLElement and
    //an exception is thrown and we end up here. Testing some
    //properties that all elements have (works on IE7)
    return false;
  }
}


export function NotSpecialType(element: string) {
  //if (element === undefined) { return true;}
  if (element.toLowerCase() === "string" || element.toLowerCase() === "bool" || element.toLowerCase() === "date" ||
      element.toLowerCase() === "int"    || element.toLowerCase() === "datetime"
      || element.toLowerCase() === "email" || element.toLowerCase() === 'password' || element.toLowerCase() === 'phone'
  ) { 
    return true;
  } else {
    return false;
  }
}